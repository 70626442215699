import React, {Fragment} from 'react';
import {
    Toolbar, Page, ToolbarButton,
    // SearchInput,
    Card,
    Segment,
} from 'react-onsenui';
import {TabbedList, TabList, Tab, Tabs, TabPanel} from 'react-tabs';
// import styled, {css} from 'react-emotion'; import styled, { css } from
// 'react-emotion'
import styled from 'styled-components';
import {Icon} from 'react-icons-kit';
import {mapPin} from 'react-icons-kit/feather/mapPin';
import {search} from 'react-icons-kit/feather/search';
import InfinitScroll from 'react-infinite-scroller';
// import InfinitScroll  from 'react-infinite-scroll-component';
import {withRouter} from 'react-router-dom';
import { Route, Switch, Redirect, Link } from "react-router-dom";
import { RoutedTabs, NavTab } from "react-router-tabs";
import {Entry, Museum} from './Card.js';
import queryString from 'query-string';
import Avator from 'react-avatar';
import {get, post} from './Backend.js';
import {setCache, getCache, setFlyCache} from './State';
import _PullRefresh from 'react-pullrefresh'
import ReactPullToRefresh from 'react-pull-to-refresh';
import { Plugins, Capacitor } from '@capacitor/core';
import {bindLifecycle} from 'react-keep-alive';
import LazyLoad from 'react-lazyload';
const { Device } = Plugins;

let platform
Device.getInfo().then(res => {
    console.log(res)
    platform = res.platform
})

// App Containers

const Container = styled.div`
    max-width: 650px;
    width: 100%;
    height: 100%;
    top: ${props =>props.top || 0};
    margin-left: auto;
    margin-right: auto;
    background-color: ${props => props.color || 'white'};
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
`


const toolbarHeight = 40;
const Body = styled.div`
    width: ${props => props.width || '100%'};
    max-width: 650px;
    margin-left: ${props => (100 - parseFloat(props.width || '100%'))/2}%;
    margin-right: ${props => (100 - parseFloat(props.width || '100%'))/2}%;
    height: calc(100% - 41px); 
    top: 41px;
    overflow: hidden;
    position: absolute;
    /* top: ${toolbarHeight + 1}px; */
    /* left: 0; */
    /* padding-top: 10px; */
    /* margin-top: 10px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-bottom: 100px; */
`

const StyledToolbar = styled.div`
    position: absolute;
    /* top: 30px; */
    max-width: 650px;
    display: flex;
    /* overflow: auto; */
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
    /* margin-bottom: 10px; */
    height: ${toolbarHeight + 1}px;
    width: 100%;
    /* max-width: 650px; */
    align-items: center;
    justify-content: space-between;
    /* background-color: white; */
    border-bottom: 1px solid lightgrey;
    /* background-color: white; */
    font-weight: bold;
    font-size: 1rem;
    .left .right {
        font-size: 1.2rem;
    }
    .center {
        max-width: 60%;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        /* height: 1.2em; */
        -webkit-box-orient: vertical;
    }
`

// import "styles/react-router-tabs.css";

const PullRefresh = styled(_PullRefresh)`
    width: 100%;
`
const TopBar = styled.div`
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
const SearchBar = styled.div `
    width: 100%;
    height: 30px;
    border-radius: 30px;
    border: 0;
    padding-left: 20px;
    margin-top: 5px;
    background-color: whitesmoke;
    display: flex;
    align-items: center;
`

const SearchForm = styled.form`
    background-color: transparent;
    border: 0;
    height: 100%;
    width: 100%;
`

const SearchInput = styled.input `
    background-color: transparent;
    border: 0;
    height: 100%;
    width: 100%;
    margin-left: 10px;
    padding-left: 10px;
`


const SearchIcon = styled(Icon)`
    width: 30px;
    height: 30px;
    /* margin-top: auto;
    margin-bottom: auto; */
    /* padding-left: 20px; */
`


// const StyledPage = styled(Page)`
//     width: 100%;
//     height: 100vh;
// `


const Content = styled.div`
    width: 98%;
    /* margin-top: 30px; */
    padding-bottom: 100px;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    position: relative;
`
const StyledLink = styled.div`
    width: 3rem;
    color: blue;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0.7rem;
    padding-top: 5px;
`



const Scroller = styled.div`
    position: absolute;
    /* max-width: 650px; */
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100vh;
    padding-top: 10px;
    padding-bottom: 100px;
    /* margin-bottom: 30vh; */
    /* height: 100%; */
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`


const Flex = styled.div`
    display: flex;
    width: 30%;
    align-items: center;
`
const SearchBox = withRouter(class extends React.Component {
    componentDidMount() {
        this.refs.searchInput.value = this.props.defaultValue || '';
    }
    
    render() {
        return (
            <TopBar>
                {/* <Flex>
                    <Icon icon={mapPin}/>
                    <div>北京</div>
                </Flex> */}
            <SearchBar>
                <Icon icon={search}/>
                <SearchForm action="" 
                // onSubmit={this.props.onSubmit}
                >
                <SearchInput
                    ref="searchInput"
                    id="searchInput"
                    onSearch={e => console.log(e)}
                    onKeyPress={e => this.props.onKeyPress && this.props.onKeyPress(e)}
                    onChange={this.props.onChange}
                    onFocus={this.props.onFocus}
                    autoFocus={this.props.autofocus}
                    onBlur={this.props.onblur}
                    value={this.props.value}
                    type="search"
                    placeholder={this.props.placeholder}/> {/* <input placeholder='Search ...' /> */}
                </SearchForm>
            </SearchBar>
            { this.props.enableCancel &&
                  <StyledLink onClick={e => this.props.history.goBack()} >
                      取消
                  </StyledLink>
                }
            { this.props.right && React.createElement(this.props.right, {})}
            </TopBar>
        )
    }
})

const StyledFeedDiv = styled.div`
    height: 90vh;
    width: 100%;
    /* margin-bottom: 30vh; */
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const FeedContainer = styled.div`
    width: 100%;
`

const End = styled.div`
    width: 100%;
    height: 400px;
    position: relative;
`

const StyledPage = styled(Page)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`



const AvatorIcon = (props) => <Avator round={true} color={'black'} size='50px' {...props} />

const Fixed = styled.div`
    position: fixed;
    bottom: ${props => props.bottom || '30px'};
    left: ${props => props.left || '80%'};
    right: ${props => props.right || ''};
    display: flex;
    flex-direction: column;
    /* margin-bottom: 200px; */
`

const FeedList = styled(InfinitScroll)`
    width: 100%;
    padding-bottom: 40vh;
    /* height: 100vh; */
`

const LazyCard = styled.div`
    width: 100%;
    /* min-height: ${props => props.height || 0}px; */
    /* max-height: ${props => props.height > 50 && props.height || 1000}px; */
    height: ${props => props.height > 0 && `${props.height}px` || 'auto'};
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    /* visibility: ${props => !props.isVisible && 'hidden'}; */
`
const PlaceHolder = styled(LazyCard)`
    height: ${props => props.height}px;
`

class LazyItem extends React.Component {
    componentDidMount() {
        if (this.props.forceRender) {
            this.props.storeHeight(this.refs.card.offsetHeight)
        }
    }
    render() {
        if (this.props.forceRender) {
            return <LazyCard ref='card' height={this.props.height} isVisible={this.props.forceRender}>
                {React.createElement(this.props.component, {...this.props})}
            </LazyCard>
        } else {
            return <PlaceHolder height={this.props.height} />

        }
    }
}


const Feed = (class extends React.Component {
    state = {
        data: {},
        hasMore: true,
        next: null,
        headerState: {},
        pullRefreshEnabled: true
    }

    loadMore(refresh) {
        console.log('loadMore...', this.props, this.state)
        let params = this.props.params && {...this.props.params} || {};
        let tagKey = this.stateKey()
        let dataWithTag = this.state.data[tagKey] || {data: [], hasMore: true}
        this.state[tagKey] = dataWithTag
        params.offset = dataWithTag.data.length;
        params.limit = params.offset > 0? 10: 3
        params.type = this.props.type || params.type;
        params.desc = null;
        if (this.state.params) {
            params = {...params, ...this.state.params}
        }
        get(this.state.data[tagKey].next || this.props.dataType, {
            params: params
        }).then(resp => {
            console.log('RESP', resp)
            let data = resp.data
            let stateData = this.state.data[tagKey]
            if (data.next) {
                data = data.data
                stateData.next = data.next
            }
            if (params.offset !== stateData.data.length) {
                return;
            }
            this.state.data[tagKey] = {
                data: [...this.state.data[tagKey].data, ...data],
                hasMore: data.length !== 0,
                next: resp.data.next,
            }
            this.setState({})
            this.dump();
            // this.dump()
        })
    }

    constructor(props) {
        super(props);
        console.log('Feed', props);
        if (!props.refresh) {
            this.load();
        } else {
            setCache(this.cacheKey(), null)
        }
        // else {
        //     this.state.data = {}
        // }
        this.state.data[this.stateKey()] = this.state.data[this.stateKey()] || {data: [], hasMore: true}
        this.state.loaded = true
    }

    cacheKey() {
        const key = `feed:${this.props.dataType}:${this.props.type || ''}:${JSON.stringify(this.props.params || '')}:${localStorage.getItem('cityName')}`;
        return key;
    }

    stateKey() {
        return `${JSON.stringify(this.state.params || [])}`
    }

    timeout = 1000 * 60 * 10

    headerSet(k, v, state) {
        if (this.props.header) {
            this.state.params = this.state.params || {}
            console.log(this.state.headerState, state)
            if (this.state.headerState && this.state.headerState.actived===state.actived) {
                
            } else {

                // this.setState({headerState: state})
                // this.setState({data: []})
                this.recordScroll()
                this.state.params[k] = v
                this.state.data[this.stateKey()] = this.state.data[this.stateKey()] || {data: [], hasMore: true}
                // this.load()
                // this.loadMore()
                // this.state.data = []
                this.setState({})
                this.restoreScroll()


                // this.setState({data: this.state.data})
            }
            this.state.headerState.actived = state.actived
        }
    }
    dump() {
        this.state.updated = Date.now();
        console.log('dump', this.cacheKey(), this.state)
        setCache(this.cacheKey(), this.state)
        // localStorage.setItem(this.cacheKey(), JSON.stringify(this.state));
    }
    load() {
        // let state = JSON.parse(localStorage.getItem(this.cacheKey()))
        let state = getCache(this.cacheKey())
        console.log('Mount', state, 'SEEEETTTT');
        if (state && (Date.now() - state.updated) < this.timeout) {
            this.state = state;
            // this.state.doNotRender = true
            this.state.doNotRender = true;
            return state
        } else {
            return {data: {}}
        }

    }

    componentDidActivate() {
        this.restoreScroll()
        this.state.activated = true
    }
    componentWillUnactivate() {
        this.state.activated = false
    }

    componentDidMount() {
        this.restoreScroll()
    }

    restoreScroll() {
        let state = this.state.data[this.stateKey()]
        let top = state.top || 0
        console.log(top)
        // document.getElementById('infList') && (document.getElementById('infList').scrollTop = top);
        // this.setState({doNotRender: false})
        setTimeout(e => {document.getElementById('infList') && (document.getElementById('infList').scrollTop = top);this.setState({doNotRender: false})}, 5);
        this.onScroll()
    }
    recordScroll() {
        let state = this.state.data[this.stateKey()] || {data: [], hasMore: true}
        this.state.data[this.stateKey()] = state
        state.top = document.getElementById('infList').scrollTop;
        console.log(state.top)

    }
    componentWillUnmount() {
        this.recordScroll()
        console.log('Unmount', this.state, this.props.history, this.props.location);
        this.dump();
    }

    refresh() {
        // TODO: only refresh current tag
        this.state.data[this.stateKey()] = {data: [], hasMore: true}
        this.setState({})
        this.loadMore()
    }

    renderList() {
        console.log('Loaded:', this.state)
        let data = this.state.data[this.stateKey()].data
        console.log(data, this.stateKey(), this.state)
        let state = this.state.data[this.stateKey()]
        let infList = document.getElementById('infList')
        let scrollTop = infList && infList.scrollTop || 0
        let windowHeight = 2000
        let heightTrack = 0
        return <FeedList
            pageStart={0}
            // dataLength={this.state.data.length} 
            loadMore={this.loadMore.bind(this)}
            hasMore={this.state.data[this.stateKey()].hasMore}
            loader={< div className = "loader" key = {0} > Loading ...</div>}
            useWindow={false}>
                    {

                        this.state.data[this.stateKey()].data.map((v, i) => {
                            // console.log('Render!', v, i)
                            let params = {
                                data: v,
                                storeHeight: height => v.height = height,
                                key: i,
                                onDelete: e => data.splice(i, 1) && this.setState({}),
                                onModify: e => this.props.onModify(v, e)
                            }
                            // return <LazyLoad offset={100}> {React.createElement(this.props.component, params)} </LazyLoad>
                            heightTrack += v.height || 0
                            let isVisible = !this.state.doNotRender && heightTrack >= (scrollTop - 600) && heightTrack < (scrollTop + windowHeight)
                            return <LazyItem forceRender={isVisible} height={v.height || 0} component={this.props.component} {...params} />
                        })
                    }
                    {/* <End /> */}
        </FeedList>
    }

    onScroll() {
        let state = this.state.data[this.stateKey()]
        let infList = document.getElementById('infList')
        let scrollTop = infList && infList.scrollTop || 0
        let lastTop = state.lastTop || 0
        const limit = 100
        if (Math.abs(lastTop - scrollTop) > limit) {
            this.setState({pullRefreshEnabled: scrollTop < limit})
            state.lastTop = scrollTop
        }
        // let data = state.data
        // if (state.data.length > 2 && state.data[state.data.length - 2].height > 0) {
        //     this.loadMore()
        // }
        this.props.params && this.props.params.onScroll && this.props.params.onScroll()
    }

    render() {
        return (


            <FeedContainer>
            {
                this.state.loaded && this.props.header && React.createElement(this.props.header, {
                    onChange: this.headerSet.bind(this),
                    // defaultActived: this.state.headerState.lastActived,
                    // headerState: this.state.headerState
                    uniqueKey: `${this.cacheKey()}/header`
                })
            }
            <PullRefresh
                onRefresh={this.refresh.bind(this)}
                disabled={this.props.disableRefresh || !this.state.pullRefreshEnabled}>
                <StyledFeedDiv id="infList" onScroll={this.onScroll.bind(this)}>
                    {this.renderList()}
                </StyledFeedDiv>
            </PullRefresh>
            </FeedContainer>

        )
    }
})

const StyledNavBar = styled.div`
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    display: flex;
    /* position: sticky; */

    /* grid-template-columns: repeat(auto-fill, 5rem); */
    align-items: center;
    border-bottom: 0.5px solid lightgrey;
    justify-content: space-between;
`
const TabbedContent = styled.div`

`
const StyledNavTab = styled(NavTab)`
    /* margin-right: 20px; */
    color: black;
    border-bottom: ${props => props.iscurrent == 'true' && "5px solid green" || "5px solid transparent"};
    font-weight: ${props => props.iscurrent == 'true' && 'bold'};
    font-size: ${props => props.iscurrent == 'true' && '1rem'};
`

const TabbedContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-top: 10px;
    padding-top: 5px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    background-color: ${props => props.backgroundColor};
    /* background-color: white; */
    /* border-radius: 10px; */
`
  
const Tabbed = withRouter(class extends React.Component {

    tabs = [
        ['文物', (props) => <Feed dataType='entry' component={Entry} {...props} /> ], 
        ['博物馆', (props) => <Feed dataType='museum' component={Museum} {...props} />],
        // '展览',
        // // '建筑',
        // '百科'
    ]

    constructor(props) {
        super(props);
        this.prefix = props.prefix;
        this.tabs = props.tabs || this.tabs;
    }
    // prefix = '/hot'
    renderNavBar(current) {
        return (
            <StyledNavBar ref='navBar' className='tabbedNavBar'>
            {
                this.tabs.map((v, i) => <StyledNavTab key={i} iscurrent={(current === i).toString()} to={`${this.props.location.pathname}?section=${v[0]}`}>{v[0]}</StyledNavTab>)
            }
            </StyledNavBar>
        )
    }
    componentDidUpdate() {
        this.props.onSwitch && this.props.onSwitch()
    }
    render() {
        console.log('Render', this.props.location, decodeURI(this.props.location.hash));

        let values = queryString.parse(this.props.location.search);
        if (!values.section) {
            // return <Redirect to={`${this.props.location.pathname}?section=${this.tabs[0][0]}`} />;
            values.section = this.tabs[0][0];
        }
        let current = 0;
        this.tabs.map((v, i) => {
            if (values.section === `${v[0]}`) {
                current = i;
            }
        })
        if (this.props.inline) {
            return   <Fragment {...this.props} className={this.props.className} >
                    {this.renderNavBar(current)}
                    {/* <TabbedContent> */}
                    {
                            this.tabs.map((v, i) => {
                            if (values.section === v[0]) {
                                return React.createElement(v[1], {
                                    key: i,
                                    params: this.props.params,
                                    prefix: this.props.prefix,
                                    refresh: this.props.refresh,
                                })
                            }
                        })
                    }
                    {/* </TabbedContent> */}
                    {/* <Route path={`${this.prefix}`} component={props => <Redirect to={`${this.prefix}?section=${this.tabs[0][0]}`} />} />
                    </Switch> */}
            </Fragment>
        }
        return (
            <TabbedContainer {...this.props} className={this.props.className} >
                {this.renderNavBar(current)}
                {/* <TabbedContent> */}
                {
                        this.tabs.map((v, i) => {
                        if (values.section === v[0]) {
                            return React.createElement(v[1], {
                                key: i,
                                params: this.props.params,
                                prefix: this.props.prefix,
                                refresh: this.props.refresh,
                            })
                        }
                    })
                }
                {/* </TabbedContent> */}
                {/* <Route path={`${this.prefix}`} component={props => <Redirect to={`${this.prefix}?section=${this.tabs[0][0]}`} />} />
                </Switch> */}
            </TabbedContainer>
        )

        // return (
        //     <Tabs>
        //         <TabList>
        //             {this
        //                 .tabs
        //                 .map((v, i) => <Tab>
        //                     {v}
        //                 </Tab>)}
        //         </TabList>
        //         <TabPanel>
        //             <Feed dataType='entry' component={Entry} />
        //          </TabPanel>
        //         <TabPanel>
        //             <Feed dataType='museum' component={Museum} />
        //         </TabPanel>
        //     </Tabs>
        // )
    }
});

const findAll = (reg, text) => {
    var matches = new Array();
    let match = reg.exec(text)
    while(match !== null){
        matches.push(match)
        match = reg.exec(text)
    }
    return matches
}

export {SearchBox, StyledPage, StyledToolbar, Feed, Content, Tabbed, Container, Body, Fixed, AvatorIcon, Scroller, End, findAll};