import axios from 'axios';
import { getCache, clearCache } from './State';
import { Plugins } from '@capacitor/core';
const { Share, Modals } = Plugins;

const prefix = 'https://api.paoying.net/'

function delete_cookie(name) {   
    document.cookie = name+'=; Max-Age=-99999999;';  
}
const isLogin = () => localStorage.getItem('userInfo') && true
const getUserInfo = () => JSON.parse(localStorage.getItem('userInfo'))
const setUserInfo = (info) => localStorage.setItem('userInfo', JSON.stringify(info))
const removeUserInfo = () => {
    localStorage.removeItem('userInfo')
    post('logout')
    token = null
    clearCache()
}

let token = null

const getToken = () => {
    if (!token) {
        let userInfo = getUserInfo();
        token = userInfo && userInfo.token;
        // if (!token) {
        //     removeUserInfo()
        // }
    }
    return JSON.stringify(token);
}


const get = (url, param) => {
    let instance = axios.create({
        baseURL: prefix,
        timeout: 3000,
        headers: {token: getToken()}
    });
    let param_ = param || {params: {}}
    // param_.params['city'] = getCache('cityName')
    // let crd = getCache('location') || {}
    // let happyVally = {longitude: 116.50417, latitude: 39.86139}
    // param_.params['longitude'] = crd.longitude || happyVally.longitude
    // param_.params['latitude'] = crd.latitude || happyVally.latitude
    console.log(param_)
    return instance.get(`${url}`, param_)
}

const post = (url, data) => {
    const token = getToken();
    return axios.post(`${prefix}${url}`, data, {
        headers: {token: token}
    })
}

const delete_ = (url, data) => {
    let instance = axios.create({
        baseURL: prefix,
        timeout: 2000,
        headers: {token: getToken()}
    });
    return instance.delete(`${url}`, data)
}

const host = 'https://owl.paoying.net/'

const shareNav = (title, text, path) => {
    if (navigator.share){
        navigator.share({
            title: title,
            text: text,
            url: `${host}#${path}`
        }).then( res => {
            console.log('Shared')
        })
    } else {
        Share.share({
            title: title,
            text: text,
            url: `${host}#${path}`,
            dialogTitle: title
        })
    }
}

const back = (self, home) => {
    let history = self.props.history
    // console.log(history, history.entries[history.index - 1])
    if (history.length > 2) {
        self.props.history.goBack()
    } else {
        self.props.history.push(home || '/')
    }
}

const checkLogin = () => {
    get('userinfo').then(res => {
        console.log('Check', res)
        setUserInfo(res.data)
    }).catch(err => {
        if (err) {
            removeUserInfo()
        }
    })
}

const ensureLogin = async () => {
    if (!getUserInfo()) {
        let r = await Modals.confirm({
            title: '确认',
            message: '请先登录'
        })
        if (r.value) {
            this.props.history.push('/login')
        } else {
            this.props.history.goBack()
        }
    }
}
export {get, post, getUserInfo, setUserInfo, delete_, removeUserInfo, shareNav, back, getToken, checkLogin, ensureLogin};