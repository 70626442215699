import React, {Fragment, useState} from 'react';
import { Link } from 'react-router-dom';
import {withRouter} from 'react-router';
import {Container} from './Common';
import {back, shareNav, get} from './Backend';

import {
    FixedPostIcon,
    GeneralCard,
    TagSelect,
    RightMost
} from './Detail';
import {Watch, Bookmark, ShareIcon} from './Actions';
import {Tabbed, Feed} from './Common';
import styled from 'styled-components';
import {PostList, PostCard} from './Discuss';
import {Entry, Exhibit as ExhibitCard} from './Card';
import {Wiki, Markdown, MarkdownPreview} from './Wiki';
import {arrowLeft as backward} from 'react-icons-kit/feather/arrowLeft';
import {logo} from './Config';
import {CoverCard, toPageSrc} from './CoverCard';
import { FaAngleLeft, FaPlus, 
    FaShare as FaShare,
    FaBookmark,
    FaHeart,
    FaPen,
    FaCross,
    FaImages,
    FaImage,
    FaHeadphones,
    FaPause,
    FaStepForward,
    FaStepBackward,
    FaEdit,
    FaTrash,
    FaSlideshare,
    FaPlay
} from "react-icons/fa";
import { InfinitList, Waterfall, Bar } from './Basics';
import {setRefresh, getCache, setCache, getHash, setHash, IndexDB, AppState} from './State';
import {post, getUserInfo} from './Backend';
import VConsole  from 'vconsole';
import { FavPromt, FavState } from './Fav';
import { SearchPromt } from './SearchPromt';
import { Plugins } from '@capacitor/core';
import { Radio } from './Speech';
import htmlToText from 'html-to-text';
import { MdSlideshow } from 'react-icons/md';
import { ImageSlides } from './Douyin';

const { Modals } = Plugins;

const ImageCover = class extends React.Component {
    Img = styled.img`
        width: 100%;
        height: 100%;
        object-fit: cover;
    `
    Container = styled.div`
        width: 100%;
        height: 30vh;
        position: relative;
        /* top: -50px;
        margin-bottom: -50px; */
    `
    toPageSrc = url => url && url.includes('paoying.net') && `${url}?x-oss-process=style/page` || url

    state = {
        src: this.toPageSrc(this.props.src)
    }
    componentDidMount() {
        this.setState({src: this.props.src})
    }

    render() {
        return <this.Container ref='cover'>
                <this.Img src={this.toPageSrc(this.props.src)} />
                {this.props.children}
             </this.Container>
    }
}

const addAction = (o, action) => {
    let actions = getCache('_actions') || []
    actions = [...actions, {...o, action: action}]
    setCache('_actions', actions)
}

const BaseItem = class extends React.Component {
    type = 'exhibit'
    state = {
        data: {},
        tabActived: 1
    }
    Header = styled.div`
        width: 100%;
        height: 50px;
        /* background-color: rgba(0, 0, 0, 0.2); */
        background-color: ${props => props.coverInvisible && 'white' || 'transparent'};
        color: 'white';
        /* border-bottom: ${props => props.coverInvisible && '1px solid whitesmoke'}; */
        position: absolute;
        /* position: -webkit-sticky; */
        top: 0;
        z-index: 10;
        span {
            position: absolute;
            left: 50px;
            top: 8px;
            width: 70%;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
        }
    `
    HeaderReturn = styled.div`
        position: absolute;
        left: 20px;
        top: 10px;
    `
    HeaderShare = styled.div`
        position: absolute;
        right: 20px;
        top: 10px;
    `
    Info = styled.div`
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
        padding-top: 10px;
        /* background-color: white; */
        border-bottom: 1px solid whitesmoke;
        font-size: small;
    `
    CoverTitle = styled.div`
        position: absolute;
        bottom: 50px;
        left: 20px;
        width: calc(100% - 40px);
        color: white;
        font-size: large;
        font-weight: bold;
    `
    Actions = styled.div`
        position: absolute;
        bottom: 10px;
        right: 20px;
        width: ${props => props.count * 45}px;
        display: flex;
        justify-content: space-between;
    `
    Line = styled.div`
        width: 100%;
        display: table;
        div {
            display: table-cell;
            vertical-align: baseline;
            text-align: left;
        }
    `
    Key = styled.div`
        width: 3rem;
        font-weight: bold;
    `
    HtmlDesc = styled.div`
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 10px;
        img {
            width: 100%;
            object-fit: cover;
        }
        a {
            pointer-events: none;
            cursor: default;
            text-decoration: none;
            color: black;
        }
    `
    Container = styled.div`
        width: 100%;
        overflow: auto;
        position: relative;
        background-color: snow;
    `
    bottomBound = 'calc(100% - 150px)'
    upBound = '70px'

    TalkCard = styled.div`
        background-color: white;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: ${props => props.spread && this.upBound || this.bottomBound};
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        box-shadow: 0px 0px 5px grey;
        max-width: 650px;
    `

    Banner = styled.div`
        height: 5px;
        border-radius: 2.5px;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        background-color: grey;
        opacity: 0.7;
    `
    TabBar = styled.div`
        width: 94%;
        margin: auto;
        margin-top: 10px;
        font-size: large;
        display: flex;
        flex-direction: row;
        vertical-align: baseline;
        justify-content: space-between;
    `

    Tab = styled.div`
        border-bottom: ${props => props.actived && '5px solid darkgreen' || '5px solid transparent'};
        svg {
            margin-bottom: 0;
        }
    `
    TouchCover = styled.div`
        position: absolute;
        top: 50px;
        width: 100%;
        height: 100%;
        background-color: transparent;
        pointer-events: 'auto';
    `

    TouchBar = styled.div`
        width: 94%;
        height: 50px;
        margin: auto;
        font-size: 10px;
        padding-top: 5px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid whitesmoke;
    `
    Space = styled.div`
        height: 30vh;
    `
    Scroller = styled.div`
        width: 100%;
        overflow-y: scroll;
        height: 100%;
    `
    Icon = styled.div`
        width: 35px;
        height: 45px;
        display: flex;
        flex-direction: column;
        font-size: 10px;
        color: white;
        align-items: center;
    `
    doAction(action, setState) {
        return () => {
            if (action === 'bookmark') {
                return this.setState({showFavPromt: true})
            }
            console.log(this.state, this.props)
            let o = {
                type: this.type,
                object: this.getId(),
                delete: !!this.state[action]
            }
            setHash(action, this.key, !this.state[action])
            if (!getUserInfo()) {
                this.setState({
                    [action]: !this.state[action]
                })
                addAction(o, action)
                return
            }
            post(action, o).then(res => {
                console.log(res)
                this.setState({
                    [action]: !this.state[action]
                })
            })
        }
    }
    IconCom = props => {
        let actived = this.state[props.action]
        if (props.action === 'bookmark') {
            actived = FavState.isBookMarked(this.type, this.getId())
            // console.log('Actived', actived)
        }
        return <this.Icon onClick={this.doAction(props.action)}>
            {React.createElement(props.icon, {
                size: '35px', 
                color: actived  && props.color || 'white', 
                })}
            <span>{props.label}</span>
            </this.Icon>
    }
    constructor(props) {
        super(props)
        this.state = getCache(this.props.location.pathname) || {tabActived: 1}
        this.state.data =  this.props.location.data || this.state.data || {}
        this.state.path = this.props.location.pathname
        console.log('SSSSSS', this.state, this.props.location)
        if (!this.state.data._id || (!this.state.path.includes(this.state.data.id || this.state.data._id))) {
            this.init()
        }

    }
    init() {
        get(this.props.location.pathname).then(res => {
            console.log(res)
            this.setState({data: res.data || {}, path: this.props.location.pathname, talkSpread: false, drag: false})
            this.refs.talkCard && (this.refs.talkCard.style.top = this.bottomBound)
        })
    }

    componentDidMount() {
        // React.initializeTouchEvents(true); 
        if (this.state.top) {
            this.refs.container.scrollTop = this.state.top || 0
        }
        this.initDrag()
        console.log(this.state, this.props)
        // getUserInfo() && getUserInfo().nickName === '闹钟' && new VConsole()
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this._mousemove)
        window.removeEventListener('touchmove', this._mousemove)
        window.removeEventListener('mouseup', this._mouseup)
        window.removeEventListener('touchend', this._mouseup)

        this.state.top = this.refs.container && this.refs.container.scrollTop || 0
        setCache(this.props.location.pathname, this.state)
    }

    getData() {
        return this.state.data
    }

    onScroll() {
        let rect = this.refs.cover.refs.cover.getBoundingClientRect()
        let inVisible = (rect.y + rect.height) < 0
        console.log('visible?', inVisible)
        if (inVisible !== this.state.coverInvisible) {
            console.log('SetState')
            this.setState({coverInvisible: inVisible})
        }

    }
    loadMore(offset) {
        return get('/post', {
            params: {
                type: this.type,
                id: this.state.data.id || this.state.data._id,
                offset: offset
            }
        })
    }
    relatedMore(offset) {
        return get('/related', {
            params: {
                type: this.type,
                id: this.state.data.id || this.state.data._id,
                offset: offset,
                title: this.state.data.title
            }
        })
    }
    tabClicked(index) {
        this.setState({tabActived: index, talkSpread: true})
        this.refs.talkCard.style.top = this.upBound;
    }
    
    tabs = ['讨论', '相册', '相关']

    renderTab() {
        let actived = this.state.tabActived || 0
        return <this.TabBar>
            {
                this.tabs.map((v, i) => 
                        <this.Tab onClick={e => this.tabClicked(i)} actived={i === actived} key={i}> {v} </this.Tab>)
            }
            {/* <this.Tab> <FaPlus /> </this.Tab> */}

            </this.TabBar>
    }
    TalkList = InfinitList('talk');
    RelatedList = InfinitList('related')
    AntiqueList = InfinitList('antiques')

    PostPromt = styled.div`
        margin-top: 10px
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 10px;
        width: calc(100% - 20px);
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.1);
        text-align: center;
        color: green;
        span {
            margin-right: 20px;
        }
        font-weight: bold;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    `
    tabComponents = {}
    renderTalk() {
        console.log(this.state)
        if (this.state.tabActived === 2) {
            return  <Fragment>
                {/* <Radio cover={this.getData().pic_cover} card={true} /> */}
                <this.RelatedList 
                    ref='talkList'
                    loadMore={this.relatedMore.bind(this)}
                    itemComponent={CoverCard}
                    cacheKey={`related.${this.props.location.pathname}.${this.state.tabActived || 0}`}
                    refreshDisabled={true}
                />
            </Fragment>
        } else if (this.state.tabActived === 1) {
            let data = this.state.data
            console.log(data)
            let images = [data.pic_cover]
            images = [...images, ...(this.state.data.images || [])]
            images = images.map((v, i) => {return v && v.src && v || {src: v}})
            return  <Waterfall 
                        ref='talkList' 
                        cacheKey={`waterfall.${this.props.location.pathname}`}
                        posts={[{images: images, title: '默认'}]} loadMore={this.loadMore.bind(this)} />

        } else if (this.state.tabActived === 0) {
            return  <this.TalkList 
                        ref='talkList'
                        loadMore={this.loadMore.bind(this)}
                        itemComponent={PostCard}
                        cacheKey={`talk.${this.props.location.pathname}.${this.state.tabActived || 0}`}
                        refreshDisabled={true}
                    >
                    </this.TalkList>

        } else {
            let tab = this.tabs[this.state.tabActived]
            let key = `${this.type}.${tab}.${this.props.location.pathname}`
            // if (!this.tabComponents[key]) {
            //     this.tabComponents[key] = InfinitList(key)
            // }
            // let comp = this.tabComponents[key]
            let props = this.renderOthers()
            return <Fragment>
                {props.header && <props.header />}
                <this.AntiqueList 
                            ref='talkList'
                            refreshDisabled={true}
                            cacheKey={key}
                            {...props}
                        />
                </Fragment>
        }
    }
    addExhibit() {
        this.props.history.push({
            pathname: '/itemModify',
            extra: {
                type: 'exhibit',
                museumName: this.getId()
            },
            from: this.props.location.pathname
        })
    }
    renderPromt() {
        const to = {
            pathname: `/post`,
            refer: {...this.state.data, type: this.type}
        }
        let tab = this.tabs[this.state.tabActived]
        if (!getUserInfo()) {
            return <Fragment />
        }
        // if (tab === '相册') {
        //     return <this.PostPromt onClick={e => this.props.history.push(to)}>
        //             <span>我要发图</span>
        //             <FaImage size='20px' />
        //         </this.PostPromt>
        // } else if (tab === '讨论') {
        //     return <this.PostPromt onClick={e => this.props.history.push(to)}>
        //         <span>发个帖子</span>
        //         <FaPen size='20px' />
        //     </this.PostPromt>
        // } else 
        if (this.type === 'museum' && tab === '展览') {
            return <this.PostPromt onClick={e => this.addExhibit()}>
                <span>添加展览</span>
                <FaPlus size='20px' />

            </this.PostPromt>
        } else if (this.type === 'exhibit' && tab === '展品') {
            return <this.PostPromt onClick={e => this.setState({showAddAntique: true})}>
                <span>添加展品</span>
                <FaPlus size='20px' />

            </this.PostPromt>
        }
    }
    Wiki = styled.div`
        padding-left: 20px;
        padding-right: 20px;
        width: calc(100% - 40px);
    `
    Promt = styled.div`
        font-size: small;
        color: gray;
        margin-bottom: 20px;
    `
    renderDesc() {
        return !this.state.data.description && !this.state.data.desc && this.state.data.title && <this.Wiki>
            <this.Promt>
                以下内容来自维基百科，感谢为开放、共享的Web社区和人类社会做出贡献的人们。
            </this.Promt>

            <Wiki title={this.state.data.title} />
            </this.Wiki>
    }
    initDrag() {
        this._mousemove = this.onMouseMove.bind(this)
        this._mouseup = this.onMouseUp.bind(this)
        window.addEventListener('mousemove', this._mousemove)
        window.addEventListener('touchmove', this._mousemove)
        window.addEventListener('mouseup', this._mouseup)
        window.addEventListener('touchend', this._mouseup)
        // document.addEventListener('touchmove', e => {
        //     e.preventDefault()
        // }, {passive: true})
    }

    onMouseDown(e) {
        console.log(e)
        e.preventDefault();
        let rect = this.refs.talkCard.getBoundingClientRect()
        let y = (e.clientY || e.touches && e.touches[0].clientY)
        this.setState({drag: true, startY: y, baseY: rect.y})
    }
    cardMouseDown(e) {
        // return
        if (document.getElementsByClassName('pswp').length > 0) {
            return
        }

        const talkList = this.refs.talkList

        if (!this.state.talkSpread || !talkList || talkList.refs.container.scrollTop === 0) {
            console.log('Scrolllllll', this.refs.talkList, this.talkList, talkList.refs.container.scrollTop)
            e.preventDefault();
            let rect = this.refs.talkCard.getBoundingClientRect()
            let y = (e.clientY || e.touches && e.touches[0].clientY)
            this.setState({drag: true, startY: y, baseY: rect.y}) 
        }
    }
    onMouseMove(e) {

        const talkList = this.refs.talkList
        let y = (e.clientY || e.touches && e.touches[0].clientY)
        console.log('Scrolllllll', this.refs.talkList, this.talkList, y, this.state)
        if (this.state.drag) {

            
            if (talkList && talkList.refs.container.scrollTop === 0 && this.state.talkSpread && y <= this.state.startY) {
                this.setState({drag: false})
                return
            }
            e.preventDefault();
            y = y + this.state.baseY - this.state.startY
            y = Math.min(Math.max(70, y), this.refs.container.offsetHeight - 150)
            console.log(e, y, this.state)
            this.refs.appContainer.style['pointer-events'] = 'none'
            this.refs.talkCard.style.top = `${y}px`
        }
    }
    onMouseUp(e) {
        console.log(this.talkCard, e)

        if (this.state.drag) {
            // let spread = this.refs.talkCard.style.top > this.refs.container.offsetHeight*0.8
            // console.log(this.refs.talkCard.getBoundingClientRect())
            if (!this.refs.talkCard) {
                return
            }
            let spread = !this.state.talkSpread || this.refs.talkCard.getBoundingClientRect().top < 150
            if (!this.state.talkSpread) {
                spread = this.refs.talkCard.getBoundingClientRect().y < (this.refs.container.offsetHeight - 200)
            }
            this.refs.talkCard.style.top = spread && this.upBound || this.bottomBound
            this.refs.appContainer.style['pointer-events'] = 'auto'
            this.setState({drag: false, talkSpread: spread, coverInvisible: true})
        }
    }
    share() {
        let title = `${this.typeName || '多知'} - ${this.state.data.title}`
        shareNav(title, title, this.props.location.pathname)
        // html2canvas(document.body).then(canvas => {
        //     console.log(canvas.toDataURL())
        // })
    }
    renderShare() {
        // return <CoverCard data={{type: this.type, data: this.state.data}} />
    }
    getId() {
        return this.state.data && this.state.data._id
    }

    InfoItem = props => {
        console.log(props)
        let key = props.keyName
        let device = window.device || {}
        if (key === '展馆') {
            return <Link to={`/museum/${props.value}`}>
                {props.value}
            </Link>
        } else if (key === '地址') {
            return <a href={device.model ==='iPhone' && `http://maps.apple.com/?q=${props.value}` || `geo:0,0?q=${props.value}`}>
            {props.value}
            </a>
        } else {
            return <div> {props.value} </div>
        }
    }
    TopCover = styled.div`
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        /* border-radius: 5px; */
        /* background: linear-gradient(to bottom, rgba(0,0,0, 0.5), rgba(0,0,0, 0)); */
        background-color: rgba(0, 0, 0, 0.1);
    `
    BottomCover = styled.div`
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100px;
        /* border-radius: 5px; */
        background: linear-gradient(to top, rgba(0,0,0, 0.5), rgba(0,0,0, 0));
    `
    renderHeaderBanner() {
        let u = getUserInfo() || {}
        // console.log(u,  this.state.data)
        let to = {
            extra: {
                type: this.type,
                id: this.state.data._id,
            },
            data: this.state.data,
            pathname: '/itemModify',
            from: this.props.location.pathname
        }
        return <Fragment>
            {/* <this.Radio /> */}
            
            {u._id === this.state.data.author && <this.PostPromt onClick={e => this.deleteThis()}>
                <span> 删除条目 </span>
                <FaTrash size='20px' />
            </this.PostPromt>  }
            <this.PostPromt onClick={e => this.props.history.push(to)}>
                
                <span> 完善条目 </span>
                <FaEdit size='20px' />
            </this.PostPromt>
            

            </Fragment>
    }
    showSlides() {
        this.setState({showSlides: true})
    }
    async slideLoadMore(offset) {
        return get('/entry', {
            params: {
                id: this.getId(),
                offset: offset,
                limit: 10,
                by: 'exhibit'
            }
        })
    }
    render() {
        // console.log(this.props, this.props.location, this.state.data)
        // if (!this.state.data._id || (!this.props.location.pathname.includes(this.state.data._id))) {
        //     this.init()
        // }
        this.state.data = AppState.update(this.type, this.state.data)
        if (this.state.path !== this.props.location.pathname) {
            // this.props.history.push({pathname: 'redirect', to: this.props.location.pathname})
            this.init()
        }
        let iconProps = {
            color: !this.state.coverInvisible && 'white' || 'black',
            size: '35px'
        }
        this.key = `${this.type}-${this.getId()}`
        this.state['follow'] = getHash('follow', this.key)
        this.state['bookmark'] = getHash('bookmark', this.key)
        return this.state.data._id && <Container ref='appContainer'>
            {this.renderShare()}
            {this.state.showFavPromt && <FavPromt type={this.type} object={this.getId()} onClose={e => {this.setState({showFavPromt: false})}} />}
            <this.Header coverInvisible={this.state.coverInvisible}>
                    <this.HeaderReturn onClick={e => back(this, '/')}>
                        <FaAngleLeft {...iconProps} /> 
                     </this.HeaderReturn>
                    {this.state.coverInvisible && <span>{this.state.data.title}</span>}
                    <this.HeaderShare> 
                        <FaShare {...iconProps}  onClick={this.share.bind(this)}/>
                    </this.HeaderShare>
            </this.Header>
            <this.Container ref='container' 
                // onMouseMove={this.onMouseMove.bind(this)}
                // onMouseUp={this.onMouseUp.bind(this)}
                // onTouchMove={this.onMouseMove.bind(this)}
                onScroll={this.onScroll.bind(this)}
                >

            <ImageCover ref='cover' src={this.getData().pic_cover}>
                <this.TopCover />
                {/* <this.BottomCover /> */}
                <this.CoverTitle>{this.state.data.title} </this.CoverTitle>
                <this.Actions count={3}>
                    <this.Icon onClick={this.showSlides.bind(this)}>
                        {/* <MdSlideshow {...iconProps} /> */}
                        <FaPlay {...iconProps} />
                        <span> 播放 </span>
                    </this.Icon>
                    <this.IconCom icon={FaHeart} label='关注' action='follow' color='red' />
                    <this.IconCom icon={FaBookmark} label='收藏' action='bookmark' color='black' />
                    {/* <Watch type={this.type} object={this.state.data._id} Active={props => <this.IconCom icon={FaHeart} label='关注' {...props} />}
                            Actived={props => <this.IconCom icon={FaHeart} label='关注' color='red' {...props} />}/> */}
                    {/* <Bookmark Active={<this.IconCom icon={FaBookmark} label='收藏' />}
                              Actived={<this.IconCom icon={FaBookmark} label='收藏' color='black'/>} /> */}
                    
                    {/* <FaHeartbeat {...iconProps} label='想去' /> */}
                    {/* <FaBookmark  {...iconProps} label='收藏' /> */}
                </this.Actions>
            </ImageCover>
            {this.state.showSlides && <ImageSlides 
                                onClose={e => this.setState({showSlides: false})}
                                loadMore={this.slideLoadMore.bind(this)} />}
            <this.Info>
                {
                    this.state.data.fields.map((v, i) => <this.Line>
                        <this.Key>{v.key}: </this.Key> 
                        <this.InfoItem keyName={v.key} value={v.value} />
                        </this.Line>)
                }
            </this.Info>
            {this.renderHeaderBanner && this.renderHeaderBanner()}
            {
                !this.state.data.is_draft && <this.HtmlDesc
                        dangerouslySetInnerHTML={{
                        __html: this.state.data.description || this.state.data.desc
                }}/>
            }
            {
                this.state.data.is_draft && <this.HtmlDesc><MarkdownPreview>
                    {this.state.data.desc}
                    </MarkdownPreview></this.HtmlDesc>
            }
            { this.renderDesc && this.renderDesc()}
            <this.Space />

            </this.Container>
            <this.TalkCard spread={this.state.talkSpread} 
                            onMouseDown={this.cardMouseDown.bind(this)}
                            onTouchStart={this.cardMouseDown.bind(this)}
                            ref='talkCard'>
            <this.TouchBar 
                onMouseDown={this.onMouseDown.bind(this)}
                onTouchStart={this.onMouseDown.bind(this)}
                // onMouseMove={this.onMouseMove.bind(this)}
                // onTouchMove={this.onMouseMove.bind(this)}
                // onMouseUp={this.onMouseUp.bind(this)}
                // onClick={e => this.setState({talkSpread: !this.state.talkSpread, coverInvisible: !this.state.talkSpread})}
            >
                {/* <span>{!this.state.talkSpread && '展开' || '收起'}</span> */}

                <this.Banner
                    // onTouchMove={this.onMouseMove.bind(this)}
                    // onMouseMove={this.onMouseMove.bind(this)}
                    // onMouseUp={this.onMouseUp.bind(this)}
                    // onClick={e => this.setState({talkSpread: !this.state.talkSpread, coverInvisible: !this.state.talkSpread})}
                 />
                {this.renderTab()}
            </this.TouchBar>
            {this.renderPromt()}
            {this.renderTalk()}
            {
                    !this.state.talkSpread && <this.TouchCover className='touchCover' onMouseDown={this.cardMouseDown.bind(this)}
                            onTouchStart={this.cardMouseDown.bind(this)}/>
            }
            </this.TalkCard>
            {this.state.showAddAntique && <SearchPromt exhibitId={this.getId()}
                    onClose={e => {this.setState({showAddAntique: false}); this.refs.talkList.onRefresh()}}
                /> }
            <FixedPostIcon
                    bottom='20vh'
                    noRemind={false}
                    to={{
                        pathname: `/post`,
                        refer: {...this.state.data, type: this.type}
                        }}/> {/* {renderHTML(data.description)} */}
        </Container> || <div />
    }
}


class ExhibitRadio extends Radio {
    constructor(props) {
        super(props)
        this.state.data = props.data
    }
    loadMore() {

    }

    PostPromt = styled.div`
        margin-top: 10px
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 10px;
        width: calc(100% - 20px);
        height: 25px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.1);
        text-align: center;
        color: green;
        span {
            margin-right: 20px;
        }
        font-weight: bold;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    `
    renderPlay() {
        return <this.PostPromt onClick={e => this.start()}>
        <span>听展览</span>  <FaHeadphones size='20px' />
        </this.PostPromt>
    }
    renderPlaying() {
        return <this.PostPromt >
        <FaStepBackward size='20px' onClick={e => window.dispatchEvent(new Event('playPrevious'))}/>
        <span>
            {' '}
        </span>
        <FaPause size='20px' onClick={e => this.stop()} />
        <span>
            {' '}
        </span>
        <FaStepForward size='20px' onClick={e => window.dispatchEvent(new Event('playNext'))}/>
        </this.PostPromt>
    }
    render() {
        return super.render()
    }
}

const Exhibit = withRouter(class extends BaseItem {
    tabs = ['讨论', '相册', '相关', '展品']
    // renderHeaderBanner() {
    //     return <this.PostPromt onClick={e => this.props.history.push({pathname: '/itemModify', data: this.state.data})}>
    //         <span>我要完善这个条目</span>  <FaPen size='20px' />
    //         </this.PostPromt>
    // }
    loadMorePosts(offset) {
        return get('/entry', {
            params: {
                id: this.getId(),
                offset: offset,
                limit: 10,
                by: 'exhibit'
            }
        })
    }
    async onDelete(data, e) {
        console.log(data, 'Delete')
        let confirmRet = await Modals.confirm({
            title: '确认',
            message: '您确定该展品不在此展览展出吗？'
          });
        console.log(confirmRet)
        confirmRet.value && post('/exhibit_entry', {
            exhibit_id: this.getId(),
            antique_id: data.antique_id || data._id,
            delete: true
        }).then(res => {
            Modals.alert({
                title: '完成',
                message: '感谢您的贡献，您的删除操作会在我们确认后生效'
            })
            this.refs.talkList.onRefresh()
        })
    }
    Minus = styled.div`
        position: absolute;
        top: 2px;
        right: 10px;
        color: grey;
        font-size: 16px;
    `
    Wrap = styled.div`
        position: relative;
    `
    EntryComponent = props => <this.Wrap>
            <Entry {...props} /> 
            { getUserInfo() && <this.Minus onClick={e => this.onDelete(props.data)}> × </this.Minus> }
        </this.Wrap>
    
    deleteThis() {
        post('/contribute', {
            type: this.type,
            id: this.state.data._id,
            delete: true
        }).then(res => {
            this.props.history.goBack()
        })
    }

    Radio = props => <ExhibitRadio data={this.onData([])} />

    onData(d) {
        let data = d.map((v, i) => {return {data: {title: v.title, wiki: `${v.title}\n${v.desc}`}}})
        let desc = htmlToText.fromString(this.state.data.description || this.state.data.desc, {
            ignoreHref: true,
            ignoreImage: true,
        })
        let data_ = [{data: {title: this.state.data.title, wiki: desc}}, ...data]
        this.state.entryData = data_
        return data_
    }
    renderOthers() {
        // this.onData([])
        return {
                loadMore: this.loadMorePosts.bind(this),
                // onData: d => this.onData(d),
                itemComponent: this.EntryComponent
        }
    }
    render() {
        return super.render()
    }
})

const Unit = withRouter(class extends BaseItem {
    type = 'unit'

})

const Museum = withRouter(class extends BaseItem {
    type = 'museum'
    getId() {
        return this.state.data.title
    }
    tabs = ['讨论', '相册', '展览', '文物']

    loadMorePosts(offset) {
        return get('/entry', {
            params: {
                museum: this.getId(),
                offset: offset,
                limit: 10
            }
        })
    }

    renderOthers() {
        return {
                loadMore: this.loadMorePosts.bind(this),
                itemComponent: Entry,
    
        }
    }
    render() {
        return super.render()
    }
})

const Book = withRouter(class extends BaseItem {
    type = 'book'
    getId() {
        return this.state.data._id
    }
    tabs = ['讨论', '相关']

    render() {
        return super.render()
    }
})

const Antique = withRouter(class extends BaseItem {
    type = 'antique'

    // renderHeaderBanner() {
    //     return <this.PostPromt onClick={e => this.props.history.push({pathname: '/itemModify', data: this.state.data})}>
    //         <span>我要完善这个条目</span>  <FaPen size='20px' />
    //         </this.PostPromt>
    // }
    render() {
        return super.render()
    }
})

const Fav = withRouter(class extends BaseItem {
    type = 'fav'
    typeName = '收藏夹'
    state = {
        data: {}
    }
    constructor(props) {
        super(props)
        this.state.data = this.props.location.data || {}
    }
    render() {
        let iconProps = {
            color: !this.state.coverInvisible && 'white' || 'black',
            size: '25px'
        }
        let data = this.state.data
        console.log(data)

        return <Container ref='appContainer'>

        <this.Header coverInvisible={this.state.coverInvisible}>
                <this.HeaderReturn onClick={e => this.props.history.goBack()}>
                    <FaAngleLeft {...iconProps} /> 
                 </this.HeaderReturn>
                {this.state.coverInvisible && <span>{this.state.data.title}</span>}
                <this.HeaderShare> 
                    <FaShare {...iconProps}  onClick={this.share.bind(this)}/>
                </this.HeaderShare>
        </this.Header>
        <this.Container ref='container' 
            // onMouseMove={this.onMouseMove.bind(this)}
            // onMouseUp={this.onMouseUp.bind(this)}
            // onTouchMove={this.onMouseMove.bind(this)}
            onScroll={this.onScroll.bind(this)}
            >

        <ImageCover ref='cover' src={this.state.data.cover}>
            <this.CoverTitle>{this.state.data.name} </this.CoverTitle>
            <this.Actions count={2}>
                <this.IconCom icon={FaHeart} label='关注' action='follow' color='red' />
            </this.Actions>
        </ImageCover>

        <this.Info>

        </this.Info>
        <this.Space />
        {
            data.items && data.items.map((v, i) => 
                <CoverCard data={v} />)
        }
        </this.Container>
        </Container>
    }

})


const FavPage = withRouter(class extends FavPromt {
    Card = styled.div`
        width: (100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        height: 100px;
        border-bottom: 1px solid lightgrey;
        background-color: white;
    `
    type = 'fav'
    Cover = styled.img`
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 10px;
    `
    init() {
        this.cacheKey = `favPage/${this.props.location.pathname}`
        this.state = IndexDB.get(this.cacheKey) || {}
        console.log('Init', this.state)
        get(this.props.location.pathname).then(res => {
            console.log(res)
            let data = res.data
            this.state.cover = data.cover
            this.state.title = data.name
            this.state.description = data.description
            this.setState({data: res.data})
        })

    }

    componentDidMount() {
        console.log('Mount', this.state)
        if (this.refs.body) {
            this.refs.body.scrollTop = this.state.top || 0
        }
    }

    componentWillUnmount() {
        this.state.top = this.refs.body.scrollTop
        console.log('Set', this.state)
        IndexDB.set(this.cacheKey, this.state)
    }
    Header = styled(this.FavDesc)`
        /* flex-direction: column; */
        font-size: 20px;
    `
    FavInfo = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 80px; 
        width: 70%;
    `
    Actions = styled.div`
        height: 80px;
        /* align-items: baseline; */
        display: flex;
        /* width: 90px; */
        justify-content: space-between;
        /* vertical-align: bottom; */
    `
    Icon = styled.div`
        width: 35px;
        height: 45px;
        display: flex;
        flex-direction: column;
        font-size: 10px;
        color: rgba(0, 0, 0, 0.5);
        align-items: center;
    `
    Quote = styled.blockquote`
        margin-top: 20px;
        margin-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
        width: calc(100% - 40px);
        color: gray;
        font-size: 14px;
        text-align: center;
        background-color: white;
    `
    Body = styled.div`
        width: 100%;
        height: 100%;
        position: relative;
        top: 50px;
        background-color: whitesmoke;
        overflow-Y: auto;
        padding-bottom: 200px;
    `
    FavCard = styled.div`
        width: 100%;
        background-color: white;
        /* border-bottom: 1px solid lightgrey; */
        margin-bottom: 10px;
        padding-top: 10px;
    `
    FavQuote = styled.div`
        width: (100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-weight: bold;
        font-size: 14px;
        color: gray;
        strong {
            color: black;
        }
        /* background-color: rgba(0, 0, 0, 0.1); */
    `
    CardHeader = styled.div`
        width: (100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-weight: bold;
        font-size: 14px;
        color: gray;
        strong {
            color: black;
        }
        span {
            font-size: 16px;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
    `
    createFav() {
        post('/fav', {
            cover: this.state.cover,
            name: this.refs.title.value,
            description: this.refs.input.value,
            _id: this.state.data._id
        }).then(res => {
            let data = {...this.state.data, ...res.data}
            this.setState({data: data, showCreate: false})
        })
    }
    deleteFav() {
        post('/fav', {
            _id: this.state.data._id,
            delete: true
        }).then(res => {
            this.setState({showCreate: false})
            this.props.history.goBack()
        })
    }
    follow() {
        let action = 'follow'
        let o = {
            type: this.type,
            object: this.state.data._id,
            delete: !!this.state[action]
        }
        setHash(action, this.key, !this.state[action])
        if (!getUserInfo()) {
            // this.setState({
            //     [action]: !this.state[action]
            // })
            // addAction(o, action)
            // return
            return
        }
        post(action, o).then(res => {
            console.log(res)
            this.setState({
                [action]: !this.state[action]
            })
        })
    }
    share() {
        let title = `@${this.state.data.user.nickName}的收藏夹： ${this.state.data.name}`
        return shareNav(title, title, this.props.location.pathname)
    }
    async deleteItem(item) {
        let confirmRet = await Modals.confirm({
            title: '确认',
            message: '您确定要删除该收藏吗？'
          });
        confirmRet.value && post('/fav/item', {
            ...item,
            delete: true
        }).then(res => {
            this.init()
        })
    }
    render() {
        let data = this.state.data || {}

        let isMe = getUserInfo() && getUserInfo()._id === (data.user && data.user._id)
        this.key = `${this.type}-${data._id}`
        this.state['follow'] = getHash('follow', this.key)
        return data.user && <Container>
            {this.renderCreate()}
            <Bar back={e => back(this, '/')} share={this.share.bind(this)}/>
            <this.Body ref='body'>
            <this.Card>
                <this.Cover src={toPageSrc(data.cover)} />
                <this.FavInfo>
                    <this.Header>
                        <this.Title> {data.name} </this.Title>
                        {/* <this.Desc> {data.items && data.items.length} 条目 </this.Desc> */}
                    </this.Header>
                    {/* <this.Desc> {data.description} </this.Desc> */}
                    <this.Desc>
                        <Link to={`/user/${data.user._id}`}> @{data.user.nickName} </Link>  创建于 {data.time_desc} 
                    </this.Desc>
                    <this.Desc> {data.description} </this.Desc>
                </this.FavInfo>
                <this.Middle />
                <this.Actions>
                    {<this.Icon onClick={this.follow.bind(this)}>
                        <FaHeart size='100%' color={this.state.follow && 'red'}/>
                        <span> 关注 </span>
                    </this.Icon>}
                    { isMe &&
                    <this.Icon onClick={e => this.setState({showCreate: true, modifyFav: true})}>
                        <FaPen size='100%' />
                        <span> 编辑 </span>
                    </this.Icon>
                    }
                </this.Actions>
            </this.Card>
            <this.Quote>
            </this.Quote>
            {
                data.items && data.items.map((v, i) => {
                    return <this.FavCard key={i}>
                        <this.CardHeader>
                        <strong> {v.time_desc} 收藏    </strong>
                        <span onClick={e => this.deleteItem(v)}> {isMe && '×'} </span>
                        </this.CardHeader>
                        { v.description && 
                        <this.FavQuote> 
                             {`@${data.user.nickName}: ${v.description}`}
                        </this.FavQuote>
                        }
                        <this.FavQuote>
                            
                        </this.FavQuote>
                        {v.type !== 'post' && <GeneralCard data={v} /> }
                        {v.type === 'post' && <PostCard data={v.data} />} 
                    </this.FavCard>
                })
            }
            </this.Body>
            </Container> || <div />
    }
})

export {Exhibit, Unit, Antique, Museum, FavPage, BaseItem, Book}
