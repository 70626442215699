import React, {Fragment} from 'react';

import {Watch} from './Actions';
import styled from 'styled-components';
import {withRouter, Link} from 'react-router-dom';
import { Wiki, Markdown } from './Wiki';
import { get, post } from './Backend';
import { Plugins } from '@capacitor/core';
import { logo } from './Config';
import Avator from 'react-avatar';
import { Colors } from "@blueprintjs/core";
import {getCache, setCache, setHash, IndexDB, getRefresh} from './State';
import { getCity, getLoc } from './City';
import { FaHeadphones, FaPauseCircle } from 'react-icons/fa';
import {PostCard} from './Discuss';
import { Container, Body, SearchBox } from './Common';
import { AddressBar, FixedPostIcon } from './Detail';
import { InfinitList as genList } from './Basics';
import { TagCard } from './Card';
import { CityHeader as Sticky, Headers, HeaderTab, SortTab } from './FirstV1';
import queryString from 'querystring';
import Swipe from 'react-easy-swipe';

const Talk = withRouter(class extends React.Component {
    PostList = genList('posts')
    lists = {

    }
    related = {

    }
    loadMore(offset) {
        let type = this.state.type
        let tag = this.state.tab._id
        let order = this.state.tab.order
        if (this.state.actived === '话题' && this.state.tab.activedTag) {
            tag = this.state.tab.activedTag
            type = 'tag'
            order = undefined
        }
        let params = {
            offset: offset,
            limit: 5,
            order: order,
            type: type,
            id: type === 'tag' && tag,
        }
        console.log(offset, params, this.state)
        return get('post', {
            params: params
        })
    }
    onData(data) {
        let tags = []
        data.map((v, i) => {
            tags = [...tags, ...(v.tags || []), ...(v._tags || [])]
        })
        tags = [...new Set(tags)]
        let tags_ = tags.map((t, _) => {return {_id: t}})
        this.related[this.state.actived] = tags
        console.log(tags_)
        this.setState({tags: tags_})

    }
    renderPosts(refresh) {
        let key = `talk.${this.state.actived}.${this.state.tab.activedTag}`
        if (!this.lists[key]) {
            this.lists[key] = genList(key)
        }
        let List = this.lists[key]
        console.log('renderPosts', key, refresh)
        // return  <Swipe
        //             onSwipeMove={this.onSwipeMove.bind(this)}
        //             onSwipeEnd={this.onSwipeEnd.bind(this)}
        //         >
        return <List 
                cacheKey={key}
                itemComponent={PostCard}
                loadMore={this.loadMore.bind(this)} 
                refresh={refresh}
                upScroll={this.upScroll.bind(this)}
                downScroll={this.downScroll.bind(this)}
                onData={this.onData.bind(this)}
            />
                // </Swipe>
    }
    Div = styled.div`
        height: 10px;
    `
    Control = styled.div`
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
        display: flex;
        /* height: 100%; */
        /* height: 50px; */
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow: auto;
        /* background-image: linear-gradient(to right , rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2)); */
        /* height: 40px; */
    `
    ExpandControl = styled(this.Control)`
        width: 100%;
        /* overflow: auto; */
        /* max-height: ''; */
        /* max-height: 50vh; */
        flex-wrap: wrap;
    `
    Tag = styled.div`
        background-color: rgba(211,211,211, 0.2);
        color: gray;
        display: inline-block;
        /* font-size: small; */
        /* margin-right: 10px; */
        margin-left: 10px;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 5px;
        margin-top: 10px;
        float: left;
        /* height: 22px; */
        flex-shrink: 0;
        font-size: 16px;
        /* max-width: 33%; */
        /* display: -webkit-box; */
        /* overflow: hidden; */
        /* -webkit-line-clamp: 1; */
    `
    Wid = styled.div`
        width: 10px;
    `
    DeepTag = styled(this.Tag)`
        /* background-color: gray; */
        /* color: white; */
        margin-left: 0;
    `
    ActivedTag = styled(this.Tag)`
        background-color: green;
        color: white;
    `
    TagComp = props => {
        return <Fragment>
            {props.actived && <this.ActivedTag {...props} /> || <this.Tag {...props} />}
        </Fragment>
    }
    upScroll(top, height) {
        // console.log('upScroll')
        // this.refs.control.style.height = '100px'
        if (top < 10 && !this.state.showCard) {
            // TODO: try more
            this.setState({relatedLength: 20, showCard: true})
        }
    }
    downScroll(top, height) {
        // console.log('downScroll')
        if (height - top > 50 && top > 10) {
            this.setState({relatedLength: 4, showCard: false}) 
        }
        // this.refs.control.style.height = '20px'
    }

    tabs = [
        {_id: '关注', order: 'follow', tips: '关注的人、话题和条目'},
        {_id: '推荐', order: 'recommend', tips: '多知推荐'},
        {_id: '最新', order: 'new', tips: '最新的帖子'},
        {_id: '话题', order: 'new', tips: '我们之间有话题'}
        // {_id: '文物', order: 'antique', tips: '关于文物'},
        // {_id: '展览', order: 'exhibit', tips: '关于展览'},
        // {_id: '多知', order: 'about', tips: '关于多知产品'},
        // {_id: '视频', order: 'video'},
    ]
    state = {
        actived: '最新',
        tab: this.tabs[2],
        followedTags: []
    }
    key = 'talk.new.Fist'

    constructor(props) {
        super(props)
        this.state.refresh = this.props.location.refresh
        this.state = IndexDB.get(this.key) || this.state
        // console.log(this.state)
    }
    componentWillUnmount() {
        IndexDB.set(this.key, this.state)
    }
    componentDidMount() {
        setTimeout(e => this.sync(), 1000);
    }

    sync() {
        post('sync', {actions: getCache('_actions')}).then(res => {
            console.log('Sync', res)
            setCache('_actions', [])
            let setter = name => {
                res.data[name].map((v, i) => {
                    let key = `${v.type}-${v.object}`
                    setHash(name, key, true)
                })
            }
            setter('bookmark')
            setter('like')
            setter('follow')
            IndexDB.set('followRows', res.data['follow'])
            this.getFollowedTags()
        })
    }
    getFollowedTags() {
        let r = IndexDB.get('followRows')
        console.log(r)
        let tags = r && r.filter(v => v.type === 'tag')
        let tags_ = tags.map((v, i) => {return v.object}).filter((v, i, a) => a.indexOf(v) === i).map((v, i) => {return {_id: v}})
        this.setState({followedTags: tags_.reverse()})

    }
    onTabClick(tab) {
        // this.setState({actived: tab._id, type: null, tab: tab, refresh: true})
        this.state.refresh = true
        this.props.history.replace(`/talk?chapter=${tab._id}`)
        this.setState({})
    }
    onTagClick(tag) {
        // this.props.history.push(`/tag/${tag._id.slice(1, tag._id.length)}`)
        // this.setState({actived: tag._id, type: 'tag',
        //                 showCard: true,
        //                 relatedLength: 10,
        //                tab: tag, refresh: true})
        get(`/tag/${tag._id.slice(1)}`).then(res => {
            console.log(res)
            this.setState({tagData: {[tag._id]: res.data}, showCard: true})
            // console.log(this.state)
        })
        if (this.state.actived === '话题') {
            this.state.refresh = true
            this.props.history.replace(`/talk?chapter=${this.state.actived}&tag=${tag._id.slice(1)}`)
            return this.setState({})
        }
        this.state.refresh = true
        this.props.history.replace(`/talk?chapter=${tag._id.slice(1)}&type=tag`)
        this.setState({})

    }
    More = styled.div`
        color: green;
        font-size: small;
        display: inline-block;
        margin-left: 20px;
    `
    Headers = styled.div`
        width: calc(100% - 10px);
        /* padding-bottom: 10px; */
        margin-left: 5px;
        margin-right: 5px;
        /* margin-bottom: 5px; */
        border-bottom: 1px solid lightgrey;
        position: relative;
        display: flex;
        justify-content: space-between;
        /* padding-left: 20px;
        padding-right: 20px; */
        /* height: 35px; */
        /* align-items: baseline; */
    `
    SortTab = styled(SortTab)`
        font-size: 20px;
    `
    HeaderTab = styled.div`
        font-size: 16px;
        font-weight: bold;
        font-family: '黑体';
        color: rgba(244, 244, 244);
        color: ${props => props.actived && 'green'};
    `
    TagLine = styled.div`
        width: 100%;
        display: flex;
        background-color: white;
        align-items: baseline;
        /* height: auto; */
        /* position: relative; */
        height: 45px;
        padding-bottom: 5px;
        /* border-bottom: 1px solid lightgrey; */
    `
    Corner = styled.div`
        position: absolute;
        right: 0;
        font-size: small;
        /* width: 100px; */
        /* height: 100%; */
        float: right;
        color: white;
        flex-shrink: 0;
        background-color: rgba(0, 0, 0, 0.5);
    `
    Sticky = styled(Sticky)`
        margin-left: auto;
        margin-right: auto;
        /* box-shadow: 0 0 3px gray; */
        padding-bottom: 5px;
        /* border-bottom: 1px solid lightgrey; */
    `
    renderLineTags(title, tags_) {
        let tagsets = new Set()
        let tags = tags_.map((v, i) => {
            if (!tagsets.has(v._id)) {
                tagsets.add(v._id)
                return v
            }
            return null
        }).filter(v => v)
        let actived = this.state.actived || '推荐'
        if (actived === '话题') {
            actived =  this.state.tab.activedTag
        }
        return <this.TagLine>
        {tags && <this.DeepTag onClick={e => this.props.history.push('/square?chapter=话题')}> {'全部▼'} </this.DeepTag> }
        <this.Control>
            {
                tags && tags.map((v, i) => 
                        <this.TagComp onClick={e => this.onTagClick(v)} actived={v._id === actived} key={v._id}>{v._id}</this.TagComp> )
            }

        </this.Control>
        {/* <this.Corner>
            展开
        </this.Corner> */}
        </this.TagLine>
    }
    renderTagCard(title, tags) {
        let actived = this.state.actived || '推荐'
        if (actived === '关注') {
            actived =  this.state.tab.activedTag 
        }
        return <Fragment>
            <this.ExpandControl>
                {tags && <this.DeepTag> {title} </this.DeepTag> }
                {
                    tags && tags.map((v, i) => 
                            <this.TagComp onClick={e => this.onTagClick(v)} actived={v._id === actived} key={v._id}>{v._id}</this.TagComp> )
                }
            </this.ExpandControl>
            
            </Fragment>
    }
    FollowPromt = styled.div`
        margin-top: 10px
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 10px;
        width: calc(100% - 40px);
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.1);
        text-align: center;
        
    `
    renderFollow(refresh) {
        return <Fragment>
            <this.FollowPromt onClick={e => this.props.history.push('/square')}>
                去 <Link>多知广场</Link> 看看，选一些人和话题关注吧~
            </this.FollowPromt>
            { this.renderPosts(refresh) }
            </Fragment>
    }
    renderTopic(refresh) {
        console.log(this.state)
        return <Fragment>
            { this.renderLineTags('', [...this.state.followedTags, ...(this.state.tags || [])]) }
            {/* { this.renderPosts(refresh) } */}
            {this.state.tagData && this.state.tagData[this.state.tab.activedTag] && <div><this.Div /><TagCard data={this.state.tagData[this.state.tab.activedTag]}/></div>}
        </Fragment>
    }
    renderTagHeader() {
        let title = '话题'
        let tags = this.state.tags
        if (this.state.tab._id === '关注') {
            title = this.state.tab._id
            tags = this.state.followedTags
        }

        return <Fragment>
                    {this.renderLineTags(title, tags)}
                    {/* {!this.state.showCard && this.renderLineTags(title, tags)}
                    {
                        this.state.showCard && this.renderTagCard(title, tags)
                    } */}
                    {this.state.tagData && this.state.tagData[this.state.actived] && <div><this.Div /><TagCard data={this.state.tagData[this.state.actived]}/></div>}
            </Fragment>
    }
    getTabById(_id) {
        for (var i in this.tabs) {
            if (this.tabs[i]._id === _id) {
                return this.tabs[i]
            }
        }
    }
    onSwipeMove(p, e) {
        let idx
        this.tabs.map((v, i) => {
            if (v._id === this.state.actived) {
                idx = i
            }
        })
        if (p.x > 20) {
            this.state.toNext = (idx + 1)%this.tabs.length
        } else {
            this.state.toNext = (idx - 1)%this.tabs.length
        }
        console.log(this.state.toNext)
    }
    onSwipeEnd() {
        this.onTabClick(this.tabs[this.state.toNext])
    }
    HeaderTab = styled(HeaderTab)`

    `
    render() {
        let values = queryString.parse(this.props.location.search.slice(1));
        let tab = values.type === 'tag' && {_id: `#${values.chapter}`} || this.getTabById(values.chapter)
        console.log(values, tab)
        if (values.chapter && tab) {
            this.state.actived = tab._id
            this.state.type = values.type
            this.state.tab = tab
            tab.activedTag = values.tag && `#${values.tag}`
        }
           
        let refresh = this.state.refresh || getRefresh('post') || getRefresh('home') || this.props.location.refresh; 
        if (refresh) {
            this.state.refresh = false
        }
        let actived = this.state.actived || '推荐'
        let Control = this.state.showCard && this.ExpandControl || this.Control
        return <Container>
                <AddressBar title={this.state.actived} tip={this.state.tab.tips}/>
                {/* <SearchBox
                    onFocus={e => this
                    .props
                    .history
                    .push('/search')}
                    placeholder='搜索 文物/展览/博物馆 ...'/> */}
                <Body width='100%' ref='body'>
                    <this.Div />
                    {/* <Sticky>
                        <this.Headers>

                            {
                                this.tabs.map((v, i) => <this.HeaderTab 
                                                            onClick={ e => this.setState({actived: v._id, tab: v})}
                                                            actived={v._id === actived} >
                                                            {v._id}
                                                        </this.HeaderTab>)
                            }
                            <this.SortTab>
                                帖子▼
                            </this.SortTab>
                        </this.Headers>
                    </Sticky> */}
                    <this.Sticky>
                    <this.Headers ref='control'>
                        {/* {this.state.tags && <this.DeepTag>全部</this.DeepTag> } */}
                        {
                            this.tabs.map((v, i) => <this.HeaderTab onClick={e => this.onTabClick(v)} 
                                                        actived={v._id === actived} key={v._id}>{v._id}</this.HeaderTab>)
                        }
                    </this.Headers>
                    {/* {this.renderTagHeader()} */}
                    {this.state.tab._id === '话题' && this.renderTopic(refresh)}
                    </this.Sticky>
                    {/* {this.renderTagHeader()} */}

                    {this.state.tab._id !== '关注' && this.renderPosts(refresh)}
                    {this.state.tab._id === '关注' && this.renderFollow(refresh)}
                    {/* </Swipe> */}
                </Body>
                <FixedPostIcon bottom='100px'
                            to={{
                                    pathname: `/post`,
                                    refer: {id: this.state.actived, type: this.state.type}
                                }}
                 />
            </Container>
    }
})

export {Talk}