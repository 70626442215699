import React, { Fragment } from 'react';
import {withRouter} from 'react-router';
import styled from 'styled-components';
import {Icon} from 'react-icons-kit';
import {bookmark} from 'react-icons-kit/feather/bookmark';
import {heart} from 'react-icons-kit/feather/heart';
import {share} from 'react-icons-kit/feather/share';
import {eyeOff} from 'react-icons-kit/feather/eyeOff'
import {eye} from 'react-icons-kit/feather/eye'
import {messageCircle} from 'react-icons-kit/feather/messageCircle';
import {setRefresh, getCache, setCache, getHash, setHash} from './State';
import {post, shareNav, getUserInfo} from './Backend';
import { FavPromt, FavState } from './Fav';
// import {Toast} from 'react-weui';

const Actions = styled.div `
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    /* margin-top: 10px; */
`

const ActionStyle = styled.div`
    color: black;
    /* font-family: "Helvetica Neue",Helvetica,Arial,"Microsoft Yahei","Hiragino Sans GB","Heiti SC","WenQuanYi Micro Hei",sans-serif; */
    /* font-size: small; */
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Label = styled.span`
    padding-left: 5px;
    font-size: 12px;
`

const ActionIcon = props => <ActionStyle><Icon tag='span' {...props} size={'15px'}/><Label>{props.label}</Label></ActionStyle>
let bookmarked = JSON.parse(JSON.stringify(bookmark));
bookmarked.attribs.fill = 'black'

let hearted = JSON.parse(JSON.stringify(heart));
hearted.attribs.fill = 'red'
hearted.attribs.color = 'red'

const ShareIcon = withRouter(props => <Icon icon={share} {...props} size={'15px'} onClick={e => shareNav(props.title, props.text, props.location.pathname)} touched={e => shareNav(props.title, props.text, props.location.pathname)} />)

const addAction = (o, action) => {
    let actions = getCache('_actions') || []
    actions = [...actions, {...o, action: action}]
    setCache('_actions', actions)
}

class Like extends React.Component {
    state = {

    }
    constructor(props) {
        super(props)
        this.key = `${props.type}-${props.object}`
        this.state.liked = getHash('like', this.key)
        // props.liked
    }
    like() {
        setHash('like', this.key, !this.state.liked)
        let o = {
            type: this.props.type,
            object: this.props.object,
            delete: !!this.state.liked
        }
        if (!getUserInfo()) {
            this.setState({
                liked: !this.state.liked
            })
            addAction(o, 'like')
            return
        }
        post('like', o).then(res => {
            this.setState({
                liked: !this.state.liked
            })
            this.props.done && this.props.done(this.state.liked)
        })
    }

    render() {
        if (this.state.liked) {
            return <ActionIcon icon={hearted} onClick={this.like.bind(this)} touched={this.like.bind(this)} />
        } else {
            return <ActionIcon icon={heart} onClick={this.like.bind(this)} touched={this.like.bind(this)} />
        }
    }
}

class Watch extends React.Component {
    state = {
    }
    constructor(props) {
        super(props)
        console.log(props)
        this.key = `${props.type}-${props.object}`
        this.state.watched = getHash(this.prefix, this.key)
        // this.state.watched = props.watched
    }
    prefix = 'follow'
    watch() {
        console.log(this.state, this.props)
        let o = {
            type: this.props.type,
            object: this.props.object,
            delete: !!this.state.watched
        }
        setHash(this.prefix, this.key, !this.state.watched)
        if (!getUserInfo()) {
            this.setState({
                watched: !this.state.watched
            })
            addAction(o, this.prefix)
            return
        }
        post('follow', o).then(res => {
            this.setState({
                watched: !this.state.watched
            })
        })
        this.props.done && this.props.done(this.state.watched)
    }
    render() {
        let actived = React.createElement(this.props.Actived || ActionIcon, {
            icon: eyeOff,
            label: this.props.withLabel && '已关注',
            onClick: this.watch.bind(this),
            touched: this.watch.bind(this)
        })
        let active = React.createElement(this.props.Active || ActionIcon, {
            icon: eye,
            label: this.props.withLabel && '关注',
            onClick: this.watch.bind(this),
            touched: this.watch.bind(this)
        })
        if (this.state.watched) {
            return actived;
        } else {
            return active;
        }
    }
}

class Bookmark extends React.Component {
    state = {

    }
    constructor(props) {
        super(props)
        this.key = `${props.type}-${props.object}`
        this.state.bookmarked = FavState.isBookMarked(props.type, props.object)
    }
    bookmark() {
        let o = {
            type: this.props.type,
            object: this.props.object,
            delete: this.state.bookmarked || false
        }
        return this.setState({showFav: true})
        // setHash('bookmark', this.key, !this.state.bookmarked)
        // if (!getUserInfo()) {
        //     this.setState({
        //         bookmarked: !this.state.bookmarked
        //     })
        //     addAction(o, 'bookmark')
        //     return
        // }
        // post('bookmark', o).then(res => {
        //     let data = this.state;
        //     setRefresh('home')
        //     this.setState({bookmarked: !data.bookmarked})
        //     this.props.done && this.props.done(this.state.bookmarked)
        // })
    }
    render() {
        let icon
        if (this.state.bookmarked) {
            icon = <ActionIcon 
                    icon={bookmarked} 
                    label={this.props.withLabel && '已收藏'}
                    onClick={this.bookmark.bind(this)} 
                    touched={this.bookmark.bind(this)} />
        } else {
            icon = <ActionIcon 
                        icon={bookmark} 
                        label={this.props.withLabel && '收藏'}
                        onClick={this.bookmark.bind(this)} 
                        touched={this.bookmark.bind(this)} />
        }
        return <Fragment>
            {this.state.showFav && <FavPromt type={this.props.type} object={this.props.object} onClose={e => this.setState({showFav: false})}/>}
            {icon}
            </Fragment>
    }
}

export {ActionIcon, Bookmark, ActionStyle, Watch, Like, ShareIcon}