import React from 'react';
import {SearchBox, Tabbed, Feed, Content, Container, Body, Scroller, End} from './Common.js';
import {Entry, Museum, Exhibit} from './Card.js';
import { Page, Card } from 'react-onsenui';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import {Icon} from 'react-icons-kit';
import {home} from 'react-icons-kit/feather/home';
import { GeneralCard } from './Detail';
import { get } from './Backend';
import { Markdown, MarkdownPreview } from './Wiki';
import { getCache, setCache, getRefresh, setRefresh } from './State';

const StyledSearchBox = styled(SearchBox)`
    position: absolute;
    margin-bottom: 10px;
`

const Group = styled(Card)`
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 100%;
    /* height: 100vh; */
    border-radius: 0;
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: white;
    margin-left: 0;
`

const GroupHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    /* width: 100%; */
    /* border-bottom: 1px solid lightgrey; */
    strong {
        font-size: 1rem;
        vertical-align: bottom;
    }
    span {
        color: lightgrey;
        font-size: 0.8rem;
        vertical-align: bottom;
    }
`

const WikiCard = withRouter(class extends React.Component {
    render() {
        let v = this.props.data
        return <Group>
        <GroupHeader>
            <strong> 维基百科: {v.title} </strong>
            <Link to={`/wiki/page/${v.title}`}>
                <span>查看全部 > </span>
            </Link>
        </GroupHeader>
        <MarkdownPreview source={v.digest} />
    </Group>
    }
})

const _Scroller = styled(Scroller)`
    height: 90%;

    /* overflow: auto; */
`

const C = styled.div`
    width: 100%;
    /* height: 100vh; */
    padding-bottom: 20vh;
    overflow-x: hidden;
`
const SearchFeed = withRouter(class extends React.Component {
    state = {}
    constructor(props) {
        super(props)
        let cache = getCache(this.key())
        let refresh = props.refresh || getRefresh('search')
        if (refresh || !cache) {
            get(props.dataApi, {params: {type: 'all'}}).then(res => {
                this.setState({data: res.data})
            })
        } else {
            this.state = cache
        }
    }
    renderGroup(v) {
        return <Group>
            <GroupHeader>
                <strong> {v.title} </strong>
                <span onClick={e => this.props.history.replace(`${this.props.location.pathname}?section=${v.title}`)}>查看全部 > </span>
            </GroupHeader>
            {
                v.data.map((v, i) => {
                    return <GeneralCard data={v} key={v.data._id} />
                })
            }
        </Group>
    }
    key() {
        return `search/main/${this.props.dataApi}`
    }
    componentDidMount() {
        console.log(this.state)
        if (this.state.scrollTop && this.state.scrollTop !== 0) {
            setTimeout(e => {this.refs.scroller.scrollTop = this.state.scrollTop;}, 10);
        }
    }
    componentWillUnmount() {
        this.state.scrollTop = this.refs.scroller && this.refs.scroller.scrollTop
        setCache(this.key(), this.state)
    }
    render() {
        console.log(this.state.data)
        return this.state.data && <_Scroller>
            <C  ref='scroller'>
            {
                this.state.data.map((v, i) => {
                    if (!v.group) {
                        return <WikiCard data={v.data} key={v.data._id} />
                    } else {
                        return this.renderGroup(v)
                    }
                })
            }
            </C>
            {/* <End /> */}
        </_Scroller> || <div />
    }
})

class SearchPage extends React.Component {
    state = {
        lastChanged: Date.now(),
        searchWord: '',
        searchInput: '',
        prefix: ''
    }

    constructor(props) {
        super(props);
        let q = this.props.location.pathname.split('/')[2];
        this.state.prefix = this.props.location.pathname;
        this.state.searchWord = q;
        this.state.searchInput = q;
        this.state.refresh = this.props.history.action !== 'POP';
        this.initQuery = q;

        console.log('Constructor', this.props.history);
    }

    timeout = 300;
    checkSearch(e) {
        // console.log(e);

        let now = Date.now();
        // if (e) {
        //     this.props.history.replace(`/redirect?to="/search/${this.state.searchInput}"`)
        // }
        if ((now - this.state.lastChanged) < this.timeout) {
            console.log(now, this.state.lastChanged);
            return
        }
        if (this.state.searchInput === this.state.searchWord)  {
            return
        }
        console.log(this.state, 'Trigger Search')
        setRefresh('search')
        this.setState({
            searchWord: this.state.searchInput,
            lastChanged: now,
            prefix: `/search/${this.state.searchInput}`,
            refresh: true
        })
        // this.state.refresh = false;
        this.props.history.replace(`/search/${this.state.searchInput}`)

        // TODO: fix it
        // this.props.location.pathname = `/search/${this.state.searchInput}`


    }
    onChange(e) {
        // this.state.searchInput = e.currentTarget.value;
        // this.state.lastChanged = Date.now()
        this.setState({
            lastChanged: Date.now(),
            searchInput: e.currentTarget.value,
        })
        console.log(this.state)
        console.log(e, this.state.lastChanged, e.currentTarget.value, e.key)
        setTimeout(this.checkSearch.bind(this), this.timeout)
    }

    onblur(e) {
        if (!this.state.searchInput) {
            this.props.history.goBack();
        }
    }
    tabs = [
        ['综合', props => <SearchFeed dataApi={props.prefix} data={props.data} />],
        ['展览', (props) => <Feed type='exhibit' dataType={`${props.prefix}`} {...props} component={GeneralCard} />],
        ['博物馆', (props) => <Feed type='museum' dataType={`${props.prefix}`} {...props} component={GeneralCard} />],
        ['名胜古迹', (props) => <Feed type='unit' dataType={`${props.prefix}`} {...props} component={GeneralCard} />],
        ['文物', (props) => <Feed type='antique' dataType={`${props.prefix}`} {...props} component={GeneralCard} />],
        // ['书', (props) => <Feed type='book' dataType={`${props.prefix}`} {...props} component={GeneralCard} />],
    ]
    render() {
        let q = this.props.location.pathname.split('/')[2];
        console.log('Rendered!', q, this.props.location.pathname)
        return (
            <Container>
                <StyledSearchBox 
                        //    onSubmit={this.checkSearch.bind(this)}
                        onKeyPress={this.checkSearch.bind(this)}
                        defaultValue={q}
                        enableCancel="true"
                        autofocus="autofocus"
                        onChange={e => this.onChange(e)}
                        //    onblur={this.onblur.bind(this)} 
                           />
                <Body width='96%'>
                    { q && q === this.state.searchInput && <Tabbed prefix={this.state.prefix} tabs={this.tabs} refresh={this.state.refresh} />}
                </Body>
            </Container>
        )
    }
}

export default withRouter(SearchPage);