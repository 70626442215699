import React from 'react';
import {
    Container,
    Body,
    StyledToolbar,
    Tabbed,
    Feed,
    Scroller
} from './Common.js';
import styled from 'styled-components';
import {ToolbarButton, Button as Button_} from 'react-onsenui';
import {Input as Input_, 
    Cell as Cell_, Form, FormCell, CellBody, CellFooter,
    Toast,
    Radio} from 'react-weui';
import {withRouter} from 'react-router';
import {get, post, getUserInfo, setUserInfo, removeUserInfo, shareNav, back} from './Backend.js';
import Avator from 'react-avatar';
import {Entry, Exhibit} from './Card.js';
import {getRefresh, getCache, setCache, setHash, setRefresh, clearCache} from './State';
import {PostList, PostCard} from './Discuss';
import { Watch } from './Actions';
import { Actions, GeneralCard, TagSelect, FixedPostIcon } from './Detail';
import { Icon } from 'react-icons-kit';
import {x} from 'react-icons-kit/feather/x';
import {Redirect} from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Plugins, CameraResultType, CameraSource } from '@capacitor/core';
import { Portal } from 'react-portal';
import { Back, Bar } from './Basics';
// import Viewer from 'react-viewer';
import { PhotoSwipe } from 'react-photoswipe';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { FavList } from './Fav';


let { Camera } = Plugins
let HeaderCache = {}
const HeadSetting = withRouter(class extends React.Component {
    Header = styled.div`
        position: absolute;
        top: 0;
        left: 0;
        height: 50px;
        width: 100%;
        background-color: white;
        z-index: 1000;
    `
    Return = styled.div`
        position: absolute;
        left: 20px;
        top: 10px;
    `
    Done = styled.div`
        position: absolute;
        right: 20px;
        top: 10px;
        color: green;
    `
    Body = styled.div`
        width: 100%;
        height: calc(100% - 50px);
        position: absolute;
        top: 50px;
        background-color: rgba(0, 0, 0, 0.9);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .ReactCrop {
            margin-left: auto;
            margin-right: auto;
        }
    `
    Center = styled.div`
        position: absolute;
        height: 40vh;
        width: 40vh;
        top: calc((100% - 40vh)/2);
        left: calc((100% - 40vh)/2);
        background-color: transparent;
        z-index: 10020;
    `
    UpBorder = styled.div`
        position: absolute;
        height: calc((100% - 40vh)/2);
        width: 40vh;
        top: 0;
        left: calc((100% - 40vh)/2);
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10020;
    `
    BottomBorder = styled.div`
        position: absolute;
        height: calc((100% - 40vh)/2);
        width: 40vh;
        bottom: 0;
        left: calc((100% - 40vh)/2);
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10020;
    `
    LeftBorder = styled.div`
        position: absolute;
        width: calc((100% - 40vh)/2);
        height: 100%;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10020;
    `
    RightBorder = styled.div`
        position: absolute;
        width: calc((100% - 40vh)/2);
        height: 100%;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10020;
    `
    Img = styled.img`
        width: 100%;
        touch-action: auto;
        overflow: auto;
    `
    state = {
        crop: {
            aspect: 1/1,
            unit: '%',
            width: 60,
            locked: true
        }
    }
    componentDidMount() {
    }

    componentWillUnmount() {
        HeaderCache.head = {}
    }
    onClick() {
        // html2canvas(document.getElementsByClassName('pswp__container')[0]).then(res => {
        //     let data = res.toDataURL("image/png");
        //     console.log(data)
        // })
        // if (this.state.photoswipe) {
        //     let currItem = this.state.photoswipe.currItem
        //     console.log(currItem)
        // }
        let img = document.getElementsByClassName('ReactCrop__image')[0]
        let data = this.getCroppedImg(img, this.state.crop, 'avator.png')
        // console.log(data)
        // TODO: compress
        this.setState({showLoading: true})
        post('avator', {data: data, src: this.state.src}).then(res => {
            console.log(res)
            this.setState({showToast: true})
            this.props.history.goBack()
        })
    }


    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
      
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height,
        );
      
        // As Base64 string
        const base64Image = canvas.toDataURL('image/png');
        return base64Image
      
        // As a blob
        // return new Promise((resolve, reject) => {
        //   canvas.toBlob(blob => {
        //     blob.name = fileName;
        //     resolve(blob);
        //   }, 'image/jpeg', 1);
        // });
    }
    render() {
        let data = HeaderCache.head || {}
        let src = data.dataUrl || 'https://icity-static.icitycdn.com/images/uploads/ap/imsm/event/pic_cover/68srmnp/e7b0f540854c7e3368srmnp.jpg/1568183953'
        this.state.src = src
        // let items = [{
        //     src: data.dataUrl || 'https://icity-static.icitycdn.com/images/uploads/ap/imsm/event/pic_cover/68srmnp/e7b0f540854c7e3368srmnp.jpg/1568183953',
        //     w: 1200,
        //     h: 900
        // }]
        // const options = {
        //     history: false,
        //     modal: false,
        //     closeEl: false,
        //     closeOnVerticalDrag: false
        // }
        return <Container>
            <this.Header>
                <this.Return> <Back /> </this.Return>
                <this.Done onClick={this.onClick.bind(this)}> 使用 </this.Done>
            </this.Header>
            <this.Body ref='body'>
                <Toast icon="success-no-circle" show={this.state.showToast}>修改成功</Toast>
                <Toast icon="loading" show={this.state.showLoading}></Toast>
                <ReactCrop src={src} circularCrop={true}
                            crossorigin={true}
                            crop={this.state.crop} 
                            onChange={newCrop => this.setState({crop: newCrop})} />
                {/* <this.Img src={data.dataUrl} /> */}
                {/* <PhotoSwipe ref='pswp' afterChange={this.onChange.bind(this)} isOpen={true} items={items} options={options} />

                <this.UpBorder />
                <this.BottomBorder />
                <this.LeftBorder />
                <this.RightBorder /> */}
            </this.Body>
            </Container>
    }
})

const StyledForm = styled(Form)`
    width: 100%;
    height: 100%;
    padding-left: 60px;
    padding-right: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 300px; */
`

const Cell = styled(Cell_)`
    width: 90%;
    display: flex;
    justify-content: space-between;
    /* font-size: 20px; */
`

const Input = styled.input`
    min-width: ${props => props.width || '80%'};
    border: 0;
    margin-left: 5px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    /* margin-left: 10px; */
`

const Signature = styled.p `
    font-size: 0.7em;
    /* width: 100%; */
    /* margin-top: 20px;
    margin-left: 20px; */
`

const Header = styled.div `
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    
    margin-left: 20px;
    /* background-color: white; */
`
const Desc = styled.div `
    /* width: ; */
    height: 100%;
    margin-left: 20px;
    border-left: 1px bold black;
    display: flex;
    flex-direction: column;
`

const FollowButton = styled.div`
    position: absolute;
    right: 0;
    padding-right: 20px;
    font-size: 1rem;
`
const Main = styled(Body)`
    position: relative;
    height: 100vh;
`
const Name = styled.p `
    font-size: larger;
    font-weight: bold;
`
const AvatorIcon = (props) => <Avator round={true} size='40px' {...props}/>

const Button = styled(Button_)`
    width: ${props => props.width};
    min-width: 4rem;
    font-size: ${props => props.fontSize || 'small'};
    border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: ${props => props.background || 'green'};
    /* color: black; */
    align-items: center;
    text-align: center;
`

const Follow = props => <Button {...props}>关注</Button>
const Followed = props => <Button background='lightgrey' {...props}>已关注</Button>
const SignOut = props => <Button {...props}>退出登录</Button>
const CancelBlock = props => <Button {...props}>取消拉黑</Button>

const BookmarkHeader = props => {
    return <TagSelect {...props} 
                      tags={['全部', '帖子', '文物', '展览']} 
                      defaultActived={props.defaultActived || 0}
                      paramKey='type'
                      keys={['all', 'post', 'antique', 'exhibit']}/>
}


const FollowHeader = props => {
    return <TagSelect {...props} 
                      tags={['条目', '关注的人', '关注话题']} 
                      defaultActived={props.defaultActived || 0}
                      paramKey='type'
                      keys={['entity', 'user', 'tag']}/>
}
const UserHome = withRouter(class extends React.Component {
    tabs = [
        ['帖子', props  => <PostList {...props} />],
        // [
        //     '收藏', props => <Feed header={BookmarkHeader} dataType='bookmark' component={GeneralCard} {...props}/>
        // ],
        [
            '收藏夹', props => <FavList {...props} />
        ],
        [
            '喜欢', props => <Feed dataType='like' component={GeneralCard} {...props}/>
        ],
        [
            '关注', props => <Feed header={FollowHeader} dataType='follow' component={GeneralCard} {...props}/>
        ],
    ]

    state = {}
    constructor(props) {
        super(props)
        this.state.userInfo = this.props.userInfo || this.props.location.user
        // this.state.isMe = true
        let id = this.props.location.pathname.split('/')[2]
        if (this.state.userInfo) {
            this.state.isMe = this.state.userInfo._id === getUserInfo()._id
        }
        console.log(this.state, this.props, id)
        if (!this.state.userInfo) {
            get(this.state.userInfo && `/user/${this.state.userInfo._id}` || this.props.location.pathname).then(res => {
                let isMe = res.data._id === (getUserInfo() && getUserInfo()._id)
                console.log(res, isMe)
                this.setState({userInfo: res.data, isMe: isMe})
            })
        }
        get('/block').then(res => {
            this.setState({blockedUsers: res.data})
            console.log(res.data)
            res.data.map((v, i) => {
                if (v.blocked_user_id === id) {
                    this.setState({isBlocked: true})
                }
            })
        })
    }
    signout() {
        removeUserInfo()
        clearCache()
        this.props.history.push('/me')
    }
    cancelBlock() {
        post('/block', {
            user_id: this.state.userInfo._id,
            delete: true
        }).then(res => {
            setRefresh('home')
            this.setState({isBlocked: false})
        })
    }
    async changeHead() {
        if (!this.state.isMe) {
            return
        }
        let options = {
            quality: 80,
            // allowEditing: true,
            resultType: CameraResultType.DataUrl,
            source: CameraSource.Prompt
          }
        let res = await Camera.getPhoto(options)
        console.log(res)
        // setCache('head', res)
        HeaderCache.head = res
        this.props.history.push('/headset')
    }
    Cover = styled.div`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 20%;
        background-image: url(${props => props.background});
        opacity: 0.4;
    `
    HeaderAvator = styled.div`
        display: flex;
        flex-direction: column;
        font-size: 10px;
        color: green;
    `
    renderHeaderPic() {
        let items = [{
            src: this.state.userInfo.avator,
            w: 300,
            h: 300
        }]

        const options = {
            index: 0,
            closeOnVerticalDrag: false,
            tapToClose: true
        }
        console.log(items)
        return this.state.showHeaderPic && this.state.userInfo.avator && 
                <PhotoSwipe isOpen={true} items={items} 
                            onClose={e => this.setState({showHeaderPic: false})}
                            options={options} />
    }
    render() {
        const userInfo = this.state.userInfo
        let refresh = getRefresh('user') || this.props.location.refresh;
        let id = this.props.location.pathname.split('/')[2]
        if (id && userInfo && id !== userInfo._id) {
            get(this.props.location.pathname).then(res => {
                let isMe = res.data._id === (getUserInfo() && getUserInfo()._id)
                console.log(res, isMe)
                this.setState({userInfo: res.data, isMe: isMe, refresh: true})
            })
            return <Container />
        }
        return this.state.userInfo && <Container>
            {/* <this.Cover background={userInfo.avator} /> */}
            {this.props.location.pathname.startsWith('/user') && 
            <Bar back={e => back(this, '/talk')} 
                noBaseLine='true'
                share={e => shareNav(`@${userInfo.nickName} 的主页`, `@${userInfo.nickName} 的主页`, this.props.location.pathname)}
            />
            // <StyledToolbar>
            //     <div className='left'>
            //         <ToolbarButton
            //             onClick={e => back(this, '/talk')}>
            //             返回
            //         </ToolbarButton>
            //     </div>
            //     <div className='center'>{userInfo.nickName}</div>
            //     <div className='right'>
            //         <ToolbarButton
            //             onClick={e => shareNav(`@${userInfo.nickName} 的主页`, `@${userInfo.nickName} 的主页`, this.props.location.pathname)}>
            //             分享
            //         </ToolbarButton>
            //     </div>
            // </StyledToolbar>
        }
            <Body width='90%'>
            <Header>
                <this.HeaderAvator>
                    {this.renderHeaderPic()}
                    <AvatorIcon src={userInfo.avator} name={userInfo.nickName} onClick={e => this.setState({showHeaderPic: true})}/>
                    {this.state.isMe && <div onClick={this.changeHead.bind(this)}> 修改头像 </div> }
                </this.HeaderAvator>
                <Desc>
                    <Name>
                        {userInfo.nickName}
                    </Name>
                    <p> {userInfo.time_desc}加入 </p>
                </Desc>
                <FollowButton>
                    {this.state.isBlocked && <CancelBlock onClick={this.cancelBlock.bind(this)}/>}
                    {!this.state.isMe && getUserInfo() && !this.state.isBlocked && <Watch type='user' object={userInfo._id} watched={userInfo.followed} Active={Follow} Actived={Followed} />}
                    {this.state.isMe && <SignOut onClick={this.signout.bind(this)} touched={this.signout.bind(this)}/>}
                </FollowButton>
                {/* {this.state.isMe && this.state.blockedUsers && this.state.blockedUsers.length > 0 && <p>
                    <strong>您拉黑了：</strong> 
                    </p>} */}
            </Header>
            {/* <Signature>
                        这个人很懒，还没设置签名档ddddddddddddddddddddddddddd
            </Signature> */}
            <Main>
                {/* <Scroller> */}
                <Tabbed
                    user={userInfo._id}
                    tabs={this.tabs}
                    refresh={this.state.refresh}
                    params={{
                    'user_id': userInfo._id,
                    'nickName': userInfo.nickName
                }}/> {/* </Scroller> */}
                <FixedPostIcon remindOnly={true} />
            </Main>
            </Body>
        </Container> || <div/>
    }
})

const SendSms = props => <Button {...props}>获取验证码</Button>
const SendedSms = props => <Button background='lightgrey' {...props}>{props.seconds}s 后可重新获取</Button>

const UserCreate = withRouter(class extends React.Component {
    state = {
        registerType: 'tel',
        lastSendSms: Date.now() - 61*1000
    }
    sync() {
        setRefresh('home')
        setRefresh('reminder')
        setRefresh('talk')
        get('/userinfo').then(res => {
            setUserInfo(res.data)
        })
        post('sync', {actions: getCache('_actions')}).then(res => {
            console.log('Sync', res)
            setCache('_actions', [])
            let setter = name => {
                res.data[name].map((v, i) => {
                    let key = `${v.type}-${v.object}`
                    setHash(name, key, true)
                })
            }
            setter('bookmark')
            setter('like')
            setter('follow')
        })
    }
    login() {
        console.log(this.state)
        this.state.created = Date.now();
        post('login', this.state).then(res => {
            console.log(res)
            // TODO: 500 error handling
            setUserInfo(res.data)
            this.setState({userInfo: getUserInfo()});
            this.sync()
        })
    }

    componentDidMount() {
        this.sync()
    }
    render() {
        let userInfo = getUserInfo();
        this.state.userInfo = userInfo;
        if (!userInfo) {
            return this.renderCreate()
        } else if (userInfo && !userInfo.nickName) {
            return this.renderInfoSetting()
        } else {
            if (this.props.location.pathname === '/me') {
                // this.sync()
                return this.renderHome()
            } else {
                return <Redirect to='/me' />
            }

        }
    }

    renderHome() {
        let refresh = getRefresh('home') || this.props.location.refresh;
        console.log('RefreshUser', refresh)
        return (<UserHome userInfo={getUserInfo()} refresh={refresh}/>)
    }

    setInfo() {
        console.log('setInfo', this.State)
        post('userinfo', this.state).then(res => {
            // TODO: 500
            console.log(res);
            // if (res.status )
            setUserInfo(res.data);
            this.setState({});
        })
    }
    renderInfoSetting() {
        return (
            <Container>
                {/* <StyledToolbar>
                    <div className='left'>
                        <ToolbarButton
                            onClick={e => this
                            .props
                            .history
                            .goBack()}>
                            取消
                        </ToolbarButton>
                    </div>
                    <div className='center'>
                        设置昵称
                    </div>
                    <div className='right'>
                        <ToolbarButton onClick={e => this.setInfo()}>
                            下一步
                        </ToolbarButton>
                    </div>
                </StyledToolbar> */}
                <Bar back={e => back(this, '/')} noBaseLine/>
                <Body>
                    <this.Center> 设置昵称 </this.Center>
                    {/* <StyledForm diplay='none' /> */}
                    <StyledForm>
                        <Cell>
                            <Input
                                type='text'
                                onChange={e => this.setState({nickName: e.target.value})}
                                placeholder='设置一个昵称'
                                defaultValue=''
                                autoFocus={true}/>
                        </Cell>
                        <br />
                        <Button width='90%' fontSize='20px' onClick={this.setInfo.bind(this)} > 好了 </Button>
                    </StyledForm>
                    
                </Body>
            </Container>
        )
    }
    smsTimeout = 60*1000

    getSmsCode() {
        post('sms', this.state).then(res => {
            this.setState({
                lastSendSms: Date.now()
            })
        })
        let updateIt = () => {
            this.setState({})
            if (Date.now() - this.state.lastSendSms < this.smsTimeout) {
                setTimeout(updateIt, 1000)
            }
        }
        setTimeout(updateIt, 1000)
    }
    UpCell = styled(Cell)`
        border-top: 0;
    `
    ToolBar = styled(StyledToolbar)`
        border-bottom: 0;
    `
    Center = styled.div`
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        width: 100%;
    `

    renderCreate() {
        console.log(Date.now(), this.state)
        return (
            <Container>
                <Bar back={e => back(this, '/')} noBaseLine/>
                {/* <this.ToolBar>
                    <div className='left'>
                        <ToolbarButton
                            onClick={e => this
                            .props
                            .history
                            .goBack()}>
                            ×
                        </ToolbarButton>
                    </div>
                    <div className='center'>
                        手机注册或登录
                    </div>
                    <div className='right'>
                        <ToolbarButton onClick={e => this.login()}>
                            下一步
                        </ToolbarButton>
                    </div>
                </this.ToolBar> */}
                <Body>
                    <this.Center>
                    手机注册或登录
                    </this.Center>
                    {this.state.registerType === 'email' && <StyledForm>
                        <Cell>
                            <Input
                                type='email'
                                onChange={e => this.setState({email: e.target.value})}
                                placeholder='电子邮箱'
                                autoFocus={true}/>
                            
                        </Cell>
                        <Cell>
                            <Input
                                type='password'
                                onChange={e => this.setState({password: e.target.value})}
                                placeholder='密码'/>
                        </Cell>
                    </StyledForm>}
                    { this.state.registerType === 'tel' && <StyledForm>
                    <Cell>
                        {/* <div> +86  </div> */}
                        <Input
                            type='tel'
                            ref='phoneText'
                            onChange={e => this.setState({phone: e.target.value})}
                            placeholder='手机号'
                            autoFocus={true}/>
                        {this.state.phone && <Icon icon={x} onClick={e => this.refs.phoneText.value=''}/>}
                        </Cell>
                        <Cell>
                            <Input
                                width='50%'
                                type='tel'
                                maxlength='6'
                                onChange={e => this.setState({code: e.target.value})}
                                placeholder='验证码'/>
                            {
                                Date.now() - this.state.lastSendSms > this.smsTimeout 
                                && <SendSms onClick={e => this.getSmsCode()} fontSize='medium' /> 
                                || <SendedSms seconds={(parseInt((this.state.lastSendSms - Date.now())/1000) + 60)} fontSize='medium' />
                            }
                        </Cell>
                        <br />
                        {/* <Cell> */}
                            <Button 
                                   width='90%' fontSize='20px' onClick={this.login.bind(this)} > 登录 </Button>
                            <br />

                            <Cell>
                            <p>
                            <strong>注册前请阅读<Link to='/posts/5d64fcd739c3635e7ace2896' >用户协议</Link>，注册/登录视为同意用户协议的全部内容。</strong>
                            </p>
                            </Cell>
                        {/* </Cell> */}
                    </StyledForm>}
                </Body>
            </Container>
        )
    }
})

export {UserCreate, UserHome, HeadSetting};
