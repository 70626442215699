import React, {userRef, Fragment} from 'react';
import Avator from 'react-avatar';
import {
    Feed,
    Container,
    StyledToolbar,
    Body,
    Fixed,
    AvatorIcon,
    Scroller,
    End,
    Tabbed,
    SearchBox
} from './Common.js';
import {withRouter, Link} from 'react-router-dom';
import styled from 'styled-components';
import {getRefresh, setRefresh, setCache, AppState} from './State.js';
import {Icon as Icon_} from 'react-icons-kit';
import {bookmark} from 'react-icons-kit/feather/bookmark';
import {heart} from 'react-icons-kit/feather/heart';
import {share} from 'react-icons-kit/feather/share';
import {edit} from 'react-icons-kit/feather/edit'
import {repeat} from 'react-icons-kit/feather/repeat'
import {messageCircle} from 'react-icons-kit/feather/messageCircle';
import {tag} from 'react-icons-kit/feather/tag';
import {x} from 'react-icons-kit/feather/x';
import {settings} from 'react-icons-kit/feather/settings';
import {thumbsUp} from 'react-icons-kit/feather/thumbsUp'
import {
    getUserInfo,
    get,
    post,
    delete_,
    shareNav,
    back
} from './Backend';
import {ToolbarButton, Button} from 'react-onsenui';
import {Gallery as _Gallery, ActionSheet, Toast} from 'react-weui';
import {EasyEditor} from './Editor';
import {Bookmark, ActionStyle, Like} from './Actions';
import {Entry as _Entry} from './Card';
import { TagSelect, AddressBar, FixedPostIcon, RightMost, GeneralCard } from './Detail';
// import { withBaseIcon } from 'react-icons-kit'
import { Markdown, MarkdownPreview } from './Wiki';
// import { PhotoCard } from './Album.js'
import { Portal } from 'react-portal';
import PhotoGallery from "react-photo-gallery";
// import { Player as VideoPlayer } from 'video-react';
// import Lightbox from 'react-image-lightbox';
// import 'react-image-lightbox/style.css'; 
// import
// import { ImageViewer } from 'react-mobile-image-viewer';
// import 'react-mobile-image-viewer/lib/index.css';

import {PhotoSwipe as _PhotoSwipe} from 'react-photoswipe';
import { Plugins } from '@capacitor/core';
import {Player, syn} from './Speech';
import { Bar } from './Basics';
const { App, BackgroundTask, Modals } = Plugins;



const PhotoSwipe = styled(_PhotoSwipe)`
    /* margin-top: 30px; */
`
const Gallery = styled(_Gallery)`
`

const Entry = styled(_Entry)`
    margin-left: 0;
    padding-left: 0;
`

const Card = styled.div `
    width: 96%;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-left: auto;
    margin-right: auto;
    border-radius: ${props => props.card && '10px' || 0};
    border: ${props => props.card && '1px solid lightgrey' || ''};
    /* padding-left: 20px; */
    /* -webkit-overflow-scrolling: auto !important; */
    /* padding-right: 20px; */
`
const Left = styled.div `
    width: 50px;
    margin-left: 10px
    margin-right: 10px;
`
const Right = styled.div `
    width: 100%;
    display: flex;
    flex-direction: column;
    /* margin-right: 10px; */
`
const Header = styled.div `
    width: 100%;
    /* font-weight: bold; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* font-size: 15px; */
    margin-bottom: 10px;
    margin-top: 5px;
    font-size: 14px;
    /* height: 30px; */
    /* border-bottom: 1px solid lightgrey; */

`
const TextContent = styled.div `
    width: calc(100% - 10px);
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    color: black;
    img {
        width: 100%;
    }
`
const ImageWrap = styled.div `
    width: 100%;
    display: grid;
    grid-template-columns: ${props => props.count === 1 && 'repeat(auto-fill, 100%)' || 'repeat(auto-fill, 50%)'};
`

const VideoWrap = styled.div `
    width: 100%;
    max-height: 200px;
    background-color: rgba(0, 0, 0, 0.5);
`
const _Img = styled.img`
    width: calc(100% - 10px);
    margin-left: 5px;
    margin-right: 5px;
    height: ${props => props.wide && '300px' || '150px'};
    /* width: auto;
    height: auto; */
    object-fit: cover;
    margin-bottom: 15px;
    border-radius: 5px;
`

const ImgDiv = styled.div `
    width: 100px;
    height: 205px;
    margin-right: 10px;
`

const Img = props => <_Img {...props} src={`${props.src}?x-oss-process=style/page`} />

const Actions = styled.div `
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    /* margin-top: 10px; */
`
const Tags = styled.div`
`

const Tag = styled.span `
    margin-left: 10px;
    border-radius: 2px;
    padding: 2px 2px 1px 1px;
    /* background-color: lightblue; */
    color: grey;
    /* font-family: "Hiragino Sans GB"; */
    font-weight: bold;
    font-size: 0.9rem;
    display: inline-block;
`
const Desc = styled.span `
    font-size: 0.7rem;
    color: grey;
`
const TextPre = styled.pre`
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 16px;
`
const Entities = styled.div `
    width: 100%;
    /* box-shadow: 10px 5px 5px black; */
    /* box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); */
    /* border-top: 1px solid lightgrey; */
    /* padding-right: 30px; */
    /* background-color: whitesmoke; */
`

const AvatorPeaple = (props) => <Link to={{
    pathname: `/user/${props.user._id}`,
    user: props.user
}}>
    <Avator round={true} size='35px' textSizeRatio={1.75} className='' src={`${props.user.avator}?x-oss-process=style/head`} name={props.user.nickName} {...props}/>
</Link>


const PostMarkdown = styled(Markdown)`
    padding-bottom: 0px;
    width: 100%;
    h1 {
        font-size: 1.2rem;
    }
    h2, h3, h4, h5 {
        font-size: 1.1rem;
    }
    word-break: break-word;
`

const PostDigest = styled(PostMarkdown)`
    /* max-height: 100px; */
    padding-bottom: 0px;
    width: 100%;
    word-break: break-word;
    h1, h2, h3, h4, h5 {
        margin-bottom: 10px;
    }
    /* display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    word-break: break-word; */
`

const Icon = styled(Icon_)`
`

const PostIcon = props => <Icon {...props} tag='span' size='15px' />
let bookmarked = JSON.parse(JSON.stringify(bookmark));
bookmarked.attribs.fill = 'black'

// const ActionWithCount = props =>
let r = document.getElementById('root')



const PostCard = withRouter(class extends React.Component {
    state = {
        showImages: false,
        showMenu: false,
        entities: [],
        reportToast: false,
        upToast: false,
    }
    speechMenus = [{
        label: '朗读帖子',
        onClick: ()=> {this.speech(this.props.data.text);this.setState({showMenu: false})}
    }
    ]
    speech(text) {
        Player.playList({
            items: syn(text)
        })
    }
    menus = [{
        label: '修改',
        onClick: ()=> {this.modify();this.setState({showMenu: false})}
    }, 
    {
        label: '这帖子要顶',
        onClick: () => {this.up();this.setState({showMenu: false})}
    },
    {
        label: '删除',
        onClick: ()=> {this.deletePost();this.setState({showMenu: false})}
    }, ...this.speechMenus]

    otherMenus = [
        {
            label: '这帖子要顶',
            onClick: () => {this.up();this.setState({showMenu: false})}
        },
        {
            label: '举报',
            onClick: () => {this.report();this.setState({showMenu: false})}
        },
        {
            label: '不看该用户的内容',
            onClick: () => {this.block();this.setState({showMenu: false})}
        },
        // {
        //     label: '为我朗读',
        //     onClick: () => {
        //         // var utterThis = new window.SpeechSynthesisUtterance(this.props.data.text);
        //         // window.speechSynthesis.speak(utterThis);
        //         // this.setState({showMenu: false})
        //         PlayText(this.props.data.text).then(res => {

        //         })
        //     }
        // }
        ...this.speechMenus
    ]

    actions = [
        {
            label: '取消',
            onClick: e => this.actionCancel()
        }
    ]
    constructor(props) {
        super(props)
        this.state.data = AppState.update('post', this.props.data)
        this.linkify()

    }
    actionCancel() {
        this.setState({showMenu: false})
    }
    topicToLink(entity) {
        // let reg = /#\((.*):(.*)\)(.*)/;
        // let m = reg.exec(topic);
        // console.log(m);
        return <Link to={`/${entity.type}/${entity.id}?section=讨论`}>
            <Tag>
                {`${entity.title}`}
            </Tag>
        </Link>

    }
    toastTimeout = 2000

    up() {
        let data = this.props.data
        this.setState({upToast: true})
        setTimeout(() => this.setState({upToast: false}), this.toastTimeout)
        post('/up', {post_id: data._id})
    }

    block() {
        let data = this.props.data
        this.setState({blockToast: true})
        setTimeout(() => this.setState({blockToast: false}), this.toastTimeout)
        post('/block', {post_id: data._id, user_id: data.user._id})
        setRefresh('home')
        this.props.history.push({pathname: '/redirect', to: this.props.location.pathname})
    }

    report() {
        let data = this.props.data
        post('/report', {post_id: data._id})
        this.setState({reportToast: true})
        setTimeout(() => this.setState({reportToast: false}), this.toastTimeout)
    }
    deletePost() {
        console.log('Delete Post')
        let url
        if (this.props.type === 'comment') {
            url = `comment/${this.props.data._id}`
        } else {
            url = `post/${this.props.data._id}`
        }
        delete_(url).then(res => {
            this.props.onDelete && this
                .props
                .onDelete()
        })
        setRefresh('home')
        setRefresh('talk')
        if (this.props.location.pathname.startsWith('/posts') && this.props.type !== 'comment') {
            this.props.history.goBack()
        }
    }
    modify() {
        this.props.history.push({
            pathname: '/post',
            data: this.props.data
        })
    }
    renderEntity() {
        let data = this.state.data
        let pathname = this.props.location.pathname
        if (!pathname.startsWith('/talk') && !pathname.startsWith('/posts') && !pathname.startsWith('/me')) {
            return <span/>
        }
        let noLink = this.props.noLink
        let entities = data.entities || []

        // 有图片的帖子在列表页不显示entity
        let listEntityCount = 1
        if (data.images && data.images[0]) {
            listEntityCount = 0
        }

        let cards = noLink && entities || entities.slice(0, listEntityCount)
        let tags = [...(!noLink && entities.slice(listEntityCount, entities.length) || []), ...(data.tags || [])]
        return (
            <Fragment>
                {
                    data.retweet && <PostCard noActions noLink={false} card={true} data={data.retweet} />
                }
                {cards.length > 0 && <Entities>
                    {/* <strong>相关条目:</strong> */}
                    {
                        cards.map((v, i) => <GeneralCard data={v} inPost={true} key={i} section='讨论' />)
                        // <Entry data={v.data} key={i} section='讨论'/>)
                    }
                </Entities>}
                {tags.length > 0 && <Tags>
                    <PostIcon icon={tag}/>
                    {
                        tags.map((t, i) => {
                            if (t && t.type) {
                                if (t.type !== 'link') {
                                    return this.topicToLink(t)
                                }
                            } else if (t) {
                                return <Link to={`/tag/${t.slice(1, t.length)}`}>
                                <Tag>{t}</Tag>
                                </Link>
                            }
                        })
                    }
                </Tags>}
            </Fragment>)

        // if (data.entities && data.entities[0]) {
        //     if ((data.images && data.images[0])) {
        //         return <Tags>
        //             <PostIcon icon={tag}/> {this.topicToLink(data.entities[0])}
        //         </Tags>
        //     } else {
   
        //     }
        // }

    }
    renderActions() {
        let data = this.state.data
        if (this.props.noActions) {
            return
        }
        if (this.props.type !== 'comment') {
            return <Actions>

                {/* <PostIcon icon={heart}/> */}
                <ActionStyle>
                    <Like type='post' liked={data.liked} object={data._id}/>
                    <div>
                        {data.likeCount || ''}
                    </div>
                </ActionStyle>
                <Link
                    to={{
                    pathname: `/posts/${data._id}`,
                    data: data,
                    commentFocus: true
                }}>
                    <ActionStyle>
                        <PostIcon icon={messageCircle}/>
                        <div>
                            {data.comments && data.comments.length > 0 && data.comments.length || ''}
                        </div>
                    </ActionStyle>
                </Link>
                <Link
                    to={{
                    pathname: `/post`,
                    retweet: data,
                    // commentFocus: true
                }}>
                    <ActionStyle>
                        <PostIcon icon={repeat}/>
                        {/* <div>
                            {data.repeat.length > 0 && data.comments.length || ''}
                        </div> */}
                    </ActionStyle>
                </Link>
                <ActionStyle>
                    <Bookmark bookmarked={data.bookmarked} type='post' object={data._id}/>
                    <div>
                        {data.bookmarkCount || ''}
                    </div>
                </ActionStyle>

                {/* <div>
                <PostIcon icon={(this.props.data.bookmarked && bookmarked) || bookmark}/>
            </div> */}
                {/* <PostIcon
                    icon={share}
                    onClick={this
                    .share
                    .bind(this)}
                    touched={this
                    .share
                    .bind(this)}/> */}
            </Actions>
        } 
        else {
            return <Actions>
                <ActionStyle>
                    <Like type='comment' liked={data.liked} object={data._id}/>
                    <div>
                        {data.likeCount || ''}
                    </div>
                </ActionStyle>
                <ActionStyle onClick={e => this.props.doComment && this.props.doComment(data)} >
                    <PostIcon icon={messageCircle}/>
                </ActionStyle>
                <Link
                    to={{
                    pathname: `/post`,
                    retweet: {...data, type: 'comment'},
                    // commentFocus: true
                }}>
                    <ActionStyle>
                        <PostIcon icon={repeat}/>
                        {/* <div>
                            {data.repeat.length > 0 && data.comments.length || ''}
                        </div> */}
                    </ActionStyle>
                </Link>
                <ActionStyle>
                    <Bookmark bookmarked={data.bookmarked} type='comment' object={data._id}/>
                    <div>
                        {data.bookmarkCount || ''}
                    </div>
                </ActionStyle>

            </Actions>
        }
    }
    share() {
        let url = `posts/${this.props.data._id}`
        let data = this.props.data
        shareNav(`@${data.user.nickName}发表了新帖子`, `@${data.user.nickName}: ${data.text}`, url)
    }

    linkify() {
        let data = this.state.data
        // console.log(data)
        if (data.summary) {
            return
        }
        data.tags = data.tags || []
        data.content = data.text || ''
        let limit = 140
        if (this.props.noLink) {
            data.tags.map((t, i) => {
                data.content = data.content.replace(t, `[${t}](/#/tag/${t.slice(1, t.length)})`)
            })
        }
        (data.entities || []).map((o, i) => {
            if (o.type === 'link') {
                data.content = data.content.replace(o.data.src, `[${o.data.title}](${o.data.src})`)
            }
        })
        if (data.content && data.content.includes('\n')) {
            let lines = data.content.split('\n').filter(v => !!v)
            let head = lines[0]
            let digest = lines[1] || ''
            data.summary = head + '\n' + (head.startsWith('# ') && !digest.startsWith('![') && digest || '') + '  ...'
        } else {
            data.summary = data.content.substring(0, limit) + (data.content.length > limit && ' ...' || '')
        }
    }

    isMyPost() {
        let data = this.state.data || {};
        return getUserInfo() && getUserInfo()._id === data.user._id
    }

    renderToast() {
        return <Fragment>
            { this.state.reportToast && <Toast icon="success-no-circle" show={this.state.reportToast}>举报成功</Toast>}
            { this.state.upToast && <Toast icon="success-no-circle" show={this.state.upToast}>顶帖成功，该帖会酌情被顶上去</Toast>}
            { this.state.blockToast && <Toast icon="success-no-circle" show={this.state.blockToast}>您不会再看见该用户的帖子</Toast>}
            </Fragment>
    }

    closeGalley() {
        // PhotoSwipe force scroll issue in Safari
        // let tabbed = document.getElementsByClassName('tabbedContainer')[0]
        // tabbed.scrollTop = 0
        this.setState({showImages: false})
        // this.disableOverlayable()
    }
    showGallery(i) {
        // console.log(document.getElementsByClassName('iOS-root'), document.getElementsByClassName('root')[0].style.paddingTop)
        this.setState({showImages: true, index: i})
        // document.getElementsByClassName('pswp')[0].style.top = '35px';
        // this.makeOverlayable()
    }
    makeOverlayable() {
        let ele = document.getElementById('infList')
        if (!ele) {
            ele = document.getElementsByClassName('container')[0]
        }
        ele && (ele.style['-webkit-overflow-scrolling'] = 'auto')
        this.state.scrollingAuto = true
    }
    disableOverlayable() {
        let ele = document.getElementById('infList')
        if (!ele) {
            ele = document.getElementsByClassName('container')[0]
        }
        ele && (ele.style['-webkit-overflow-scrolling'] = 'touch')
    }

    openMenu() {
        this.setState({showMenu: true})
        // this.makeOverlayable()
    }
    Wrap = styled(Portal)`
    `
    renderGallery(data) {
        // let images = data.images
        // let index = this.state.index
        // return this.state.showImages && <Lightbox 
        //             mainSrc={data.images[this.state.index].src}
        //             nextSrc={data.images[(this.state.index + 1) % data.images.length].src}
        //             prevSrc={images[(index + images.length - 1) % images.length]}
        //             onCloseRequest={() => this.closeGalley()}
        //             clickOutsideToClose={true}
        //             onMovePrevRequest={() =>
        //               this.setState({
        //                 index: (index + images.length - 1) % images.length,
        //               })
        //             }
        //             onMoveNextRequest={() =>
        //               this.setState({
        //                 index: (index + 1) % images.length,
        //               })
        //             }
        //         /> || <Fragment />
        let urls = data.images.map((v, i) => {
            let info = v.info || {}
            let exif = info.exif || {}

            // for iPhone picture
            let doTrans = exif.Orientation === 6

            let width = info.width || 1920
            let height = info.height || 1920
            return {
                    src: `${v.src}`, 
                    w:  doTrans && height || width,
                    h:  doTrans && width || height,
                    msrc: `${v.src}?x-oss-process=style/page`
                }
        })
        let options = {
            index: this.state.index,
            escKey: true,
            // ui option
            // timeToIdle: 400000,
            tapToClose: true,
            modal: false,
            history: false,
            // clickToCloseNonZoomable: true,
            closeOnVerticalDrag: true,
            isClickableElement: e => false,
        }



        return this.state.showImages &&
                <this.Wrap>
                    <this.Screen>
                    <PhotoSwipe isOpen={true}
                                items={urls}
                                modal={false}
                                options={options}
                                onClose={e => this.closeGalley()} />
                    </this.Screen>
                </this.Wrap>
    }
    top = document.getElementsByClassName('iOS-root').length > 0 && document.getElementsByClassName('iOS-root')[0].style.paddingTop  || 0
    Screen = styled.div`
        width: 100%;
        height: calc(100%);
        top: ${this.top};
        background-color: transparent;
        position: fixed;
        z-index: 3000;
    `

    decoratedContent(content) {
        if (this.props.type !== 'comment') {
            return content
        }
        let data = this.state.data || {};
        let head = this.props.type==='comment' && data.reply_to_user && `回复 [@${data.reply_to_user.nickName}](/#/user/${data.reply_to_user._id}):`
        head = head || '回复主贴:'
        return `**${head}**  ${content}`

    }
    Head = styled.div`
        display: flex;
        align-items: center;
    `
    HeadDesc = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 10px;
        /* padding-top: 5px; */
        /* padding-bottom: 5px; */
    `
    render() {
        this.state.data = AppState.update('post', this.props.data)
        let data = this.state.data || {};
        data.images = data.images || []
        if (!data.summary) {
            this.linkify()
        }
        !this.state.showImages && !this.state.showMenu && this.state.scrollingAuto && this.disableOverlayable() &&
        (this.state.scrollingAuto = false)
        return (
            <Card card={this.props.card}>
                { this.renderGallery(data) }
                {/* <Gallery
                    src={data
                    .images
                    .map((v, i) => `${v.src}`)}
                    show={this.state.showImages}
                    defaultIndex={this.state.index}
                    onClick={e => this.closeGalley()}
                    touched={e => this.setState({showImages: false})}/> */}
                <Portal>
                <ActionSheet
                    style={{'maxWidth': '650px', 'left': r.offsetLeft, 'bottom': '0px', 'position': 'fixed', 'zIndex': 100001}}
                    menus={this.isMyPost() && this.menus || this.otherMenus}
                    actions={this.actions}
                    show={this.state.showMenu}
                    type="ios"
                    onRequestClose={e=>{this.setState({showMenu: false}); this.disableOverlayable();}}
                />
                </Portal>
                {
                    this.renderToast()
                }
                {/* <Left>
                    <AvatorPeaple user={this.props.data.user}/>
                </Left> */}
                <Right>
                    <Header>
                        <this.Head>
                            <AvatorPeaple user={this.props.data.user}/>
                            <this.HeadDesc>
                                <strong>
                                    {`@${data.user.nickName}`}
                                </strong>

                                <Desc>
                                    {data.time_desc}
                                </Desc>
                            </this.HeadDesc>

                        </this.Head>

                        {getUserInfo() && <span onClick={this.openMenu.bind(this)}>⋯</span> || <span></span>}
                        {/* // <PostIcon
                        //     onClick={this
                        //     .deletePost
                        //     .bind(this)}
                        //     icon={x}/> || <span/>} */}
                    </Header>
                    {/* {!this.props.noLink && <Link
                        to={{
                        pathname: `/posts/${data._id}`,
                        data: data
                    }}> */}

                    {/* <Desc>
                            {}
                    </Desc> */}
                    {!this.props.noLink &&
                    <Link
                        to={{
                        pathname: `/posts/${data.post_id || data._id}`,
                        data: data
                    }}> 
                            <TextContent>
                            <PostDigest>{data.summary}</PostDigest>
                            </TextContent>
                    </Link>}
                    {this.props.noLink && <TextContent><PostMarkdown>{this.decoratedContent(data.content)}</PostMarkdown></TextContent>}
                    {/* {this.props.noLink && this.props.type !== 'comment' && <Speech text={data.text || ''}/>} */}

                    {data.images && data.images[0] && <ImageWrap count={data.images.length}>
                        {
                            data
                            .images.slice(0, this.props.noLink && data.images.length || 4)
                            .map((v, i) => <Img
                                key={i}
                                wide={data.images.length === 1}
                                src={v.src || v.url}
                                onClick={e => this.showGallery(i)}
                                />)
                        }
                    </ImageWrap>}
                    {
                        data.videos && data.videos[0] && !this.props.onlyText &&
                        <VideoWrap>
                            {/* // <VideoPlayer 
                            //     playsInline
                            //     height={'300px'}
                            //     width={'100%'}
                            //     fluid={true}
                            //     src={`${data.videos[0].src}`}
                            // /> */}
                         <video 
                            webkit-playsInline="true" /*这个属性是ios 10中设置可以
                                                        让视频在小窗内播放，也就是不是全屏播放*/  
                            playsInline="true"  /*IOS微信浏览器支持小窗内播放*/ 
                            x-webkit-airplay="allow" 
                            width='100%' 
                            height='200px' 
                            controls>
                        <source src={`${data.videos[0].src}#t=0.01`} type={data.videos[0].type} />
                        <source src={`${data.videos[0].src}#t=0.01`}  />
                        </video> 
                        </VideoWrap> 
                    }

                    {this.renderEntity()}
                    {/* {getUserInfo() && getUserInfo()._id === data.user._id && <span><PostIcon icon={edit} onClick={this.modify.bind(this)} /> </span>} */}
                    {this.renderActions()}
                   
                </Right>
            </Card>
        )
    }
})

const CommentBox = styled.div `
    width: 100%;
    /* min-height: 60px; */
    max-height: 50vh;
    max-width: 650px;
    position: fixed;
    bottom: 0;
    /* padding-bottom: 30px; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid lightgrey;
    background-color: white;
    /* resize: none; */
`

const CommentHeader = styled(Header)`
    width: 90%;
    /* height: 300px; */
    margin-left: auto;
    margin-right: auto;
`

const CommentEditor = withRouter(class extends React.Component {
    state = {}
    publish(data, clear_) {
        console.log(this.state);
        post('comment', {
            post_id: this.props.post._id,
            reply_to: this.state.extraData && this.state.extraData._id,
            text: data.text,
            images: data.images,
            created: Date.now()
        }).then(res => {
            console.log(res);
            // this.setState({}) this.refs.textInput.value = '';
            setRefresh('postPage')
            this.props.onPublished && this
                .props
                .onPublished()
            clear_ && clear_()
        })
    }
    componentDidMount() {
        this.props.onRef && this.props.onRef(this)
    }
    startEdit(text, extraData) {
        // this.state.extraData = extraData
        this.setState({
            extraData: extraData
        })
        // document.getElementById('easyEditor').startEdit('')
        this.easyEditor.startEdit('')

    }
    render() {
        return (
            <CommentBox>
                {this.state.extraData && <CommentHeader><Desc> {`回复 @${this.state.extraData.user.nickName}: `} </Desc> </CommentHeader>}
                <EasyEditor
                    ref='easyEditor'
                    onRef={inst => this.easyEditor = inst}
                    onClear={e => this.setState({extraData: null})}
                    autofocus={this.props.autofocus}
                    onPublish={this
                    .publish
                    .bind(this)}
                    minHeight='16px'
                    maxHeight='30vh'
                    placeholder='发表评论'/>
            </CommentBox>
        )
    }
})

const Content = styled.div `

width: 100%;
height: 80vh;
/* position: absolute; */
overflow: auto;
padding-bottom: 40vh;
padding-left: 5px;
padding-right: 5px;
`
const ToolBar = styled(StyledToolbar)`
    /* position: fixed; */
`
const _Body = styled(Body)`
    /* position: fixed; */
`
const PostPage = withRouter(class extends React.Component {
    state = {
        comments: []
    }
    constructor(props) {
        super(props)
        this.state.data = this.props.location.data
        let id = this
        .props
        .location
        .pathname
        .split('/')[2]
        // this.commentEditor = userRef()
        if (!this.props.location.data || id !== this.props.location.data._id) {
            this.refresh()
        } else {
            this.state.comments = this.state.data.comments
        }

    }
    async componentDidMount() {

    }
    refresh() {
        let id = this
        .props
        .location
        .pathname
        .split('/')[2]
        this.state.data = null
        get(`post/${id}`).then(res => {
            console.log(id, res)
            this.setState({data: res.data, comments: res.data.comments})
        })
    }
    fetchComments() {
        // setRefresh('post')
        if (this.state.data._id) {
            get(`comment?post_id=${this.state.data._id}`).then(res => {
                this.state.data.comments = res.data
                this.setState({comments: res.data})
            })
        }
    }

    share() {
        shareNav(`@${this.state.data.user.nickName}发表了帖子`, `@${this.state.data.user.nickName}: ${this.state.data.text}`, this.props.location.pathname)
    }

    async doComment(data) {
        if (!getUserInfo()) {
            let r = await Modals.confirm({
                title: '确认',
                message: '请先登录'
            })
            if (r.value) {
                this.props.history.push('/login')
            }
            return
        }
        console.log(this.state, this.commentEditor)
        this.commentEditor.startEdit(`回复 @${data.user.nickName}: `, data)
    }
    render() {
        let id = this
        .props
        .location
        .pathname
        .split('/')[2]
        if (this.state.data && id !== this.state.data._id) {
            this.refresh()
        }

        return (
            <Container>
                <Bar back={e => back(this, '/talk')} share={this.share.bind(this)} />
                {/* <ToolBar>
                    <div className='left'>
                        <ToolbarButton onClick={e => back(this, '/talk')}>
                            返回
                        </ToolbarButton>
                    </div>
                    <div className='center'>{} </div>
                    <div className='right'>
                        <ToolbarButton
                            onClick={this.share.bind(this)}> 分享
                    </ToolbarButton>
                </div>
            </ToolBar> */}
            <_Body>
            <Content className='container'>
            {this.state.data && <PostCard noLink={true} data={this.state.data} noWrap={true}/>}
            {/* <Comments> */}

            {this
                .state
                .comments
                .map((v, i) => <PostCard
                    noLink={true}
                    data={v}
                    key={i}
                    type='comment'
                    doComment={this.doComment.bind(this)}
                    onDelete={e => this.fetchComments()}/>)}
                        {/* {this.state.data && <Feed dataType={`comment?post_id=${this.state.data._id}`} component={props => <PostCard {...props} type='comment' doComment={this.doComment.bind(this)} />}/>} */}
            </Content>
            </_Body>
            { getUserInfo() &&
            <CommentEditor
                onRef={inst => this.commentEditor = inst}
                autofocus={this.props.location && this.props.location.commentFocus}
                post={this.state.data}
                onPublished={e => this.fetchComments()}/>}
            <FixedPostIcon remindOnly={true} />
        </Container>
        )
    }
})

const PLHeader = styled(Actions)`
    width: 96%;
    padding-top: 10px;
    padding-bottom: 10px;
    /* position: absolute; */
`
// const JustSpace = styled.div`
//     width: 100%;
// `
const TagListHeader = props => <TagSelect  defaultActived={1} 
                                            {...props}
                                            tags={['关注', '最新', '最热']} 
                                            paramKey='order' keys={['follow', 'new', 'hot']}/>

const PostList = withRouter(props => {
    return <Feed dataType='/post' component={PostCard} 
                {...props}
                // header={PostListHeader}
                />
})


const TopBar = styled.div `
    /* height: 40px; */
    /* display: flex;
    align-items: center; */
    /* margin-bottom: 10px; */
    /* position: absolute; */
    width: 100%;
    position: absolute;
    top: 0;
    /* height: 100px; */
    max-width: 650px;
    display: flex;
    flex-direction: row;
`

const ConfigIcon = styled(Icon)`
    padding-left: 20px;
    padding-right: 2px;
`
const NearList = withRouter(props => <Feed {...props} dataType='near' 
                                           component={GeneralCard}
                                           header={props => <TagSelect defaultActived={1} 
                                                              {...props}
                                                              tags={['博物馆', '文物古迹', '展览']}
                                                              paramKey='type'
                                                              keys={['museum','unit', 'exhibit']}/>}/>)

const HotTags = withRouter(class extends React.Component {
    render() {
        return <Tags><Tag>#热门</Tag></Tags>
    }
})
const Space = styled.div`
    height: 1rem;
`
const FollowRecommend = withRouter(class extends React.Component {
    state = {
        cold: false
    }
    constructor(props) {
        super(props)
        get('/post', {params: {order: 'follow', limit: 1}}).then(res => {
            console.log('Follow', res)
            this.setState({cold: res.data.length === 0})
        })
    }
    render() {
        let props = this.props
        if (!this.state.cold) {
            return <Feed dataType='/post' component={PostCard} 
                        {...props}
                        // header={PostListHeader}
                        params={{...props.params, order: 'follow'}}
                    />
        }
        setRefresh('home')
        return <Fragment>
                    <p> 您还没关注谁，选择一些用户关注吧~ </p>
                    <Space />
                    <Feed dataType='/suggests' component={GeneralCard} 
                            {...props}
                            params={{...props.params, order: 'recommend'}}
                            // header={PostListHeader}
                        />
                </Fragment>

    }
})


const TalkFirst = withRouter(class extends React.Component {
    state = {}
    recommend = ['推荐', props => <Feed dataType='/post' component={PostCard} 
                                    {...props}
                                    params={{...props.params, order: 'recommend'}}
                                    // header={PostListHeader}
                                    />]
    
    follow_ =  ['关注', props => <Feed dataType='/post' component={PostCard} 
                                {...props}
                                // header={PostListHeader}
                                params={{...props.params, order: 'follow'}}
                                />]
    follow = ['关注', props => <FollowRecommend {...props} />]                               
    all = ['最新', props => <Feed dataType='/post' component={PostCard} 
                                    {...props}
                                    params={{...props.params}}
                                    // header={HotTags}
                                    />]
    album = ['相册', props => <PhotoGallery direction={"column"} />]
    topic = ['话题', props => <Feed dataType='/topic' component={GeneralCard} 
                                    {...props}
                                    header={TagListHeader}
                                    params={{...props.params, order: 'follow'}}
                                    />]
    tabs = [
        this.all,
        this.recommend,
        // this.album,
        this.follow,
        this.topic
    ]
    unloginTabs = [
        this.recommend,
        // this.album,
        this.all,
        this.topic
    ]

    componentDidMount() {

    }
    Div = styled.div`
        height: 20px;
    `
    render() {
        let refresh = getRefresh('home') || this.props.location.refresh;
        return (
            <Container color='white'>
                {/* <AddressBar title={'社区'} /> */}
                <SearchBox
                    onFocus={e => this
                    .props
                    .history
                    .push('/search')}
                    placeholder='搜索 文物/展览/博物馆 ...'/>
                <Body width='96%'>
                    {/* <this.Div /> */}
                    {/* <PostListHeader /> */}
  
                    <Tabbed tabs={getUserInfo() && this.tabs || this.unloginTabs} refresh={refresh} />
                    {/* <PostList refresh={refresh}/> */}
                </Body>
                <FixedPostIcon bottom='100px'/>
            </Container>
        )
    }
})

export {PostCard, PostList, TalkFirst, PostPage};