import React, {Fragment} from 'react';
import {withRouter} from 'react-router';
import { Link } from 'react-router-dom';
import {Container} from './Common';
import {back, shareNav, get} from './Backend';
import styled from 'styled-components';

// import {Markdown as ReactMarkdown} from './newsprint';

import {Icon} from 'react-icons-kit';
import {share} from 'react-icons-kit/feather/share';
import {arrowLeft as backward} from 'react-icons-kit/feather/arrowLeft';
import {logo} from './Config';
import {CoverCard} from './CoverCard';
import { FaAngleLeft, FaPlus, 
    FaShare as FaShare,
    FaBookmark,
    FaHeart,
    FaPen
} from "react-icons/fa";

import {post, getUserInfo} from './Backend';
import {invalidate} from './State';
import { Portal } from 'react-portal';
import {x} from 'react-icons-kit/feather/x';
import {image} from 'react-icons-kit/feather/image';
import { Plugins, CameraResultType, CameraSource } from '@capacitor/core';
import {toPageSrc} from './CoverCard';
import { FavPromt } from './Fav';
import { Bar } from './Basics';
import axios from 'axios';
import FileAPI from 'fileapi';
import {} from 'fileapi/plugins/FileAPI.exif';
import Compress from 'compress.js';
import {Toast} from 'react-weui';
const { Camera } = Plugins
const { Modals } = Plugins;


let r = document.getElementById('root')
const isStandalone = window.matchMedia('(display-mode: standalone)').matches

const Contribute = withRouter(class extends FavPromt {
    Body = styled.div`
        width: 100%;
        height: 100%;
        position: relative;
        top: 50px;
        background-color: snow;
        overflow-Y: auto;
        padding-bottom: 200px;
    `
    init() {
        this.state.covers = this.props.location.data && this.props.location.data.images || []
        this.state.covers = this.state.covers.map((v, i) => {
            return v.src && {...v, url: v.src} || {url: v, src: v}
        })        
        this.state.extra = this.props.location.extra
        this.state.data = this.props.location.data
        this.state.exhibit_id = this.props.location.exhibit_id
    }
    componentWillUnmount() {

    }

    Promt = styled.div`
        margin-top: 10px
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 10px;
        width: calc(100% - 20px);
        border-radius: 5px;
        /* background-color: rgba(0, 0, 0, 0.1); */
        text-align: center;
        /* color: green; */
        font-weight: bold;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    `
    Line = styled.div`
        width: (100% - 40px);
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        padding-right: 20px;
        padding-left: 20px;
        margin-bottom: 20px;

    `
    Key = styled.div`
        width: 4rem;
        text-align: left;
        font-weight: bold;
        flex-grow: 0;
        vertical-align: center;
        font-size: 16px;
    `
    Value = styled.input`
        flex-grow: 1;
        width: 100%;
        padding-bottom: 5px;
        padding-top: 5px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 5px;
        border: 0;
    `
    DescInput = styled(this.Input)`
        width: 100%;
        background-color: white;
        margin: 0 0;
    `
    Cover = styled.div`
        height: 100px;
        width: 100px;
        border-radius: 10px;
        background-color: white;
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    `
    Covers = styled.div`
        flex-grow: 1;
        width: 100%;
        padding-bottom: 5px;
        padding-top: 5px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 5px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow: auto;
    `
    Img = styled.img`
        height: 100%;
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
    `
    ImgWraper = styled.div`
        height: 100px;
        width: 100px;
        border-radius: 10px;
        margin-top: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;
        flex-shrink: 0;
    `
    Minus = styled.div`
        position: absolute;
        top: 10px;
        right: 10px;
        color: white;
        font-size: 14px;
    `
    selectImage(e) {

        document.getElementById('imageInput').click()
        if (isStandalone) {
            document.getElementById('imageInput').click()
        }
        // document.getElementById('imageInput').click()
        // let options = {
        //     quality: 90,
        //     // allowEditing: true,
        //     height: 1920,
        //     width: 1920,
        //     resultType: CameraResultType.DataUrl,
        //     source: CameraSource.Prompt
        //   }
        // let res = await Camera.getPhoto(options)
        // console.log(res)
        // this.setState({
        //     covers: [...(this.state.covers || []), res.dataUrl]
        // })
    }
    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }
    antiqueFields = [
        {
            name: '名称',
            key: 'title',
            placeholder: '文物名称'
        },
        {
            name: '年代',
            key: 'ageDesc',
            placeholder: '文物本身的年代'
        },
        {
            name: '出土地点',
            key: 'excavatedPlace',
            placeholder: '出土地点'
        },
        {
            name: '类别',
            key: 'cagegoryName',
            placeholder: '文物类别'
        },
        {
            name: '收藏',
            key: 'museumName',
            placeholder: '收藏在哪（请规范使用博物馆名称）'
        }
    ]
    exhibitFields = [
        {
            name: '名称',
            key: 'title',
            placeholder: '展览名称'
        },
        {
            name: '起止时间',
            key: 'date',
            placeholder: '起止时间，常设展输入『常设』'
        },
    ]
    museumFields = [
        {
            name: '地址',
            key: 'address',
            placeholder: '地址'
        },
    ]
    unitFields = [
        {
            name: '地址',
            key: 'address',
            placeholder: '地址'
        },
    ]
    getFields() {
        let type = this.state.extra.type
        return {
            antique: this.antiqueFields,
            exhibit: this.exhibitFields,
            museum: this.museumFields,
            unit: this.unitFields
        }[type]
    }
    async commit() {
        this.setState({showLoading: true})
        let d = this.props.location.data || {}
        let antique_id = d && (d.antique_id || d._id)
        let fields = this.getFields()
        let kv = fields.map((v, i) => {
            return [v.key, this.refs[v.key].value]
        })
        let data = {
            ...Object.fromEntries(kv),
            desc: this.refs.desc.value,
            comment: this.refs.comment.value,
            antique_id: antique_id,
            id: antique_id,
            from: !d.is_draft && antique_id,
            exhibit_id: this.state.exhibit_id,
            ...this.state.extra
        }
        console.log(data)
        let covers = await Promise.all((this.state.covers || []).map(async (v, i) => {
            if (v.src) {
                return v
            }
            let key = `contribution-${data.title}-${i}-${Date.now()}.jpg`
            let o = {}
            let res = await post('/upload', {key: key})
                console.log(res)
            let ret = await this.upload(this.dataURLtoFile(v.url), res.data)
            console.log('Aliyun:', ret)
            o.src = `https://paoying.oss-cn-beijing.aliyuncs.com/${key}`
            o.url = o.src
            return o
        }))
        console.log(data, covers)
        let res = await post('/contribute', {
            ...data,
            images: covers
        })
        this.setState({showToast: true, showLoading: false})
        
        invalidate(this.props.location.from)
        this.props.history.replace(`/${data.type}/${res.data._id}`)

    }
    upload(file, options) {
        const url = 'https://paoying.oss-cn-beijing.aliyuncs.com/';
        const formData = new FormData();
        console.log('Options', options, file)
        for (var v in options) {
            formData.append(v, options[v])
        }
        formData.append('file', file)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return  axios.post(url, formData,config)
    }
    async onImageSelected(e) {
        let files = [...e.currentTarget.files]
        const compress = new Compress();
        let data =  await compress.compress(files, {
            size: 4,
            quality: 0.9,
            maxWidth: 1920,
            maxHeight: 1920,
            resize: true,
        })
        let images = data.map((v, i) => {
            this.state.counter = (this.state.counter || 0) + 1
            let o = {url: `${v.prefix}${v.data}`, index: this.state.counter, alt: v.alt, prefix: v.prefix}
            return o
        })
        this.setState({covers: [...(this.state.covers || []), ...images]})
        console.log(this.state)
    }
    deleteImage(v, i) {
        this.state.covers.splice(i, 1)
        this.setState({})
    }

    render() {
        let data = this.state.data || {}
        let fields = this.getFields()
        console.log(data)
        return <Container>
            <Bar back={e => back(this, '/')} share={this.commit.bind(this)} shareTitle='提交'/>
            <input  style={{'display': 'none'}} id='imageInput' ref='imageInput' type='file' accept="image/*" multiple onChange={this.onImageSelected.bind(this)} />
            <this.Body>
                <this.Line>
                    <this.Key> 图片 </this.Key>
                    <this.Covers>
                        {
                            (this.state.covers || []).map((v, i) => <this.ImgWraper>
                                 <this.Img src={toPageSrc(v.url)} />
                                 <this.Minus onClick={e => this.deleteImage(v, i)}> 删除 </this.Minus>
                                 </this.ImgWraper>)
                        }
                    <this.Cover onClick={this.selectImage.bind(this)}>
                        <FaPlus size='50px'/>
                    </this.Cover>
                    </this.Covers>
                </this.Line>

                {
                    fields.map((v, i) => {
                        return <this.Line>
                            <this.Key>{v.name} </this.Key>
                            <this.Value defaultValue={data[v.key]} ref={v.key} placeholder={v.placeholder || v.name} />
                            </this.Line>
                    })
                }
                        
                <this.Line>
                <this.Key> 介绍 </this.Key>
                <this.DescInput ref='desc' placeholder={'介绍，可以使用Markdown格式'} defaultValue={data.desc} />
                </this.Line>
                <this.Line>
                <this.Key> 备注 </this.Key>
                <this.DescInput  ref='comment' placeholder={'介绍一下您这次修订的目的和原因'}  />
                </this.Line>

                <this.Center>
                <this.Sure onClick={this.commit.bind(this)}> 好了 </this.Sure>
                <Toast icon="success-no-circle" show={this.state.showToast}>发布成功</Toast>
                <Toast icon="loading" show={this.state.showLoading}>发布中...</Toast>
                </this.Center>
                <this.Promt>
                    您在多知上贡献的条目一切权利归您本人所有。<Link>贡献指南</Link>
                </this.Promt>
            </this.Body>
            </Container>
    }
})


export {Contribute}