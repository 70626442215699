import React from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {Container, StyledToolbar, Body, Feed, AvatorIcon} from './Common';
import styled from 'styled-components';
import {getRefresh, setHash, getHash, setRefresh, getCache, setCache} from './State';
import Avator from 'react-avatar';
import {post, get} from './Backend';
import { toPageSrc } from './CoverCard';
import { AddressBar } from './Detail';
import { InfinitList, List } from './Basics';

const Card = styled.div `
    width: calc(100%);
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: ${props => props.readed && 'whitesmoke' || 'white'};
    /* padding-right: 20px; */
`
const Left = styled.div `
    width: 50px;
    padding-left: 20px
    padding-right: 10px;
`
const Right = styled.div `
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    padding-right: 20px;
`
const Header = styled.div `
    width: 100%;
    /* font-weight: bold; */
    display: flex;
    justify-content: space-between;
    color: black;
`
const TextContent = styled.div `
    width: 100%;
    margin-bottom: 5px;
    color: grey;
    font-size: small;
`

const AvatorPeaple = (props) => <Link to={{pathname: `/user/${props.user._id}`, user: props.user}}> <Avator  src={toPageSrc(props.user.avator)} round={true} size='40px'  name={props.user.nickName} {...props}/> </Link>

const Summary = props => <TextContent> {`${props.text.substring(0, 30)} ...`} </TextContent>

const RemindCard = withRouter(class extends React.Component {
    getLink() {
        let type = this.props.data.type
        if (type === 'post' || type === 'comment' || type === '@') {
            return `/posts/${this.props.data.post._id}`
        }
    }
    // componentDidMount() {
    //     this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave)
    // }
    // routerWillLeave(nextLocation) {
    //     console.log('routerWillLeave')
    //     localStorage.setItem(`reminder/${this.props.data._id}`, true)
    // }
    gotoPost() {
        setHash('reminder', this.props.data._id, true)
        post('reminder/read', [{
            type: 'reminder',
            id: this.props.data._id
        }])
        this.props.history.push(this.getLink())
    }
    render() {
        // console.log('RemindCard', this.props, localStorage.getItem(`reminder/${this.props.data._id}`))
        return (

            <Card readed={getHash('reminder', this.props.data._id)} >
                <Left>
                    <AvatorPeaple user={this.props.data.user} />
                </Left>
                {/* <Link to={this.getLink()}> */}
                <Right onClick={this.gotoPost.bind(this)} touched={this.gotoPost.bind(this)}>
                    <Header>{this.props.data.header} </Header>
                    <Summary text={this.props.data.summary || ''} />
                </Right>
                {/* </Link> */}
            </Card>

        )
    }
})

const Reminder = withRouter(class extends React.Component {
    state = {
    }
    componentDidMount() {
        post('reminder/look')
        if (!getCache('reminder/init')) {
            get('/reminder/read').then(res => {
                res.data.map((v, i) => {
                    return setHash(v.type, v.id, true)
                })
                setCache('reminder/init', true)
                setRefresh('reminder')
            })

        }
    }
    Div = styled.div`
        height: 20px;
        width: 100%;
    `
    List = InfinitList('reminder')
    loadMore(offset) {
        if (offset === 0) {
            this.state.nextUrl = null
        }
        return new Promise((resolve, reject) => {
            let url = this.state.nextUrl || '/reminder'
            get(url).then(res => {
                console.log(res)
                resolve(res.data)
                this.state.nextUrl = res.data.next
            })
        })
    }
    render() {
        let refresh = getRefresh('reminder') || this.props.location.refresh;
        return (
            <Container>
                <StyledToolbar>
                    <div />
                    <div className='center'> 通知 </div>
                    <div />
                </StyledToolbar>
                {/* <AddressBar title={'通知'} /> */}

                <Body width={'100%'}>
                    {/* <this.Div /> */}
                    <this.List 
                        cacheKey='reminder'
                        loadMore={this.loadMore.bind(this)}
                        itemComponent={RemindCard}
                        refresh={refresh}
                    />
                    {/* <Feed dataType='reminder' component={RemindCard} refresh={refresh} /> */}
                </Body>
            </Container>
        )
    }
})


export {Reminder}