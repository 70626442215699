import React from 'react';
import queryString from 'querystring';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

import FirstPage, {TheCity, TheNear} from './First.js';
import styled from 'styled-components';
import SearchPage from './Search.js'
import {HashRouter as Router, Route, Link, Switch, Redirect} from "react-router-dom";
import {
    Provider,
    KeepAlive,
  } from 'react-keep-alive';
import {
    Tab,
    TabBarItem,
    TabBarIcon as _TabBarIcon,
    Article,
    TabBar,
    TabBody,
    Badge as _Badge,
    TabBarLabel as _TabBarLabel,
    Cell
} from 'react-weui';
// import 'semantic-ui-css/semantic.min.css';
import {Icon as _Icon} from 'react-icons-kit';
import {home} from 'react-icons-kit/feather/home';
import {hash} from 'react-icons-kit/feather/hash';
import {user} from 'react-icons-kit/feather/user';
import {inbox} from 'react-icons-kit/feather/inbox';
import {search} from 'react-icons-kit/feather/search';
import {plus} from 'react-icons-kit/feather/plus';
// import Main from './Main'
import 'weui';
import 'react-photoswipe/lib/photoswipe.css';

// import 'typeface-roboto';

import './App.css';
import 'react-weui/build/packages/react-weui.css';
import Antique from './Antique.js';
import Museum from './Museum.js';
import Post from './Editor.js';
import {
    // Exhibit, MuseumPage, UnitPage, AntiquePage,
     TagPage} from './Exhibit';
import {Exhibit as ExhibitPage, 
    Museum as MuseumPage, 
    Antique as AntiquePage, 
    Unit as UnitPage,
    FavPage,
    Book as BookPage
} from './Item';


import {WikiPage} from './Wiki';
import {CitySelect, PlaceSearch} from './City';
// import Post from './Post.js';
import {Container} from './Common.js';
import {UserCreate, UserHome, HeadSetting} from './User.js';
import {withRouter} from 'react-router';
import {TalkFirst as _, PostPage} from './Discuss.js';
import {Talk as TalkFirst} from './Talk';
import {Square} from './Square';
import NewFirst from './FirstV1';
import { Contribute } from './Contribute';
import {Reminder} from './Reminder';
import { get, checkLogin, getUserInfo, post } from './Backend';
import { add, setSession } from './Log';
import { setRefresh, setCache, getCache } from './State';

import {
    Plugins,
    Capacitor,
    StatusBarStyle,
    PushNotification,
    PushNotificationToken,
    PushNotificationActionPerformed } from '@capacitor/core';
import { FaCross } from 'react-icons/fa';

const { Device } = Plugins;
const { SplashScreen } = Plugins;
const { PushNotifications } = Plugins;
const { Geolocation } = Plugins;

// Hide the splash (you should do this on app launch)


// import { withRouter } from 'react-router/cjs/react-router.min';

// import {     Navigator,     Tabbar,     Tab,     Page,     Button,
// Toolbar,     BackButton,     SearchInput } from 'react-onsenui';

const StyledTabbar = styled.div `
    width: 100%;
    display: flex;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
    right: 0;
    align-items: flex-start;
    height: 59px;
    border-top: 1px solid;
    padding-top: 5px;

`
// const StyledTab = styled(Tab)`     margin-bottom: 30px; `
const StyledSwitch = Switch


const TabBarLabel = styled(_TabBarLabel)`
    margin-top: 5px;
`

const Badge = styled(_Badge)`
`
const Icon = styled(_Icon)`
    margin-top: 0;
    padding-top: 0;
`
const TabBarIcon = styled(_TabBarIcon)`
    position: relative;
    padding-top: 0;
    margin-top: 0px;
    &i: {
        padding-top: 0;
    }
`

const StyledRoute = Route

const Blank = withRouter(class extends React.Component {
    componentDidMount() {
        let to = this.props.location.to;
        console.log(to)
        this.props.history.replace(to);
    }
    render() {
        return (
            <div />
        )
    }
})

let device = {}
// Plugins.StatusBar.setStyle({
//     style: StatusBarStyle.Light
// })
// const keepAlive = component => props => <KeepAlive name={`${props.location.pathname}`}> {React.createElement(component, {...props})} </KeepAlive>
const keepAlive = component => component
window.history.scrollRestoration && (window.history.scrollRestoration = 'auto');

const isStandalone = window.matchMedia('(display-mode: standalone)').matches

// Converts numeric degrees to radians
function toRad(Value) 
{
    return Value * Math.PI / 180;
}

function calcCrow(lat1, lon1, lat2, lon2) 
{
  var R = 6371; // km
  var dLat = toRad(lat2-lat1);
  var dLon = toRad(lon2-lon1);
  var lat1 = toRad(lat1);
  var lat2 = toRad(lat2);

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c;
  return d;
}


class MainPage extends React.Component {
    state = {
        newNotify: false,
        showBanner: false,
    }
    constructor(props) {
        super(props)
        checkLogin()
        Device.getInfo().then(res => {
            console.log(res)
            // TODO check PWA and android
            if (res.platform === 'ios') {
                let barSize = this.iPhoneVersion() > 9 && '35' || '20'
                document.getElementById('root').setAttribute('class', `iOS-root-${barSize}`)
                Capacitor.Plugins.IosSwipeBack.enable()
            } 

            this.setState({
                device: res,
                platform: res.platform
            })
        })
    }
    onClick(path) {
        console.log(path, this.state)
        this.state[this.props.location.pathname] = this.props.location
        this.state[this.props.location.pathname].refresh = false
        if (!this.props.location.pathname.startsWith(path)) {
            this
                .props
                .history
                .push(this.state[path] || path);
        } else {
            console.log('Reresh!')
            this.props.history.push({ pathname: "/redirect", to: {...this.props.location, refresh: true} })
            // this.props.history.refresh();
        }
    }
    pushMessageInit() {
        Capacitor.Plugins.App.addListener("appStateChange", state => {
            PushNotifications.removeAllDeliveredNotifications()
        })

        PushNotifications.register()
         // On succcess, we should be able to receive notifications
        PushNotifications.addListener('registration', 
            (token) => {
                console.log('push token', token)
                // alert('Push registration success, token: ' + token.value);
                post('/push/token', {
                    token: token.value,
                    device: this.state.device
                })
            }
        );
        PushNotifications.addListener('registrationError', 
            (error) => {
                // alert('Error on registration: ' + JSON.stringify(error));
            }
        );

           // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived', 
            (notification) => {
                alert(notification.body);
                // alert('Push action performed: ' + JSON.stringify(notification));
                let extra = notification.data
                if (extra && extra.to) {
                    this.props.history.push(extra.to)
                }
                // PushNotifications.removeDeliveredNotifications([notification])
            }
        );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', 
            ({notification}) => {
                // alert('Push action performed: ' + JSON.stringify(notification));
                let extra = notification.data
                if (extra && extra.to) {
                    this.props.history.push(extra.to)
                }
                // PushNotifications.removeDeliveredNotifications([notification])
            }
        );

    // 
    }

    androidInit() {
        Plugins.App.addListener('backButton', () => {
            // alert('Back')
            // if (this.props.history.length === 0) {
            //     Capacitor.Plugins.App.exitApp()
            // }
            // alert('Back')
            //this.props.history.goBack()
        })
        document.addEventListener('backbutton', () => {})
        let device = this.state.device
        document.addEventListener("deviceready",  () => {
            window.JPush.init()
            window.JPush.setDebugMode(true)
            window.JPush.getRegistrationID(res => {
                post('/push/token', {
                    device: this.state.device,
                    token: res
                })
            })
        })

    }
    getGeo() {
        Geolocation.getCurrentPosition({}).then((pos) => {
            let coords = pos.coords
            setCache('location', {
                longitude: coords.longitude,
                latitude: coords.latitude,
                accuray: coords.accuracy,
                updated: Date.now()
            })
            this.state.lastUpdateGeo = Date.now()
            this.setState({})
            // setTimeout(e => this.getGeo(), 1000*60)
        })
    }
    initGeo() {

        this.getGeo()

        Geolocation.watchPosition({}, (pos, err) => {
            console.log('Error:', err)
            if (!pos) {
                return
            }
            let {coords} = pos
            setCache('location', {
                longitude: coords.longitude,
                latitude: coords.latitude,
                accuray: coords.accuracy,
                updated: Date.now()
            })
            let last = this.state.lastUpdateGeo
            if ((Date.now() - last) > 1000 * 30) {
                this.setState({lastUpdateGeo: Date.now()})
            }
        })


    }
    createClass(name,rules){
        var style = document.createElement('style');
        style.type = 'text/css';
        document.getElementsByTagName('head')[0].appendChild(style);
        if(!(style.sheet||{}).insertRule) 
            (style.styleSheet || style.sheet).addRule(name, rules);
        else
            style.sheet.insertRule(name+"{"+rules+"}",0);
    }
    iPhoneVersion() {
        var iHeight = window.screen.height;
        var iWidth = window.screen.width;
      
        if (iWidth === 414 && iHeight === 896) {
          return 11;
        }
        else if (iWidth === 375 && iHeight === 812) {
          return 10;
        }
        else if (iWidth === 320 && iHeight === 480) {
          return 4;
        }
        else if (iWidth === 375 && iHeight === 667) {
          return 6;
        }
        else if (iWidth === 414 && iHeight === 736) {
          return 7;
        }
        else if (iWidth === 320 && iHeight === 568) {
          return 5;
        }
        else if (iHeight <= 480) {
          return 2;
        } else {
            return 12
        }
      }
    componentDidMount() {
        // this.props.history.scrollRestoration = 'manual'
        // setTimeout(this.initGeo.bind(this), 1000)
        this.initGeo()
        // this.androidInit()
        Device.getInfo().then(res => {
            window.device = res
            if (res.platform === 'ios') {
                // StyleSheet.insertRule('.pswp__top-bar {top: 35px;}', 0)
                // document.stylesheets.insertRule('.pswp__top-bar {top: 35px;}', 0)
                let barSize = this.iPhoneVersion() > 9 && '35px' || '20px'
                this.createClass('.pswp__top-bar', `top: ${barSize}`)
                this.pushMessageInit()
                Plugins.StatusBar.setStyle({
                    style: StatusBarStyle.Light
                })
            } else if (res.platform === 'android') {
                this.androidInit()
                // this.pushMessageInit()
            }
            if (res.platform === 'web') {
                if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                    console.log(navigator.userAgent)
                    this.state.showBanner = true
                    this.state.isMobile = true
                    this.setState()
                }
            }
        })

        if (!this.state.logStarted) {
            setSession()
            add({
                action: null,
                location: this.props.location,
                path: this.props.location.pathname
            })
            this.props.history.listen((location, action) => {
                console.log('History Chane:', action, location)
                add({
                    action,
                    location,
                    path: location.pathname
                })
            })
            this.state.logStarted = true

        }
        setTimeout(e => this.checkReminder(), 2000)
        SplashScreen.hide();
    }

    componentWillUnmount() {
        add(null)
    }

    checkReminder() {
        get('reminder/new').then(res => {
            console.log(res);
            let timeout = 1000*60
            if (res.data.count > 0) {
                setRefresh('reminder')
                timeout = 1000 * 5
            }
            this.setState({
                newNotify: res.data.count
            })
            setTimeout(this.checkReminder.bind(this), timeout)
        }).catch(error => {
            setTimeout(this.checkReminder.bind(this), 1000*60*5)
        })
    }
    Banner = styled.div`
        width: (100% - 40px);
        height: 50px;
        padding-left: 20px;
        padding-right: 20px;
        border-bottom: 1px solid lightgrey;
        display: flex;
        align-items: center;
        /* position: absolute; */
    `
    Button = styled.div`
        height: 25px;
        /* width: 5rem; */
        background-color: green;
        color: white;
        border-radius: 5px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: auto;
        padding-top: auto;
    `
    Title = styled.div`
        width: auto;
        padding-left: 20px;
        strong {
            font-size: 20px;
        }
        font-size: 14px;
        flex-grow: 1;
    `
    downApp() {
        let url
        if (this.state.device.model === 'iPhone') {
            url = 'https://apps.apple.com/cn/app/id1477742261'
        } else {
            url = 'https://owl.paoying.net/app-release.apk'
        }
        window.open(url)
    }
    Main = styled.div`
        width: 100%;
        height: ${props => props.showBanner && 'calc(100% - 50px)' || '100%'};
    `
    render() {
        console.log(this.props.location)
        let iconSize = '25px'
        let isLogin = getUserInfo() && getUserInfo().nickName
        // this.state.showBanner = false;
        return (
            <Container 
                // paddingTop={this.state.platform === 'ios' && '30px' || '0'}
                paddingTop={'30px'}
            >
            {/* {this.state.showBanner && <this.Banner onClick={e => this.props.history.push('/')}>
                <span onClick={e => this.setState({showBanner: false})}> × </span>
                <this.Title><strong>  多知</strong>人文中国，多知一点</this.Title>
                <this.Button onClick={this.downApp.bind(this)}> 下载App </this.Button>
            </this.Banner>} */}
            <this.Main showBanner={false}>
            <StyledSwitch>
                <StyledRoute path='/near' component={TheNear} /> 
                <StyledRoute path='/posts' component={PostPage} />
                <StyledRoute path='/post' component={Post} />
                <StyledRoute path='/itemModify' component={Contribute} />
                <StyledRoute path='/museum' component={MuseumPage} />
                <StyledRoute path='/antique' component={AntiquePage} />
                <StyledRoute path='/exhibit' component={ExhibitPage} />
                <StyledRoute path='/book' component={BookPage} />
                <StyledRoute path='/fav' component={FavPage} />
                <StyledRoute path='/unit' component={UnitPage} />
                <StyledRoute path='/search' component={SearchPage} />
                <StyledRoute path='/wiki/page' component={WikiPage} />
                <StyledRoute path='/tag' component={TagPage} />
                <StyledRoute path='/square' component={Square} />
                <StyledRoute path='/city/select' component={CitySelect} />
                <StyledRoute path='/place/select' component={PlaceSearch} />
                <StyledRoute path='/city' component={TheCity} />
                <StyledRoute path='/redirect' component={Blank} />
                <StyledRoute path='/user' component={UserHome} />
                <StyledRoute path='/headset' component={HeadSetting} />
                <StyledRoute path='/login' component={UserCreate} />
                <StyledRoute path='/'>
                    <Tab>
                        <TabBody>
                            <Switch>
                                {/* <StyledRoute path='/hot' component={keepAlive(FirstPage)} /> */}
                                <StyledRoute path='/hot' component={keepAlive(NewFirst)} />
                                <StyledRoute path='/talk' component={keepAlive(TalkFirst)} >
                                </StyledRoute>
                                <StyledRoute path='/reminder' component={keepAlive(Reminder)} />
                                <StyledRoute path='/me' component={keepAlive(UserCreate)} />
                                <StyledRoute path='/' component={props => <Redirect to='/hot' />}/>
                            </Switch>
                        </TabBody>
                        <TabBar style={{paddingBottom: isStandalone && '30px' || '10px'}}>
                            <TabBarItem
                                active={this.props.location.pathname.startsWith('/hot')}
                                onClick={e => this.onClick('/hot')}>
                                <TabBarIcon>
                                    <Icon tag='span' size={iconSize} icon={search} />
                                </TabBarIcon>
                                <TabBarLabel>首页</TabBarLabel>
                            </TabBarItem>
                            <TabBarItem
                                active={this.props.location.pathname.startsWith('/talk')}
                                onClick={e => this.onClick('/talk')}>
                                <TabBarIcon>
                                    <Icon tag='span' size={iconSize} icon={hash} />
                                </TabBarIcon>
                                <TabBarLabel>社区</TabBarLabel>
                            </TabBarItem>

                            <TabBarItem
                                active={this.props.location.pathname.startsWith('/reminder')}
                                onClick={e => this.onClick('/reminder')}>
                                <TabBarIcon>
                                    <Icon tag='span' size={iconSize} icon={inbox} color={'green'} />
                                    {/* {this.state.newNotify && <Badge dot preset='header' />} */}
                                </TabBarIcon>
                                <TabBarLabel>消息</TabBarLabel>
                            </TabBarItem>
                            <TabBarItem
                                active={this.props.location.pathname.startsWith('/me')}
                                onClick={e => this.onClick(`${isLogin && '/me' || '/login'}`)}>
                                <TabBarIcon>
                                    <Icon tag='span' size={iconSize} icon={user} />
                                </TabBarIcon>
                                <TabBarLabel>吾</TabBarLabel>
                            </TabBarItem>
                        </TabBar>
                    </Tab>
                </StyledRoute>
            </StyledSwitch>
            </this.Main>
            </Container>
        );
    }
}

class App extends React.Component {

    render() {
        return (
            <Router>
                {/* <Provider> */}
                <StyledRoute path="/" component={MainPage}/>
                {/* </Provider> */}
            </Router>
        );
    }
}

export default App;
