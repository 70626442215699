import React, {Fragment} from 'react';

import {Watch} from './Actions';
import styled from 'styled-components';
import {withRouter, Link} from 'react-router-dom';
import { Wiki, Markdown } from './Wiki';
import { get } from './Backend';
import { Plugins } from '@capacitor/core';
import { logo } from './Config';
import Avator from 'react-avatar';
import { Colors } from "@blueprintjs/core";
import {getCache, AppState} from './State';
import { getCity } from './City';
import { QuotePost } from './Card';
import { MdSlideshow } from 'react-icons/md';
import { FaHeadphones } from 'react-icons/fa';
import { ImageSlides } from './Douyin';
// import Img from 'react-image';
// import VisibilitySenser from 'react-visibility-sensor';

// import 'semantic-ui-css/semantic.min.css';
// import {Button} from './Detail';

const { Browser } = Plugins;
const toPageSrc = url => url && url.includes('paoying.oss-cn-beijing') && `${url}?x-oss-process=style/page` || url
const leftAlign = '20px'
const r = document.getElementById('root')

const Card = styled.div`
    position: relative;
    height: ${props => props.big && '250px' || '180px'};
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 3px ${Colors.GRAY5};
    margin-top: 2px;
    background-image: url(${props => props.image});
    background-size: cover;
    /* background-color: rgba(0, 0, 0, 0.5); */
`
const Bottom = styled.div`
    position: absolute;
    bottom: 0;
    height: 50px;
    width: calc(100% - ${leftAlign} - ${leftAlign});
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    /* box-shadow: 0px 0px 3px ${Colors.GRAY5}; */
    padding-left: ${leftAlign};
    padding-right: ${leftAlign};
    font-size: 12px;
`

const PostBottom = styled(Bottom)`
    -webkit-line-clamp: 3;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    /* font-size: 14px; */
    color: white;
    /* background-color: transparent; */
    bottom: 0;
    box-shadow: 0 0 0;
    /* height: 50%; */
`
const Divider = styled.div`
    height: 14px;
`

const Line = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
const Desc = styled.div`
    /* color: ${Colors.GRAY1}; */
    font-size: 12px;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    /* width: 90%; */
`
const Distance = styled.div`
    font-size: small;
    color: gray;
    /* width: 8rem; */
`
const Title = styled.h5`
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    font-size: medium;
    /* background-color: rgba(0, 0, 0, 0.1); */
`


const Labels = styled.div`
    /* position: absolute;
    top: 10px;
    left: 10px; */
    display: flex;
    flex-direction: row;
    font-size: 12px;
`

const CoverTitle = styled.div`
    position: absolute;
    top: 10px;
    left: ${leftAlign};
    font-size: 16px;
    color: white;
    font-weight: bold;
    width: calc(100% - ${leftAlign} - ${leftAlign});
    display: flex;
    flex-direction: column;
    align-items: left;
`

const MainTitle = styled.div`
    font-weight: bold;
    font-family: '黑体';
    font-size: 16px;
    width: calc(100%);
`

const SubTitle = styled.div`
    font-size: 12px;
    color: rgba(244, 244, 244);
    display: flex;
    justify-content: flex-start;
    font-weight: bold;
    /* text-align: right; */
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* flex-grow: 0; */
`

const TopCover = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100px;
    border-radius: 5px;
    background: linear-gradient(to bottom, rgba(0,0,0, 0.5), rgba(0,0,0, 0));
`

const BottomCover = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: linear-gradient(to top, rgba(0,0,0, 0.1), rgba(0,0,0, 0.1));
`

const CoverDistance = styled.div`
    /* right: 20px; */
    font-weight: bold;
    margin-left: 20px;
    font-size: 16px;
    color: rgba(244, 244, 244);
    /* font-family: sans-serif; */
    /* color: grey; */
`

const CoverType = styled.div`
    position: absolute;
    width: calc(100% - 40px);
    top: 10px;
    left: ${leftAlign};
    font-size: 10px;
    color: white;
    display: flex;
    justify-content: space-between;
`

const Label = styled.div`
    border-radius: 2px;
    padding-left: 2px;
    padding-right: 2px;
    font-size: small;
    /* background-color: ${Colors.LIGHT_GRAY1}; */
    /* color: white; */
`

function toRad(Value) 
{
    return Value * Math.PI / 180;
}

function calcCrow(lat1, lon1, lat2, lon2) 
{
  var R = 6371; // km
  var dLat = toRad(lat2-lat1);
  var dLon = toRad(lon2-lon1);
  var lat1 = toRad(lat1);
  var lat2 = toRad(lat2);

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c;
  return d;
}

const BaseCoverCard = class extends React.Component {
    state = {}
    constructor(props) {
        super(props)
        this.state.pic_cover = this.props.data.data.pic_cover
        let data = this.props.data.data
        this.state.data = AppState.update(this.type, data)
        if (!this.state.pic_cover) {
            this.state.pic_cover = data.post && data.post.images && data.post.images[0] && data.post.images[0].src
        }
        this.initCover()
    }
    initCover() {
        let data = this.props.data.data
        if (data.card === 'big' && data.images) {
            let n = Math.ceil(Math.random() * 100)
            let src = data.images[n % data.images.length]?.src || data.pic_cover
            this.state.pic_cover = src
            // let idx = 0

            // let f = e => {
            //     let src = data.images[idx % data.images.length].src
            //     this.setState({pic_cover: src})
            //     setTimeout(f, 5000)
            //     idx += 1
            // }
            // setTimeout(f, 5000);
        }
    }
    getLink() {
        let data = this.props.data.data
        return `/exhibit/${data.id || data._id}`
    }
    getType() {
        return this.type
    }

    coverTitle() {
        return this.props.data.data.title
    }

    subTitle() {
        return this.props.data.data.fields[0].value
    }

    Icons = styled.div`
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        width: 100%;
        height: 100%;
        padding-right: 10px;
        color: white;
    `
    renderBottom() {
        let data = this.props.data.data
        let type = this.props.data.type
        let loc = getCache('location') || {latitude: 39.86139, longitude: 116.50417}
        let distance = data.location && calcCrow(loc.latitude, loc.longitude, data.location.latitude, data.location.longitude)
        let fields = new Set(['时间', '费用'])
        return  <Bottom>
            {
                data.fields && data.fields.map((v, i) => fields.has(v.key) && <Desc key={i}> <strong>{v.key}: </strong>{v.value} </Desc>)
            }
            <this.Icons>
                <MdSlideshow size={'20px'}/>
                {/* <FaHeadphones size={'20px'} /> */}
            </this.Icons>
    </Bottom>
    }
    renderTalk() {
        let data = this.props.data.data
        
        return data.post && <PostBottom>
            <QuotePost data={data}/>
            {/* {
                `@${data.post.user.nickName}: ${data.post.text}`
            } */}

            </PostBottom>
    }
    getDistance() {
        let data = this.props.data.data
        if (data.distance) {
            return `${(data.distance/1000).toFixed(1)}km`
        }
        let loc = getCache('location') || {latitude: 39.86139, longitude: 116.50417}
        let distance = data.location && calcCrow(loc.latitude, loc.longitude, data.location.latitude, data.location.longitude)
        return distance && `${(distance).toFixed(1)}km`
    }
    _getLink() {
        return `${this.getLink()}${this.props.data.showTalk && '?section=讨论' || ''}`
    }

    Tags = styled.div`
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        /* padding-left: 20px; */
        /* padding-right: 20px; */
        padding-top: 5px;
        position: absolute;
        bottom: 5px;
        margin-left: 20px;
        width: calc(100% - 40px);
    `
    CardTag = styled.span`
        /* background-color: rgba(0, 0, 0, 0.3); */
        /* border: 1px solid lightgrey; */
        color: rgba(244, 244, 244);
        /* padding-left: 5px; */
        padding-right: 5px;
        padding-top: 1px;
        padding-bottom: 1px;
        font-size: 12px;
        font-weight: bold;
        border-radius: 10px;
        margin-right: 10px;
    `

    Tag = styled(this.CardTag)`
        background-color: transparent;
        border: 0;
    `
    renderSlides() {
        return <this.Icons>
                <MdSlideshow size={'50px'} color='rgba(244, 244, 244, 0.7)' onClick={e => console.log(e)}/>
                {this.state.showSlides && <ImageSlides /> }
            </this.Icons>
    }
    render() {
        let data = this.state.data
        let type = this.props.data.type

        return (data && <Fragment>
            <Divider />
        <Card className='coverCard' image={toPageSrc(this.state.pic_cover || '')} 
              big={data.card === 'big'}
              onClick={e => this.props.history.push({pathname: this._getLink(), data: data})}>
              <TopCover />
              {this.type === 'exhibit' && <BottomCover />}
            {/* <CoverType> <strong>{this.typeName}</strong></CoverType> */}
            <CoverTitle> 

                <MainTitle> {this.coverTitle()} </MainTitle>

                <SubTitle> 
                { data.museumName && <this.CardTag>{data.museumName} </this.CardTag> }
                {
                    (data.tags || []).map((v, i) => <this.Tag key={v}> {v} </this.Tag>)
                }
                </SubTitle> 
            </CoverTitle>
            {/* { this.renderSlides() } */}
            {/* <CoverDistance> {this.getDistance()} </CoverDistance> */}
            {/* {!this.props.data.showTalk && this.renderBottom()} */}
            {this.props.data.showTalk && this.renderTalk()}
            { !this.props.data.showTalk && <this.Tags>
                {/* <this.CardTag> {this.typeName} </this.CardTag> */}
                {/* { data.museumName && <this.CardTag> {data.museumName} </this.CardTag> } */}
                <span />
                <CoverDistance> {this.getDistance()} </CoverDistance>
            </this.Tags>}

      </Card>

      </Fragment>)
    }
}

const ExhibitCoverCard = withRouter(class extends BaseCoverCard {
    type = 'exhibit'
    typeName = '展览'
    constructor(props) {
        super(props)
        let data = this.props.data.data
        // this.typeName = data.museumName || this.typeName
    }
    subTitle() {
        return this.props.data.data.fields[0].value
    }
})

const MuseumCoverCard = withRouter(class extends BaseCoverCard {
    type = 'museum'
    typeName = '博物馆'
    getLink() {
        let data = this.props.data.data
        return `/museum/${data.title}`
    }
    subTitle() {
        return this.props.data.data.address
    }
    coverTitle() {
        return this.props.data.data.title
    }

    renderBottom() {
        let data = this.props.data.data
        return data.fields && data.fields.length > 0 && <Bottom>
            {
                this.props.data.data.fields.map((v, i) => <Desc key={i}> <strong>{v.key}: </strong>{v.value} </Desc>)
            }
            </Bottom> || <Fragment />
    }

})

const UnitCoverCard = withRouter(class extends BaseCoverCard {
    type = 'unit'

    constructor(props) {
        super(props)
        let data = this.props.data.data
        this.typeName = data.fields && data.fields[0] && data.fields[0].value || '文物保护单位'
    }
    getLink() {
        let data = this.props.data.data
        return `/unit/${data._id}`
    }
    coverTitle() {
        return this.props.data.data.title
    }
    renderBottom() {
        return <Fragment />
    }
})

const AntiqueCover = withRouter(class extends BaseCoverCard {
    type = 'antique'
    typeName = '文物'
    getLink() {
        let data = this.props.data.data
        return `/antique/${data.antique_id || data._id}`
    }
    renderBottom() {
        let data = this.props.data.data
        return data.fields && data.fields.length > 0 && <Bottom>
            {
                this.props.data.data.fields.map((v, i) => v.value.length > 0 && <Desc key={i}> <strong>{v.key}: </strong>{v.value} </Desc>)
            }
            </Bottom> || <Fragment />
    }
})
const PostCover = withRouter(class extends BaseCoverCard {
    type = 'post'
    getLink() {
        let data = this.props.data.data
        return `/post/${data._id}`
    }

})

const TagCover = withRouter(class extends BaseCoverCard {
    type = 'tag'
    typeName = '话题'
    getLink() {
        let data = this.props.data.data
        return `/tag/${data._id}`
    }

    renderBottom() {
        return <Bottom>

        </Bottom>
    }
})

const Segment_ = styled.div`
    border-top: 1px solid lightgray;
    width: 100%;
`
const Head = styled.div`
    strong {
        font-size: 20px
        margin-right: 10px;
    }

    width: 100%;
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    display: table;
    .left {
        display:table-cell;
        vertical-align:bottom;
        text-align: left;
        width: auto;
    }
    .right {
        display:table-cell;
        vertical-align:bottom;
        text-align: right;
    }
`

const Segment = withRouter(class extends React.Component {
    render() {
        let data = this.props.data.data
        return <Fragment>
            <Divider />
            <Divider />
            <Segment_ />
            <Head> 
                <div className='left'><strong>{data.title}</strong>{data.title === '同城' && getCity()}</div>
                { data.to &&
                <Link className='right' to={data.to}>
                    查看全部
                </Link>
                }
            </Head>

            </Fragment>

    }
})

const ListCard = withRouter(class extends React.Component {
    
})

const CoverCard = withRouter(class extends React.Component {
    components = [ExhibitCoverCard, MuseumCoverCard]
    render() {
        let type = this.props.data.type
        if (type === 'exhibit') {
            return <ExhibitCoverCard {...this.props} />
        } else if (type === 'museum') {
            return <MuseumCoverCard {...this.props} />
        } else if (type === 'unit') {
            return  <UnitCoverCard {...this.props} />
        } else if  (type === 'segment') {
            return <Segment {...this.props} />
        } else if (type === 'antique') {
            return <AntiqueCover {...this.props} />
        } else if (type === 'tag') {
            return <TagCover {...this.props} />
        }
        else {
            return <div />
        }
    }
})



export {CoverCard, toPageSrc}