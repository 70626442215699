import React, {Fragment} from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Container } from './Common';
import { Bar, InfinitList } from './Basics';
import { BaseItem } from './Item';
import styled from 'styled-components';
import { GeneralCard } from './Detail';
import { get, shareNav } from './Backend';
import { IndexDB } from './State';
import queryString from 'querystring';



const Square = withRouter(class extends React.Component {
    UserList = InfinitList('user')
    TagList = InfinitList('tag')

    TabBar = styled.div`
        width: 94%;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: large;
        display: flex;
        flex-direction: row;
        vertical-align: baseline;
        justify-content: space-between;
        border-bottom: 1px solid lightgrey;
    `

    Body = styled.div`
        width: 100%;
        height: 70vh;
        position: relative;
        top: 50px;
        /* background-color: whitesmoke; */
        /* overflow-Y: hidden; */
        /* padding-bottom: 200px; */
    `
    Tab = styled.div`
        border-bottom: ${props => props.actived && '5px solid darkgreen' || '5px solid transparent'};
        svg {
            margin-bottom: 0;
        }
    `
    Welcome = styled(this.TabBar)`
        display: flex;
        flex-direction: column;
        font-size: 14px;
        padding-bottom: 20px;
        strong {
            font-size: 20px;
            font-weight: bold;
        }
        border-bottom: 0;
    `
    tabs = [
        '多友',
        '话题',
        '收藏夹'
    ]
    componentWillUnmount() {
        IndexDB.set('square.State', this.state)
    }
    constructor(props) {
        super(props)
        this.state = IndexDB.get('square.State') || this.state
    }
    loadMoreUser(offset) {
        return get('/suggests', {
            params: {
                offset: offset,
                limit: 10,
                order: 'recommend'
            }
        })
    }
    loadMoreTag(offset) {
        console.log(offset)
        return get('/topic', {
            params: {
                offset: offset,
                limit: 10,
            }
        })
    }
    CenterText = styled.div`
        width: 94%;
        margin: auto;
        text-align: center;
    `
    renderList(actived) {
        if (actived === '多友') {
            return <this.UserList 
                cacheKey='square.User'
                loadMore={this.loadMoreUser.bind(this)}
                itemComponent={GeneralCard}
            />
        } else if (actived === '话题') {
            return <this.TagList 
                cacheKey='square.Tag'
                loadMore={this.loadMoreTag.bind(this)}
                itemComponent={GeneralCard}
        />
        } else {
            return <this.CenterText> 很快就有了 </this.CenterText>
        }
    }
    state = {

    }
    share() {
        shareNav('多知广场', '知友扎堆的地方', this.props.location.pathname)
    }
    render() {
        let values = queryString.parse(this.props.location.search.slice(1));
        if (values.chapter) {
            this.state.actived = values.chapter
        }
        let actived = this.state.actived || this.tabs[0]

        return <Container>
                <Bar back={e => this.props.history.goBack()} share={e => this.share()} noBaseLine='true' />
                <this.Body>
                <this.Welcome>
                    <strong>多知广场</strong> 
                    <span>关注一些多友和话题，你可以在 <Link to={'/talk?chapter=关注'}> 关注 </Link> 看到他们的动态 </span>
                </this.Welcome>
                <this.TabBar>
                    {
                        this.tabs.map((v, i) => <this.Tab onClick={e => this.props.history.replace(`/square?chapter=${v}`)} actived={actived === v}> {v} </this.Tab>)
                    }

                </this.TabBar>
                {
                    this.renderList(actived)
                }
                </this.Body>
            </Container>
    }
})

export {Square}