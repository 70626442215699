import React, {Fragment} from 'react';
import {
    Toolbar, Page, ToolbarButton,
    // SearchInput,
    Card
} from 'react-onsenui';

// import styled, {css} from 'react-emotion'; import styled, { css } from
// 'react-emotion'
import styled from 'styled-components';
// import {SearchInput} from 'react-weui';

import "react-tabs/style/react-tabs.css";
import {SearchBox, StyledToolbar, End} from './Common.js';
import SearchPage from './Search.js';
import {withRouter} from 'react-router-dom';
import {
    Feed,
    StyledPage,
    Tabbed,
    Container,
    Body,
    Scroller
} from './Common.js';
import {Route, Switch, Redirect} from "react-router-dom";
import {RoutedTabs, NavTab} from "react-router-tabs";
import { PostList } from './Discuss';
import {Entry, Museum, Exhibit, Unit, LinkCard} from './Card';
import {TagSelect, GeneralCard, LeftMost, Desc, AddressBar, FixedPostIcon} from './Detail';
import {getRefresh, setRefresh, setCache, getCache, getPosition, getPlaceOfLoc, IndexDB} from './State';
import { ExhibitList } from './Exhibit';
import { GroupCard } from './Group';
import { back, shareNav, get } from './Backend';
import axios from 'axios';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Slider from "react-slick";
import { getPlace, getCity, locateCity, getLoc } from './City';
import queryString from 'query-string';
import LazyLoad from 'react-lazyload';
import {Icon} from 'react-icons-kit';
import {settings} from 'react-icons-kit/feather/settings';
import {CoverCard} from './CoverCard';
import {InfinitList as genList} from './Basics';
import { Plugins } from '@capacitor/core';
import {ActionSheet} from 'react-weui';
import { Portal } from 'react-portal';
import { Radio } from './Speech';
import { FaSearch } from 'react-icons/fa';
const { Keyboard, Modals } = Plugins;
// import {bindLifecycle} from 'react-keep-alive';
// import "styles/react-router-tabs.css"; import
// "react-router-tabs/styles/react-router-tabs.css";


const NavBar = styled.div`
    border-radius: 20px;
    height: 30px;
    width: calc(80% - 40px);
    background-color: rgba(0, 0, 0, 0.4);
    /* background-color: #fffafa; */
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    bottom: 100px;
    left: 10%;
    margin-left: auto;
    margin-right: auto;
    display: table;
    color: white;
    font-weight: bold;
    /* flex-direction: row;
    justify-content: space-between; */

    /* align-items: center; */
`

const BarTab = styled.div`
    /* height: 20px; */
    margin: 2px 0;
    border-radius: 10px;
    background-color: ${props => props.actived && 'rgba(0, 0, 0, 0.7)' || 'transparent'};
    /* color: ${props => props.actived && 'black' || 'white'}; */
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    max-width: 3rem;
    padding:  2px 10px;
    /* font-size: small; */
    font-weight: bold;
    font-family: '黑体';
    /* color: grey; */
`
const CityHeader = styled.div`
    position: sticky;
    position: -webkit-sticky;
    width: 96%;
    /* height: 30px; */
    padding-top: 10px;
`
const Headers = styled.div`
    font-size: 20px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    border-bottom: 1px solid lightgrey;
`

const HeaderTab = styled.div`
    /* font-size: 16px; */
    border-bottom: ${props => props.actived && '5px solid darkgreen' || '5px solid transparent'};
    font-size: 16px;
    font-weight: bold;
    font-family: '黑体';
    color: ${props => props.actived && 'black'  || 'darkgrey'};
`
const SortTab = styled.div`
    font-size: 16px;
    border-bottom: 5px solid transparent;
    /* -webkit-appearance: none; */
    /* border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    width: 150px;
    height: 15px; 
    position: relative;
    bottom: 5px;
    display: flex; */
    select {
        font-size: 16px;
        display: inline-block;
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;
        ::after {
            content: "▼";
        }      
    }
`

const FirstPageV1 = withRouter(class extends React.Component {
    state = {
        barActived: '推荐',
        typeActived: {}
    }
    constructor(props) {
        super(props)

        this.state = IndexDB.get(this.cacheKey) || this.state
        this.state.refresh = this.props.location.refresh      

    }

    cacheKey = 'firstPage'
    componentDidMount() {
        console.log(this.state)
        // Keyboard.setAccessoryBarVisible(false)
    }
    componentWillUnmount() {
        IndexDB.set(this.cacheKey, this.state)
        // Keyboard.setAccessoryBarVisible(true)
    }
    loadMore(offset) {
        console.log(offset)
        if (offset < 5) {
            this.state.recommendOffset = offset
        }
        return get('/select', {params: {location: getLoc(), 
                                        city: getCity(), 
                                        offset: offset - (this.state.recommendOffset || 0),
                                        hot: offset > 0
                                    }})
    }
    getSort() {
        let sort = this.state.sort || {}
        return (sort[this.getTypeActived()] || {value: 'new'}).value
    }
    cityLoadMore(offset) {
        
        let params = {
            offset: offset,
            limit: 10,
            location: getLoc(),
            city: getCity(),
            order: this.getSort(),
            byCity: true
        }
        let url = {
            '展览': 'exhibit',
            '博物馆': 'museum',
            '文物古迹': 'unit',
        }[this.getTypeActived()]
        return get(url , {params: params})
    }
    types = {
        '展览': 'exhibit',
        '博物馆': 'museum',
        '文物古迹': 'unit'
    }
    sections = {
        '同城': ['展览', '博物馆', '文物古迹'],
        '附近': ['文物古迹', '展览', '博物馆'],
        '文物': ['热门', '碑帖', '青铜器', '陶器', '石刻', '玉器', '古籍']
    }
    getTypeActived() {
        return this.state.typeActived[this.state.barActived] || this.sections[this.state.barActived][0]
    }
    setTypeActived(v) {
        this.state.typeActived[this.state.barActived] = v
        this.setState({})
    }
    sortOptions = {
        '展览': [
            {label: '最新', value: 'new'},
            // {label: '即将开始', value: 'start_soon'},
            {label: '即将结束', value: 'end_soon'},
            {label: '已经结束', value: 'ended'}
        ]
    }
    onSelect(e) {
        this.state.sort = this.state.sort || {}
        this.state.sort[this.getTypeActived()] = e.currentTarget.value
        this.setState({})
    }
    async selectSort() {
        let options = this.sortOptions[this.getTypeActived()] || [{label: '...'}]
        options = options.map((v, i) => {return {...v, title: v.label}})
        let promptRet = await Modals.showActions({
            title: '同城展览',
            message: '选择排序方式',
            options: options
          })
        this.state.sort[this.getTypeActived()] = promptRet
        this.setState({})
    }

    renderCity(refresh) {
        console.log(this.state, this.getSort())
        let options = this.sortOptions[this.getTypeActived()] || [{label: '...'}]
        options = options.map((v, i) => {
            return {
                label: v.label,
                onClick: e => {
                    this.state.sort = this.state.sort || {}
                    this.state.sort[this.getTypeActived()] = v
                    this.setState({showSort: false})
                }
            }
        })
        let sort = this.state.sort || {}
        let current = sort[this.getTypeActived()] || options[0]
        return <Fragment>
            <CityHeader>
                <Headers>
                {
                    this.sections[this.state.barActived].map((v, i) => 
                        <HeaderTab key={v} actived={this.getTypeActived()=== v}
                                    onClick={e => this.setTypeActived(v)}
                        >
                         {v} 
                        </HeaderTab>)
                }
                    <SortTab>
                        <span>排序: </span> 
                        <span onClick={e => this.setState({showSort: true})}> {current.label}▼ </span>

                        <Portal>
                            <ActionSheet
                                // style={{'max-width': '650px', 'left': r.offsetLeft, 'bottom': '0px', 'position': 'fixed', 'z-index': 100001}}
                                menus={options}
                                // actions={this.actions}
                                show={this.state.showSort}
                                type="ios"
                                // onRequestClose={e=>{this.setState({showMenu: false}); this.disableOverlayable();}}
                            />
                        </Portal>

                        {/* <select onChange={this.onSelect.bind(this)} value={this.getSort()}>
                            {
                                (this.sortOptions[this.getTypeActived()] || [{label: '...'}]).map((v, i) => <option value={v.value}>{v.label}</option>)
                            }
                            <optgroup disabled></optgroup>
                        </select> */}
                        {/* {
                            (this.sortOptions[this.getTypeActived()] || [{label: '...'}]).map((v, i) => <div> {v.label} </div>)
                        } */}
                    </SortTab>
                </Headers>
            </CityHeader>
            { 
                this.sections[this.state.barActived].map((v, i) =>
                    v === this.getTypeActived() && <this.CityList 
                        key={`first.City.${getCity()}.${v}.${this.getSort()}`}
                        cacheKey={`first.City.${getCity()}.${v}.${this.getSort()}`}
                        itemComponent={props => <CoverCard data={{type: this.types[v], data: props.data}}/>}
                        loadMore={this.cityLoadMore.bind(this)} 
                        refresh={refresh}
                    />)
            }

            </Fragment>
    }
    nearLoadMore(offset) {
        let params = {
            offset: offset,
            limit: 10,
            location: getLoc(),
            city: getCity(),
            type: this.types[this.getTypeActived()]
        }
        let url = 'near'
        return get(url , {params: params})
    }
    renderNear(refresh) {
        let chapters = this.sections[this.state.barActived]
        return <Fragment>
        <CityHeader>
            <Headers>
            {
                chapters.map((v, i) => 
                    <HeaderTab key={v} actived={this.getTypeActived() === v}
                                onClick={e => this.setTypeActived(v)}
                    >
                     {v} 
                    </HeaderTab>)
            }
            </Headers>
        </CityHeader>
        { 
            chapters.map((v, i) =>
                v === this.getTypeActived() && <this.NearList 
                    cacheKey={`first.Near.${v}`}
                    itemComponent={CoverCard}
                    loadMore={this.nearLoadMore.bind(this)} 
                    refresh={refresh}
                />)
        }

        </Fragment>
    }

    antiquesMore(offset) {
        return get('/hot', {
            params: {
                offset: offset,
                limit: 5,
                type: 'antique'
            }
        })
    }

    SearchTag = styled.div`
        background-color: rgba(0, 0, 0, 0.1);
        color: grey;
        display: block;
        font-size: 14px;
        font-weight: bold;
        margin-right: 10px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 5px;
        /* padding-bottom: 2px;
        padding-top: 2px; */
        height: auto;
        margin-top: 4px;
        /* margin-bottom: 5px; */
        margin-bottom: 4px;
        vertical-align: center;
        flex-shrink: 0;
        /* margin-bottom: 10px; */
    `
    renderAntiques(refresh) {
        let searchs = [
            '骆驼',
            '鸮',
            '王羲之',
            '象',
            '良渚'
        ]
        return <Fragment>
            {/* <CityHeader>
                <Headers>
                    {

                        searchs.map((v, i) => <this.SearchTag onClick={e => this.props.history.push(`/search/${v}?section=文物`)}>
                            <FaSearch size='10px' />
                            {' '}
                            {v}
                            </this.SearchTag>)
                    }
                </Headers>
            </CityHeader> */}
            <this.Antiques
                cacheKey={`first.Antiques`}
                itemComponent={CoverCard}
                loadMore={this.antiquesMore.bind(this)}
                refresh={refresh}
            />
        </Fragment>
    }
    MainList = genList('main')
    CityList = genList('city')
    NearList = genList('near')
    Antiques = genList('antiques')
    Div = styled.div`
        height: 10px;
        color: green;
    `
    render() {
        let values = queryString.parse(this.props.location.search);
        if (values.chapter) {
            this.state.barActived = values.chapter
        }
        if (values.section) {
            this.state.typeActived = values.section
        }
        console.log(this.state)
        let refresh = getRefresh('home') || this.state.refresh;
        if (this.state.refresh) {
            this.state.refresh = !this.state.refresh
        }
        return   <Container>
        <AddressBar title={this.state.barActived} 
            showCity={this.state.barActived === '同城'}/>
        {/* <Radio /> */}
        {/* <SearchBox
            onFocus={e => this
            .props
            .history
            .push('/search')}
            placeholder='搜索 帖子/文物/展览/博物馆 ...'/> */}

        <Body width='96%' ref='body'>
        <this.Div>
        </this.Div>
        { this.state.barActived === '推荐' &&
            <this.MainList 
                cacheKey='first.Default'
                itemComponent={CoverCard}
                refresh={refresh}
                loadMore={this.loadMore.bind(this)} 
            />
        }
        {this.state.barActived === '同城' && this.renderCity(refresh)}
        {this.state.barActived === '附近' && this.renderNear(refresh)}
        {this.state.barActived === '文物' && this.renderAntiques(refresh)}
        </Body>
        <NavBar>
            {

                ['推荐', '同城', '附近', '文物'].map((v, i) => {
                    return <BarTab actived={v===this.state.barActived} onClick={e => this.props.history.replace(`/hot?chapter=${v}`)}>{v}</BarTab>
                })
            }
            
        </NavBar>
        {/* <FixedPostIcon bottom='100px' remindOnly={true} /> */}
    </Container>
    }
})

export default FirstPageV1;
export {CityHeader, Headers, HeaderTab, SortTab};