import { post, getUserInfo } from './Backend';
import { Plugins, Capacitor } from '@capacitor/core';
const { Device } = Plugins;

// For an RFC4122 version 4 compliant solution, this one-liner(ish) solution is the most compact I could come up with.:

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
let pageLogs = []

const add = log => {
    if (!log) {
        if (!pageLogs[0]) {
            return
        }
        let start = 1
        if (!pageLogs[0].pre_page_id) {
            start = 0
        }
        let pages = pageLogs.slice(start, pageLogs.length)
        pageLogs = [pages[pages.length]]
        pushData(pages)
        return
    }
    let session = getUserInfo() || {}
    log.page_id = uuidv4()
    log.user_id = session._id || (visitSession.device && visitSession.device.uuid)
    log.session = session.token || visitSession.id
    log.created = Date.now()
    let pre_page = pageLogs[pageLogs.length - 1] || {}
    log.pre_page_id = pre_page && pre_page.page_id
    log.pre_path = pre_page.path
    pageLogs.push(log)
    if (pageLogs.length > 9) {
        add(null)
    }
}
let visitSession = {}
const timelyPushData = e => {
    add(null)
    setTimeout(timelyPushData, 1000*30)
}

const setSession = () => {
    // add ip, geo, host
    let session = getUserInfo()
    Device.getInfo().then(res => {
        visitSession = {
            device: res,
            user: getUserInfo(),
            id: uuidv4(),
            created: Date.now()
        }
        post('log', {
            type: 'session',
            data: [visitSession]
        })
    })

    setTimeout(timelyPushData, 1000*5)
}

window.onunload = () => add(null)
const pushData = (pages) => {
    post('log', {
        type: 'page_log',
        data: pages
    }).then(res => {
    })
}

export {add, setSession}