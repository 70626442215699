import React from 'react';
import {
    Toolbar, Page, ToolbarButton,
    // SearchInput,
    Card,
    Segment
} from 'react-onsenui';

// import styled, {css} from 'react-emotion'; import styled, { css } from
// 'react-emotion'
import styled from 'styled-components';
// import {SearchInput} from 'react-weui';

import "react-tabs/style/react-tabs.css";
import {SearchBox, StyledToolbar, End} from './Common.js';
import SearchPage from './Search.js';
import {withRouter} from 'react-router-dom';
import {
    Feed,
    StyledPage,
    Tabbed,
    Container,
    Body,
    Scroller
} from './Common.js';
import {Route, Switch, Redirect} from "react-router-dom";
import {RoutedTabs, NavTab} from "react-router-tabs";
import { PostList } from './Discuss';
import {Entry, Museum, Exhibit, Unit, LinkCard} from './Card';
import {TagSelect, GeneralCard, LeftMost, Desc, AddressBar, FixedPostIcon} from './Detail';
import {getRefresh, setRefresh, setCache, getCache, getPosition, getPlaceOfLoc} from './State';
import { ExhibitList } from './Exhibit';
import { GroupCard } from './Group';
import { back, shareNav, get } from './Backend';
import axios from 'axios';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Slider from "react-slick";
import { getPlace, getCity, locateCity } from './City';
import queryString from 'query-string';
import LazyLoad from 'react-lazyload';
import {Icon} from 'react-icons-kit';
import {settings} from 'react-icons-kit/feather/settings';
import {CoverCard} from './CoverCard';
import { Divider } from 'semantic-ui-react';
// import {bindLifecycle} from 'react-keep-alive';
// import "styles/react-router-tabs.css"; import
// "react-router-tabs/styles/react-router-tabs.css";


const TopBar = styled.div `
    /* height: 40px; */
    /* display: flex;
    align-items: center; */
    /* margin-bottom: 10px; */
    /* position: absolute; */
    width: 100%;
    position: absolute;
    top: 0;
    /* height: 100px; */
    max-width: 650px;
    display: flex;
    flex-direction: column;
`

const _Body = styled(Body)`
    /* top: 101px; */
`



const HotCard = styled(Card)`
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 50vh;
`

const HotHeader = styled.div`
    font-size: large;
    /* font-weight: bold; */
    border-bottom: 1px solid lightgrey;
    display: flex;
    justify-content: space-between;
`

const CityHeader = styled(Card)`
    width: 100%;
    border-radius: 0;
    position: relative;
    background-color: inherit;
    box-shadow: 0 0;
    font-size: 0.9rem;
    padding-left: 0;
    padding-right: 0;
    strong {
        font-size: 1.4rem;
    }
    div {
        display: inline-block;
        margin-left: 10px;
        a {
            margin-left: 10px;
        }
    }
`

const CityContent = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
`


const TheNear = withRouter(class extends React.Component {
    state = {
    }
    tabs = [
        ['博物馆', props => <Feed {...props} dataType='near' params={{...props.params, type: 'museum'}} component={GeneralCard}/>],
        ['文物古迹', props => <Feed {...props} dataType='near' params={{...props.params, type: 'unit'}} component={GeneralCard}/>],
        ['展览', props => <Feed {...props} dataType='near' params={{...props.params, type: 'exhibit'}} component={GeneralCard} />]
    ]
    constructor(props) {
        super(props)
        let values = queryString.parse(this.props.location.search);
        if (this.props.location.search && values.location) {
            if (values.location) {
                this.state.place = {
                    ...values
                }
            }
        } else if (props.history.action === 'POP') {
            this.state.place = getCache('place') || getPlace()
        } else {
            this.state.place = getPlace(p => {
                setCache('place', p)
                this.setState({})
            })
        }
    }

    componentWillUnmount() {
        setCache('place', this.state.place)
    }

    relocate() {
        getPlace(place => {
            setCache('place', place)
            // this.setState({place: place, refresh: true})
            this.props.history.replace({pathname: '/redirect', to: {...this.props.location, refresh: true}})
        })

    }
    share() {
        let path = `/near?location=${this.state.place.location}&name=${this.state.place.name}`
        shareNav(`${this.state.place}`, `${this.state.place} 附近`, path)
    }
    render() {
        let refresh = getRefresh('home') || this.props.location.refresh || this.state.refresh;

        return (
            <Container>
                {/* <TopBar>
                        <AddressBar />
                </TopBar> */}
                <StyledToolbar>
                <div className='left'>
                        <ToolbarButton onClick={e => back(this, '/hot')}>
                            返回
                        </ToolbarButton>
                    </div>
                    <div className='center'>
                        附近
                    </div>
                    <div className='right'>
                        <ToolbarButton
                            onClick={this.share.bind(this)}> 分享
                    </ToolbarButton>
                    </div>
                </StyledToolbar>

                <Body width='96%'>
                    <CityHeader>
                        <span>
                        <strong>{this.state.place.name || this.state.place.district || '这里'} </strong> 
                        </span>
                        <span>
                            附近 
                        </span>
                        <div> 
                            <Link to='/place/select'>看看别处</Link>
                            {this.state.place.adcode && <Link onClick={this.relocate.bind(this)}>回到定位地点</Link>}
                        </div>
                        {/* <Link to='/city/select'> 看看其他地方 </Link> */}

                    </CityHeader>
                    <CityContent>
                    <Tabbed 
                            tabs={this.tabs} 
                            refresh={refresh}
                            params={{
                                location: this.state.place.location
                                }}/>
                    </CityContent>
                </Body>
            </Container>
        );
    }
})

const TheCity = withRouter(class extends React.Component {
    // pushPage() {     this         .props         .navigator .pushPage({component:
    // SecondPage}); }
    state = {
        city: getCity(),
        data: [],
    }

    componentDidMount() {
        if (!this.state.city) {
            locateCity(city => {
                setCache('cityName', city)
                this.setState({city: city})
            })
        }
        console.log(this.state)
    }

    share() {
        let path = this.props.location.pathname
        let reg = /city\/?(.*)/
        let city = reg.exec(path)[1]
        if (!city) {
            path = `/city/${this.state.city}`
        }
        shareNav(`${this.state.city}`, `${this.state.city}`, path)
    }
    constructor(props) {
        super(props);
        console.log('Constructor')
        this.state.refresh = this.props.history.action !== 'POP';
        let path = this.props.location.pathname
        let reg = /city\/?(.*)/
        let city = reg.exec(path)[1]
        this.state.city = city || getCache('cityName')
    }
    tabs = [
        ['展览', ExhibitList],
        ['博物馆', (props) => <Feed dataType='museum' component={Museum} {...props} />],
        ['文物古迹', (props) => <Feed dataType='unit' component={Unit} {...props} />],
    ]

    render() {

        let refresh = getRefresh('home') || this.props.location.refresh;
        console.log(this.state)
        return (
            <Container>
                {/* <TopBar>
                        <AddressBar />
                </TopBar> */}
                <StyledToolbar>
                <div className='left'>
                        <ToolbarButton onClick={e => back(this, '/')}>
                            返回
                        </ToolbarButton>
                    </div>
                    <div className='center'>
                        同城: {this.state.city}
                    </div>
                    <div className='right'>
                        <ToolbarButton
                            onClick={this.share.bind(this)}> 分享
                    </ToolbarButton>
                    </div>
                </StyledToolbar>

                <Body width='96%'>
                    <CityHeader>
                        <span>
                        <strong>{this.state.city}</strong> 
                        </span>
                        <Link to='/city/select'> 切换城市 </Link>
                    </CityHeader>
                    <CityContent>
                    <Tabbed 
                            tabs={this.tabs} 
                            refresh={refresh}
                            params={{
                                city: this.state.city,
                                byCity: true
                                }}/>
                    </CityContent>
                </Body>
            </Container>
        );
    }
})

const SliderArea = styled.div`
    width: 100%;
    padding-bottom: 30px;
    overflow: hidden;
`



const CardTempate = class extends React.Component {
    state = {
        data: [],
        show: false
    }
    constructor(props) {
        super(props)
        let url = props.dataURL || props.base
        this.state.data = getCache(url) || []
        console.log(this.state)
    }

    componentDidMount(){
        window.addEventListener('touchstart', this.touchStart);
        window.addEventListener('touchmove', this.preventTouch, {passive: false});
        setTimeout(e => {
            this.setState({show: true})
        }, 20)
        this.refresh()
    }
    
    componentWillUnmount(){
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, {passive: false});
    }
    
    touchStart(e){
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }
    
    preventTouch(e){
        const minValue = 10; // threshold
    
        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;
    
        // Vertical scrolling does not work when you start swiping horizontally.
        if(Math.abs(this.clientX) > minValue){ 
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }

    refresh() {
        let props = this.props
        let url = props.dataURL || props.base
        get(props.dataURL || props.base, {params: {...props.params, limit: 5, ...(this.props.getParams && this.props.getParams() || {})}}).then(res => {
            let data = res.data
            if (props.dataType) {
                data = data.map((v, _) => {return {type: props.dataType, data: v}})
            }
            console.log(data)
            setCache(url, data)
            this.setState({data: data})
        })
    }
    // componentDidMount() {

    // }
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
          };
        let loc = getCache('location') || {updated: 0}
        if (Date.now() - loc.updated < 50) {
            // alert('Refresh!')
            setTimeout(e => this.refresh(), 50)
        }
        return <GroupCard head={this.props.head} base={this.props.base} data={{title: this.props.title}}>
            <SliderArea>
                {/* to prevent blink when comeback */}
                {   this.state.show &&
                    <Slider {...settings}>
                        {
                            this.state.data.map((v, i) => {
                                return <GeneralCard data={v} key={v.data._id} />
                            })
                        }
                    </Slider>
                }
            </SliderArea>
            </GroupCard>
    }
}

const NearCard = props => <CardTempate show={props.show} base='/near' 
                                        title={ props.place.name } 
                                        getParams={() => {return {location: getPlace().location}}}
                                        params={{type: 'place', location: props.place.location}} head='附近' />
const TheCityCard = props => <CardTempate base={`/city`} 
                                        dataURL='/exhibit' 
                                        dataType='exhibit' 
                                        title={props.city}
                                        show={props.show}
                                        getParams={() => {return {city: getCity()}}}
                                        params={{byCity: true, city: props.city}} head='同城' />

const EditorSelect = props => <CardTempate base='/select'
                                           head='编辑精选'
                                           show={props.show}
                                           />

// const TheCityCard = withRouter(class extends React.Component {
//     render() {
//         return <GroupCard head='同城' base='/city' data={{}}>

//         </GroupCard>
//     }
// })

const HotLinkCard = props => <CardTempate base={`/hot`} 
                                        dataURL='/links' 
                                        dataType='link' 
                                        params={{limit: 5}} 
                                        head='热门链接' />
const HotAntiqueCard = props => <CardTempate base={`/hot`} 
                                            head='热门文物'
                                            dataURL='/entry' 
                                            dataType='antique' />

const Main = styled.div`
    width: 100%;
    padding-bottom: 100px;
    height: 100vh;
    font-size: 0.8rem;
    padding-top: 20px;
    /* background-color: white; */
    overflow-x: hidden;
    strong {
        font-size: 1.2rem;
        padding-left: 5px;
        padding-bottom: 10px;
    }
`
const Hot = styled.div`
    width: 100%;
    /* height: 100vh; */
    margin-top: 10px;
    background-color: white;
    /* position: relative; */
    strong {
        font-size: 1.2rem;
        padding-left: 5px;
        padding-bottom: 10px;
    }

`
const ConfigIcon = styled(Icon)`
    padding-left: 20px;
    padding-right: 2px;
`

const FirstPage = withRouter(class extends React.Component {
    state = {
        place: getPlace(),
        city: getCity()
    }
    tabs = [
        // ['热议', props => <Feed
        //     dataType='/hot'
        //     component={GeneralCard}
        //     {...props.params}
        //     params={{type: 'all'}}/>],
        ['展览', props => <Feed dataType='/hot' component={GeneralCard} params={{type: 'exhibit'}}  {...props.params}/>],
        ['文物', props => <Feed
                            dataType='/hot'
                            component={GeneralCard}
                            {...props.params}
                            params={{type: 'antique'}}/>],
        ['博物馆', props => <Feed dataType='/museum' component={Museum} params={{firstPage: true}} {...props.params} />],
        ['链接', props => <Feed dataType='/links' component={LinkCard} params={{}} />]
        ]

    constructor(props) {
        super(props)
        let cache = getCache(this.key())
        if (cache && cache.scrollTop) {
            this.state = cache
        }
        this.relocate()
    }
    key() {
        return `hot/v2/${this.props.location.pathname}`
    }
    componentDidMount() {
        // console.log(this.state, this.refs)
        this.refs.scroller && (this.refs.scroller.scrollTop = this.state.scrollTop || 0);
        // this.setState({showTab: true})
        // setTimeout(e => {console.log(this.refs); this.refs.scroller && (this.refs.scroller.scrollTop = this.state.scrollTop || 0); }, 3)
        this.onScroll() 

    }
    componentWillUnmount() {
        this.state.scrollTop = this.refs.scroller && this.refs.scroller.scrollTop
        this.state.showTab = false
        setCache(this.key(), this.state)
    }
    // componentDidActivate() {
    //     console.log('restore', this.state)
    //     setTimeout(e => {this.refs.scroller.scrollTop = this.state.scrollTop;}, 10);
    //     this.onScroll()
    //     this.state.activated = true
    // }
    // componentWillUnactivate() {
    //     this.state.activated = false
    // }

    onScroll() {
        let listElem = document.getElementById('infList')
        let elem = document.getElementsByClassName('hotTabbed')[0]
        // if (this.state.activated) {
        //     this.state.scrollTop = this.refs.scroller && this.refs.scroller.scrollTop
        // }
        if (!this.refs.scroller) {
            return
        }
        console.log(elem.offsetTop, this.refs.scroller.scrollTop, elem)
        if ((this.refs.scroller.scrollTop - elem.offsetTop) > 0) {
            if (listElem.style.overflowY === 'hidden') {
                listElem.style.overflowY = this.state.listScroll || 0
                listElem.style.overflowY = 'auto'
                elem.style.overflowY = 'hidden'
                listElem.scrollTop = listElem.scrollTop + 1
                listElem.scrollTop = listElem.scrollTop - 1
            }

        } else {
            console.log('hidden')
            if (listElem.style.overflowY !== 'hidden') {
                this.state.listScroll = listElem.scrollTop
                listElem.style.overflowY = 'hidden'
                elem.style.overflowY = 'auto'
            }
        }
        // setInterval(e => this.onScroll(), 20)
    }
    relocate() {
        getPlace(p => {
            setCache('cityName', p.city)
            setCache('place', p)
            this.setState({
                city: p.city,
                place: p
            })
        })

    }
    render() {
        let refresh = getRefresh('home') || this.props.location.refresh;

        let loc = getCache('location') || {updated: 0}
        if (Date.now() - loc.updated < 100) {
            // alert('Refresh!')
            this.relocate()
        }
        return <Container>
        {/* <AddressBar /> */}
        <SearchBox
            right={props => <ConfigIcon onClick={e => this.props.history.push('/tag/meta')} icon={settings} size='20px' />}
            onFocus={e => this
            .props
            .history
            .push('/search')}
            placeholder='搜索 帖子/文物/展览/博物馆 ...'/>

        <Body width='96%' ref='body'>
        <Scroller ref='scroller' 
            onScroll={this.onScroll.bind(this)}
        >

            {/* 编辑精选 */}
            {/* 同城 */}
            <TheCityCard city={this.state.city} />
            {/* 附近 */}
            <NearCard place={this.state.place}/>

            {/* 热门 */}
            {/* <HotLinkCard />
            <HotAntiqueCard /> */}
            {/* <GroupCard head='热门' base='' data={{}}> */}
            <Hot>
            <span><strong>热门</strong></span>
            <Tabbed 
                    className='hotTabbed'
                    backgroundColor='white'
                    tabs={this.tabs} 
                    refresh={refresh}
                    onSwitch={this.onScroll.bind(this)}
                    params={{
                        disableRefresh: true,
                        onScroll: this.onScroll.bind(this)
                        }}/>
            </Hot>


            {/* </GroupCard> */}

        </Scroller>
        </Body>
        <FixedPostIcon bottom='100px' remindOnly={true} />
    </Container>
    }
})

export default FirstPage;
export  {TheCity, TheNear};