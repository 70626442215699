import React, {Fragment, useEffect} from 'react';
import {withRouter} from 'react-router';
import { Link } from 'react-router-dom';
import {Container} from './Common';
import {back, shareNav, get} from './Backend';
import styled from 'styled-components';

// import {Markdown as ReactMarkdown} from './newsprint';

import {Icon} from 'react-icons-kit';
import {share} from 'react-icons-kit/feather/share';
import {arrowLeft as backward} from 'react-icons-kit/feather/arrowLeft';
import {logo} from './Config';
// import {CoverCard} from './CoverCard';
import { FaAngleLeft, FaPlus, 
    FaShare as FaShare,
    FaBookmark,
    FaHeart,
    FaPen,
    FaXing
} from "react-icons/fa";

import {post, getUserInfo} from './Backend';
import {invalidate} from './State';
import { Portal } from 'react-portal';
import {x} from 'react-icons-kit/feather/x';
import {image} from 'react-icons-kit/feather/image';
import { Plugins, CameraResultType, CameraSource } from '@capacitor/core';
// import {toPageSrc} from './CoverCard';
import axios from 'axios';
import FileAPI from 'fileapi';
import {} from 'fileapi/plugins/FileAPI.exif';
import Compress from 'compress.js';
import {Toast} from 'react-weui';
import Swipe from 'react-easy-swipe';
import { Player, syn } from './Speech';

const { Camera } = Plugins
const { Modals } = Plugins;



const Slides = class extends React.Component {
    Container = styled(Container)`
        background-color: rgba(12, 12, 12, 1);
        /* padding-top: 70px; */
        /* padding-bottom: 50px; */
        height: calac(100vh - 100px);
        width: 100%;
        max-width: 100%;
        position: fixed;
        z-index: 1000;

    `
    VideoContainer = styled.div`
        width: 100%;
        max-width: 650px;
        /* height: 100%; */
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
    `
    Exit = styled.div`
        position: absolute;
        right: 10px;
        top: 30px;
        font-size: 30px;
        color: grey;
    `
    state = {
        data: [],
        index: 0
    }
    async loadMore() {
        let params = {
            offset: this.state.data.length,
            limit: 5,
            video: true
        }
        let res = await get('/post', {params})
        console.log(res.data)
        this.setState({data: [...this.state.data, ...res.data]})

    }
    componentDidMount() {
        this.loadMore()
    }
    onSwipeMove(p, e) {
        console.log(p, e)
        if (p.y < -20 || p.x < -20) {
            this.state.toNext = this.state.index + 1
        } else if (p.y > 20 || p.x > 20) {
            this.state.toNext = Math.max(this.state.index - 1, 0)
        }
    }
    onSwipeEnd() {
        this.setState({index: Math.min(this.state.toNext, this.state.data.length - 1)})
        if (this.state.data.length - this.state.toNext < 4) {
            this.loadMore()
        }
    }
    Video = props => {
        let id = `video/${props.src}`
        useEffect(() => {
            let v = document.getElementById(id)
            if (props.display === 'none') {
                v.pause()
            } else {
                v.play()
            }
        })
        return <video 
            id={`video/${props.src}`}
            webkit-playsInline="true" /*这个属性是ios 10中设置可以
                                        让视频在小窗内播放，也就是不是全屏播放*/  
            playsInline="true"  /*IOS微信浏览器支持小窗内播放*/ 
            x-webkit-airplay="allow" 
            width='100%' 
            height='100%' 
            style={{'object-fit': 'cover', display: props.display}}
            // controls
            >
            <source src={`${props.src}`} type={props.type} />
            <source src={`${props.src}`} />
        </video> 
    }

    // videos = {}

    renderVideo(data) {
        let src = data.videos[0].src
        console.log(src)
        return <Fragment>
            {
                this.state.data.map((v, i) => v && v.videos[0] && <this.Video src={v.videos[0].src} type={v.videos[0].type} display={src !== v.videos[0].src && 'none'} />)
            }
            </Fragment>
    }
    Post = styled.div`
        position: absolute;
        bottom: 100px;
        max-height: 200px;
        width: calc(100% - 40px);
        color: grey;
        font-weight: bold;
        margin-left: 20px;
        margin-right: 20px;
        strong {
            font-size: 20px;
            color: white;
        }
    `
    render() {
        let data = this.state.data[this.state.index] || {}
        return <Portal>
            <this.Container>

                
                <this.VideoContainer>
                    <Swipe  onSwipeMove={this.onSwipeMove.bind(this)} onSwipeEnd={this.onSwipeEnd.bind(this)}>
                    {
                        data.videos && this.renderVideo(data)
                    }
                    { this.renderImage && this.renderImage(data)}
                    </Swipe>
                    {/* {data._id && <PostCard data={data}  onlyText /> }                */}
                </this.VideoContainer>
                <this.Post onClick={e => this.props.history.push(`/posts/${data._id}`)}>
                    {
                        data.user && `@${data.user.nickName}: ${data.text}`
                    }
                </this.Post>
                {
                    this.renderPost && this.renderPost(data)
                }
                <this.Exit onClick={e => this.props.onClose && this.props.onClose()}>
                    <Icon icon={x} size='30px' />
                </this.Exit>

                    
            </this.Container>
            </Portal>
    }
}

const toPageSrc = url => url && url.includes('paoying.oss-cn-beijing') && `${url}?x-oss-process=style/page` || url

const ImageSlides = withRouter(class extends Slides {
    Img = styled.img`
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    `
    renderImage(data) {
        return <this.Img src={toPageSrc(data.pic_cover)} />
    }
    renderPost(data) {
        // data.title && Player.playList({
        //     items: syn(`${data.title}\n ${data.desc}`)
        // })
        return <this.Post>
            <strong> {data.title}  </strong>
            <span> {data.desc && `${data.desc.slice(0, 50)} ...` } </span>
            </this.Post>
    }
    async loadMore() {
        let res = await this.props.loadMore(this.state.data.length)
        console.log(res)
        this.setState({data: [...this.state.data, ...res.data]})
    }
    render() {
        return super.render()
    }
})

const VSlides = withRouter(Slides)

export {VSlides, ImageSlides}