import React from 'react';
import {Card, Image, Button as Button_} from 'react-onsenui';

import {Watch} from './Actions';
import styled from 'styled-components';
import {withRouter, Link} from 'react-router-dom';
import ReactTextMoreLess from 'react-text-more-less';
import ReactTextCollapse from 'react-text-collapse';
import { Wiki, Markdown } from './Wiki';
import { get } from './Backend';
import { Plugins } from '@capacitor/core';
import { logo } from './Config';
import Avator from 'react-avatar';
import { FaHeadphones

} from 'react-icons/fa';
import { toPageSrc } from './CoverCard';
import {Player, syn} from './Speech';
import { AppState } from './State';
// import {Button} from './Detail';

const { Browser } = Plugins;

const CardDesc = styled.div `
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: left;
    justify-content: space-between;
    padding-left: 10px;
`;

const MyCard = styled(Card)`
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: row;
    border-radius: 0;
    margin-bottom: 5px;
    height: 110px;
    margin-left: 0;
    margin-right: 0;
    padding-top: 5px;
    /* padding-bottom: 5px; */
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.10);
    /* box-shadow: 0 0 0 0; */
    /* border: 1px solid lightgrey; */
    /* box-shadow: -1px 1px -1px 1px rgba(0, 0, 0, 0.1); */
    /* box-shadow: 1px 1px 1px 1px; */
    /* font-size: 0.5rem; */
    /* z-index: 1; */
`;

const _WikiCard = styled(MyCard)`
    height: 120px;
    overflow: hidden;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    /* height: 1.2em; */
    -webkit-box-orient: vertical;
`
const MarkdownPreview = styled(Markdown)`
    font-size: 0.8rem;
    p {
        font-size: 0.8rem;
    }
    h1, h2, h3, h4 {
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }
    ul {
        margin-bottom: 0.5rem;
    }
`
const CardImg_ = styled.img `
    height: auto;
    width: auto;
    width: 100px;
    max-height: 100px;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
`;


const CardImg = props => <CardImg_ src={props.src && props.src.includes('paoying') && `${props.src}?x-oss-process=style/head` || props.src || logo}/>

const CardWrap = styled.div `
    width: 105px;
    /* align-items: center; */
`

const CardDescTitle = styled.div `
    text-align: left;
    width: 100%;
    height: 30px;
    font-weight: bold;
    font-size: 0.8rem;
    height: 1.2rem;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    /* height: 1.2em; */
    -webkit-box-orient: vertical;
`;

const CardDescLine = styled.div `
    text-align: left;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    font-size: 0.7rem;
    height: 1.2rem;
    /* height: 1.2em; */
    -webkit-box-orient: vertical;
`

const Summary = styled.div `
    text-align: left;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    font-size: 0.7rem;
    /* height: 1.2em; */
    -webkit-box-orient: vertical;
`
const CardDescDetail = styled.div `
    font-size: 0.7em;
    text-align: left;
    width: 100%;
    margin-top: 5px;
    height: 1.8em;
    margin-bottom: 5px;
    /* position: absolute;
    bottom: 5px; */
    /* height: 3em; */
    /* overflow: hidden; */
`;

const CardType = styled.span`
    color: grey;
    font-size: 0.7rem;
    margin-right: 10px;
    /* background-color: lightgrey; */
`
const CardTag = styled(CardType)`
    background-color: grey;
    color: white;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 1px;
    padding-bottom: 1px;
`

const BlockQuote = styled.blockquote`
    color: rgba(244, 244, 244);
    font-size: 14px;
    font-weight: bold;
`

class Expandable extends React.Component {
    state = {
        collapsed: true
    };
    options = {
        collapse: false, // default state when component rendered
        collapseText: '...', // text to show when collapsed
        expandText: '收起', // text to show when expanded
        minHeight: 35, // component height when closed
        maxHeight: 250, // expanded to
        textStyle: { // pass the css for the collapseText and expandText here
            color: "black",
            //          fontSize:
        }

    }
    render() {
        const {collapsed} = this.state;
        return (
            <CardDescDetail>
                <ReactTextCollapse options={this.options}>
                    {this.props.text
}
                </ReactTextCollapse>
            </CardDescDetail>
        );
    }
}

const MuseumLink = styled(Link)`
    background-color: whitesmoke;
    color: lightblue;
`

const QuotePost = class extends React.Component {
    componentDidMount() {
        this.props.onLoad && this.props.onLoad()
    }
    render() {
        let data = this.props.data
        if (this.props.disabled) {
            return <span />
        }
        return <BlockQuote className='quote' onLoad={e => this.props.onLoad && this.props.onLoad()}>
            {data.post && <p>@{data.post.user.nickName}: {data.post.text.substring(0, 30)} ...</p>}
            {!data.post && <p>@多小知： 发表帖子，占领这里~ </p>}
        </BlockQuote>
    }
}

class _Entry extends React.Component {
    onClick(e) {
        console.log(e)
        let pathname = `/antique/${this.props.data.antiqueId || this.props.data.antique_id || this.props.data._id}`

        if (this.props.section) {
            pathname = `${pathname}?section=${this.props.section}`
        }
        this
            .props
            .history
            .push({pathname: pathname, data: this.props.data})
    }
    RightMost = styled.div`
        position: absolute;
        right: 20px;
        top: 0px;
        font-size: 20px;
        color: grey;
    `
    render() {
        let data = AppState.update('antique', this.props.data)
        
        return (
            <MyCard onClick={this.props.onClick || (e => this.onClick(e))}>
                <CardWrap>
                    <CardImg src={this.props.data.pic_cover}/>
                </CardWrap>
                <CardDesc>
                    <CardDescTitle>
                       {this.props.data.title}
                       {this.props.onDelete && <this.RightMost onClick={this.props.onDelete}> × </this.RightMost>}
                    </CardDescTitle>
                    {/* <CardDescLine>
                        {
                                data.tags && data.tags.map((v, i) => {
                                    return <CardTag> {v} </CardTag>
                                })
                        }
                        <CardType>文物</CardType>
                    </CardDescLine> */}
                    {/* <QuotePost data={data} disabled={this.props.inPost}/> */}
                    <CardDescDetail>
                        <CardDescLine>{this.props.data.ageDesc}</CardDescLine>
                        <CardDescLine>{this.props.data.museumName}</CardDescLine>
                        {/* </MuseumLink> */}

                    </CardDescDetail>
                    {/* { this.props.data.desc &&
                            this
                            .props
                            .data
                            .desc
                            // .substring(0, 50) + '...'
                            }
                    </Expandable> */}
                </CardDesc>
            </MyCard>
        )
    }
}

const Distance = props => <CardType> {`${(props.distance/1000).toFixed(1)}km`} </CardType>
class _Museum extends React.Component {
    onClick(e) {
        this
            .props
            .history
            .push(`/museum/${this.props.data.name}`)
    }
    render() {
        let data = AppState.update('museum', this.props.data)
        return (
            <MyCard onClick={e => this.onClick(e)}>
                <CardWrap>
                    <CardImg src={this.props.data.pic_cover}/>
                </CardWrap>
                <CardDesc>
                    <CardDescTitle>
                        {this.props.data.name}
                    </CardDescTitle>
                    {/* <br/> */}
                    {/* <QuotePost data={data} disabled={this.props.inPost}/> */}

                    <CardDescDetail>
                        <CardDescLine />
                        <CardDescLine>
                            {
                                data.tags && data.tags.map((v, i) => {
                                    return <CardTag> {v} </CardTag>
                                })
                            }
                            {data.distance && <Distance distance={data.distance}/>}
                        </CardDescLine>
                    </CardDescDetail>
                </CardDesc>
            </MyCard>
        )
    }
}

const Exhibit = withRouter(class extends React.Component {
    onClick(e) {
        this.props.history.push({
            pathname: `/exhibit/${this.props.data._id}`,
            data: this.props.data
        })
    }
    render() {
        let data = AppState.update('exhibit', this.props.data)
        return (
            <MyCard onClick={e => this.onClick(e)}>
                <CardWrap>
                    <CardImg src={this.props.data.pic_cover}/>
                </CardWrap>

                <CardDesc>
                    <CardDescTitle>
                        {this.props.data.title}
                    </CardDescTitle>
                    {/* <QuotePost data={data} disabled={this.props.inPost}/> */}
                    <CardDescDetail>
                        <CardDescLine>
                        {
                                data.tags && data.tags.map((v, i) => {
                                    return <CardTag> {v} </CardTag>
                                })
                        }
                        {/* <CardType>
                            展览
                        </CardType> */}
                        {this.props.data.museumName}
                        {data.distance && <Distance distance={data.distance}/>}

                        
                        </CardDescLine>
                        <CardDescLine>{this.props.data.fields[0].value}</CardDescLine>
                    </CardDescDetail>
                </CardDesc>
            </MyCard>
        )
    }
})

// 不可移动文物
const Unit = withRouter(class extends React.Component {
    onClick(e) {
        this.props.history.push({
            pathname: `/unit/${this.props.data._id}`,
            data: this.props.data
        })
    }
    render() {
        // let data = this.props.data
        let data = AppState.update('unit', this.props.data)
        console.log(data)
        return (
            <MyCard onClick={e => this.onClick(e)}>
                <CardWrap>
                    <CardImg src={data.pic_cover}/>
                </CardWrap>

                <CardDesc>
                    <CardDescTitle>
                        {this.props.data.title}
                    </CardDescTitle>
                    {/* <QuotePost data={data} disabled={this.props.inPost} /> */}
                    <CardDescDetail>

                        <CardDescLine>
                        {
                            data.tags && data.tags.map((v, i) => {
                                return <CardTag> {v} </CardTag>
                            })
                        }

                        {this.props.data.belongTypeName}
                        {data.distance && <Distance distance={data.distance}/>}
                        </CardDescLine>
                        <CardDescLine>
                            {this.props.data.address}
                        </CardDescLine>
                    </CardDescDetail>
                </CardDesc>
            </MyCard>
        )
    }
})

// 不可移动文物
const BookCard = withRouter(class extends React.Component {
    onClick(e) {
        this.props.history.push({
            pathname: `/book/${this.props.data._id}`,
            data: this.props.data
        })
    }
    render() {
        // let data = this.props.data
        let data = AppState.update('book', this.props.data)
        console.log(data)
        return (
            <MyCard onClick={e => this.onClick(e)}>
                <CardWrap>
                    <CardImg src={data.image}/>
                </CardWrap>

                <CardDesc>
                    <CardDescTitle>
                        {this.props.data.title}
                    </CardDescTitle>
                    {/* <QuotePost data={data} disabled={this.props.inPost} /> */}
                    <CardDescDetail>
                    <CardDescLine> {data.author} </CardDescLine>
                    <CardDescLine> {data.publisher} </CardDescLine>
                    </CardDescDetail>
                </CardDesc>
            </MyCard>
        )
    }
})

const TagCard_ = styled(MyCard)`
    height: 55px;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-bottom: 0px;
    margin-top: 0;
`
const TagTitle = styled(CardDescTitle)`
    font-size: 14px;
    /* height: 30px; */
    margin-bottom: 5px;
`

const Button = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    min-width: 4rem;
    font-size: ${props => props.fontSize || 'small'};
    border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: ${props => props.background || ''};
    /* color: black; */
    align-items: center;
    text-align: center;
`
const TagDesc = styled(CardDesc)`
    width: 100%;
`
const Left = styled.div`
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const TagWrap = styled(CardWrap)`
    height: 45px;
`
const TagImg_ = styled(CardImg_)`
    height: 45px;
    width: 45px;
`
const TagImg = props => <TagImg_ src={props.src && props.src.includes('paoying') && `${props.src}?x-oss-process=style/head` || props.src || logo}/>

const Follow = props => <Button  background='rgba(0, 0, 0, 0.4)' {...props}>关注</Button>
const Followed = props => <Button  background='rgba(255, 255, 255, 0.5)' {...props}>已关注</Button>

const SmallCard = class extends React.Component {
    onClick(e) {
        this.props.history.push({
            pathname: `/tag/${this.props.data.title.substring(1)}`,
            data: this.props.data
        })
    }

    CardType = 'tag'

    getAvator() {
        let data = this.props.data
        return <TagWrap>
                    <TagImg src={data.pic_cover}/>
                </TagWrap>
    }
    getTitle() {
        return this.props.data.title
    }
    render() {
        let data = this.props.data
        return (
            <TagCard_ >
                {this.getAvator()}
                <TagDesc onClick={e => this.onClick(e)}>
                <TagTitle>
                    {this.getTitle()}
                </TagTitle>
                    {/* <CardDescLine>
                    <CardType>
                        话题
                    </CardType>
                    </CardDescLine> */}
                    {/* <CardDescDetail>
                        
                    </CardDescDetail> */}
                    <CardDescLine>{`${data.post_count}帖子  ${data.follow_count}关注`}</CardDescLine>
                </TagDesc>
                <Left>
                <Watch type={this.CardType} object={data._id} 
                        Active={Follow} 
                        Actived={Followed} 
                        />
                </Left>

            </TagCard_>
        )
    }
}

const TagCard = withRouter(SmallCard)

const AvatorIcon = (props) => <Avator round={true} size='40px' {...props}/>

const UserCard = withRouter(class extends SmallCard {
    onClick(e) {
        this.props.history.push({
            pathname: `/user/${this.props.data._id}`,
            data: this.props.data
        })
    }

    CardType = 'user'

    getAvator() {
        let data = this.props.data
        return <AvatorIcon name={data.nickName} />
    }
    getTitle() {
        return this.props.data.nickName
    }
})

// Wiki
const WikiCard = withRouter(class extends React.Component {
    onClick(e) {
        this.props.history.push({
            pathname: `/wiki/page/${this.props.data.title}`,
            data: this.props.data
        })
    }
    render() {
        let data = this.props.data
        return (
            <_WikiCard onClick={e => this.onClick(e)}>
                <MarkdownPreview source={data.digest} />
            </_WikiCard>
        )
    }
})

const LinkCard_ = styled(MyCard)`
    height: auto;
    height: 120px;
`
const VideoCard = styled(MyCard)`
    height: auto;
`

const Iframe = styled.iframe`
    display: none;
`
const LinkCard = withRouter(class extends React.Component {
    state = {

    }
    constructor(props) {
        super(props)
        let data = props.data
        this.state.data = data
        if (!data.title) {
            get('link', {
                params: data
            }).then(res => {
                this.setState({data: res.data})
            })
        }
    }
    onClick() {
        // window.open(this.state.data.src)
        Browser.open({url: this.state.data.src})
    }
    SpeechIcon = styled.div`
        position: absolute;
        right: 10px;
        bottom: 30px;
        z-index: 100;
    `
    speechLink(e) {
        // e.preventDefault()
        get('/link', {
            params: {
                src: this.props.data.src,
                full: true
            }
        }).then(res => {
            console.log(res)
            Player.playList({
                items: syn(this.props.data.title + '。。' + res.data.text)
            })
            Player.setPlaying({
                title: this.props.data.title,
                artist: '多小知'
            })
        })

    }
    renderEmbedVideo(aid) {
        let src = this.props.data.src
        src = `https://player.bilibili.com/player.html?aid=${aid}`
        return <VideoCard onClick={e => this.onClick(e)}>
        <iframe title={'视频'} src={`https://player.bilibili.com/player.html?aid=${aid}`}
        scrolling="no" border="0" frameBorder="no" frameSpacing="0" allowFullScreen={true}> </iframe>

        </VideoCard>
    }
    render() {
        let data = this.state.data
        let src = this.props.data.src
        // https://www.bilibili.com/video/av77668530/
        let r = /https:\/\/www.bilibili.com\/video\/av(\d+)\/?/
        let r1 = /https:\/\/b23.tv\/av(\d+)\/?/
        let m = r.exec(src)
        if (!m) {
            m = r1.exec(src)
        }
        if (m && m[1]) {
            return this.renderEmbedVideo(m[1])
        }
        return (
            <LinkCard_>
                {/* {this.state.craw && <Iframe ref='iframe' onload={this.crawLink.bind(this)} src={data.src} />} */}
                {/* <MarkdownPreview source={data.digest} /> */}
                {!data.title && <CardDesc onClick={e => this.onClick(e)}>{data.src}</CardDesc>}
                {data.title &&  <CardWrap onClick={e => this.onClick(e)}><CardImg src={data.pic_cover}/></CardWrap>}
                {data.title &&  <CardDesc onClick={e => this.onClick(e)}>
                    <CardDescTitle> {data.title} </CardDescTitle>
                    <Summary>
                        {data.summary}
                    </Summary>
                </CardDesc>}
                <this.SpeechIcon  onClick={this.speechLink.bind(this)}>
                    <FaHeadphones size='20px'/>
                </this.SpeechIcon>
            </LinkCard_>
        )
    }
})

// TODO
const SelectCard = withRouter(class extends React.Component {
    render() {
        let data = this.props.data.data
        let type = this.props.data.type
        return (
            <MyCard onClick={e => this.onClick(e)}>
                <CardWrap>
                    <CardImg src={data.pic_cover}/>
                </CardWrap>

                <CardDesc>
                    <CardDescTitle>
                        {this.props.data.title}
                    </CardDescTitle>
                    <CardDescLine>
                    <CardType>
                        {type}
                    </CardType>
                    </CardDescLine>
                    <CardDescDetail>
                        <CardDescLine>{this.props.data.belongTypeName}</CardDescLine>
                        <CardDescLine>{this.props.data.address}</CardDescLine>
                    </CardDescDetail>
                </CardDesc>
            </MyCard>)
    }
})



const Entry = withRouter(_Entry);
const Museum = withRouter(_Museum);
export {Entry, Museum, Expandable, Exhibit, Unit, WikiCard, LinkCard, TagCard, UserCard, QuotePost, BookCard};