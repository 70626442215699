import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import {
    Page,
    Toolbar,
    ToolbarButton,
    Br,
    Card,
    Hr,
    Button
} from 'react-onsenui';
import styled from 'styled-components';
import axios from 'axios';
// import ExpandCollapse from 'react-expand-collapse';
import {plusCircle} from 'react-icons-kit/feather/plusCircle';
import {Icon} from 'react-icons-kit';
import {bookmark} from 'react-icons-kit/feather/bookmark';
import {heart} from 'react-icons-kit/feather/heart';
import {share} from 'react-icons-kit/feather/share';
import {eye} from 'react-icons-kit/feather/eye'
// import {circlePlus} from 'react-icons-kit/feather/plus'
import {StyledToolbar, Container, Content, Body} from './Common.js';
import {Expandable} from './Card.js';
import {Tabbed, Fixed, AvatorIcon, Scroller} from './Common.js';
import {PostList} from './Discuss.js';
import {get, post, shareNav, back, getUserInfo} from './Backend.js';
import {setRefresh} from './State.js';
import { Watch, Bookmark } from './Actions';
import {Gallery} from 'react-weui';
// import { Hr } from '@sumup/circuit-ui';
import {Image, Head, Desc, Row, Key, Value, MetaCard, C, Actions, FixedPostIcon} from './Detail';

let bookmarked = JSON.parse(JSON.stringify(bookmark));
bookmarked.attribs.fill = 'black'

class Antique extends React.Component {
    state = {
        data: {},
        showImages: false
    }
    constructor(props) {
        super(props);
        this.antiqueId = this
            .props
            .location
            .pathname
            .split('/')[2];
        if (!this.props.location.data) {
            get(this.props.location.pathname).then(resp => {
                console.log('data', resp.data.data)
                this.setState({data: resp.data.data})
                console.log(this.state);
            })
        } else {
            this.state.data = this.props.location.data;
            console.log('Data from cache!');
        }
    }
    bookmark() {
        post('bookmark', {
            type: 'antique',
            object: this.antiqueId,
            delete: this.state.data.bookmarked || false
        }).then(res => {
            let data = this.state.data;
            data.bookmarked = !data.bookmarked
            setRefresh('home')
            this.setState({data: data})
        })
    }
    keys = [
        [
            'ageDesc', '年代'
        ],
        [
            'categoryName', '类别'
        ],
        [
            'excavatedPlace', '出土地点'
        ],
        ['museumName', '收藏']
    ]
    tabs = [
        [
            '简介', (props) => <Desc>
                    <br/>
                    <p>{props.params.desc}
                    </p>
                </Desc>
        ],
        ['讨论', PostList]
    ]
    onScroll(e) {
        console.log('Scroll', this.refs.tabbedTop, this.refs.tabbedTop, this.refs.tabbedTop)
        let rect = this
            .refs
            .tabbedTop
            .getBoundingClientRect()
        let actionRect = this
            .refs
            .actions
            .getBoundingClientRect()
        let actionsBottom = actionRect.top + actionRect.height
        console.log(rect, actionsBottom, this.refs.actions.getBoundingClientRect())
        this.setState({
            scrollOut: rect.top <= 50,
            scrollTop: actionsBottom
        })
    }
    share() {
        shareNav(this.state.data.title, this.state.data.title, this.props.location.pathname)
    }
    render() {
        console.log(this.props.location, this.props.location.pathname)
        return (
            <Container color='white'>
                <Gallery src={[this.state.data.picHD || this.state.data.picPreview]} show={this.state.showImages} defaultIndex={this.state.index} onClick={e => this.setState({showImages: false})} touched={e => this.setState({showImages: false})} />
                <StyledToolbar>
                    <div className='left'>
                        <ToolbarButton
                            onClick={e => back(this, '/')}>
                            返回
                        </ToolbarButton>
                    </div>
                    <div className='center'>{this.state.data.title}</div>
                    <div className='right'>
                        <ToolbarButton
                            onClick={this.share.bind(this)}>
                            分享
                        </ToolbarButton>
                    </div>
                </StyledToolbar>
                <Body width="90%">
                    <Scroller
                        onScroll={this
                        .onScroll
                        .bind(this)}>
                        {/* TODO: loading HD */}
                        <Image src={this.state.data.picPreview} onClick={e => this.setState({showImages: true})} />
                        <MetaCard>
                            {this
                                .keys
                                .map((v, i) => {
                                    if (this.state.data[v[0]]) {
                                        return (
                                            <Row key={i}>
                                                <Key>{v[1]}</Key>
                                                <Value>{this.state.data[v[0]]}</Value>
                                            </Row>
                                        )
                                    }
                                })
}
                        </MetaCard>
                        {/* <hr /> */}
                        <div ref='tabbedTop' className='tabbedTop'/>
                        {this.state.data._id && <Actions ref='actions'>
                            <Watch type='antique' object={this.state.data._id} watched={this.state.data.watched} />
                            <Bookmark type='antique' object={this.state.data._id} />

                            <Icon icon={share} onClick={this.share.bind(this)} touched={this.share.bind(this)} size={'15px'}/>
                        </Actions>}
                        <C>
                            {this.state.data._id && <Tabbed
                                tabs={this.tabs}
                                params={{
                                desc: this.state.data.desc,
                                type: 'antique',
                                id: this.state.data.antiqueId,
                                title: this.state.data.title
                            }}/>}
                        </C>
                    </Scroller>

                    {/* <MetaCard> */}
                <FixedPostIcon to={`/post?type=antique&id=${this.antiqueId}&title=${this.state.data.title}`}/>

                </Body>
            </Container>
        )
    }
}

export default withRouter(Antique);