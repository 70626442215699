import React, {Fragment} from 'react';
import styled from 'styled-components';
// import {Container, Toolbar, Editor} from 'react-mobiledoc-editor';
import {Container, StyledToolbar, Body, findAll} from './Common.js';
import {TextArea, Toast, Dialog} from 'react-weui';
import {ToolbarButton} from 'react-onsenui';
import Compress from 'compress.js';
import {post, ensureLogin, getUserInfo} from './Backend.js';
import queryString from 'querystring';
import {withRouter} from 'react-router';
import {setRefresh, getCache, AppState} from './State.js';
import {image} from 'react-icons-kit/feather/image';
import {video} from 'react-icons-kit/feather/video';
import {hash} from 'react-icons-kit/feather/hash';
import {Icon} from 'react-icons-kit';
import {x} from 'react-icons-kit/feather/x';
import {trash2} from 'react-icons-kit/feather/trash2';
import {send} from 'react-icons-kit/feather/send';
// import {link} from 'react-icons-kit/feather/link'
// import {speaker} from 'react-icons-kit/feather/speaker'
import {mic} from 'react-icons-kit/feather/mic'
import {GeneralCard} from './Detail';
import {setCache} from './State';
import FileAPI from 'fileapi';
import {} from 'fileapi/plugins/FileAPI.exif';
import { Player } from 'video-react';
import "video-react/dist/video-react.css";
import axios from 'axios';
import { Portal } from 'react-portal';
import PhotoGallery from "react-photo-gallery";
import { toPageSrc } from './CoverCard';
import {PhotoSwipe as _PhotoSwipe} from 'react-photoswipe';
import { FaLink, FaTrash } from 'react-icons/fa';
import { PostCard } from './Discuss';
import Reorder, {reorder} from 'react-reorder';
import VConsole from 'vconsole';

const PhotoSwipe = styled(_PhotoSwipe)`
    /* margin-top: 30px; */
`


// import Editor from "nib-core";
// import "mobiledoc-kit/dist/css/mobiledoc-kit.css"
// import { WysiwygUI } from '@remirror/editor-wysiwyg';

// https://jpuri.github.io/Nib/#/Introduction

const TitleInput = styled.h1`
    width: 100%;
    border: 0;
`

const ContentInput = styled.textarea `
    width: 100%;
    height: 300px;
    font-size: 1rem;
    border: 0;
    margin-top: 10px;
`
const isStandalone = window.matchMedia('(display-mode: standalone)').matches

// const Editor = styled.div`     width: 95%;     margin-left: auto;
// margin-right: auto;     border-radius: 10px;     background-color: white;
// margin-top: 10px; `

const StyledContainer = styled.div`
    width: 95%;
    /* height: 300px; */
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    margin-top: 20px;
    border-radius: 5px;
`


const ImageInput = styled.input`
    display: none;
    width: 100%;
    height: auto;
`

const imageHeight = '200px';

const PreviewContainer = styled.div`
    width: 200px;
    height: ${imageHeight};
    margin-bottom: 5px;
    margin-left: 10px;
    position: relative;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.5);
`

const Img = styled.img`
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
`

const TrashIcon = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    /* padding-bottom: 10px; */
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px; 
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
    font-size: 10px;
`

const LinkIcon = styled(TrashIcon)`
    /* bottom: 10px; */
    bottom: 20px;
    top: calc(100% - 40px);
`

class ImagePreview extends React.Component {
    constructor(props) {
        super(props)
        this.state = props.state || {};
        console.log('ImagePreview', props, this.state)
    }

    remove() {
        this.props.onRemove && this.props.onRemove()
    }

    shouldComponentUpdate() {
        return false
    }
    onTouchStart(e) {
        console.log(e)
    }
    onTouchMove(e) {
        console.log(e)
    }
    onTouchEnd(e) {

    }
    render() {
        let url = this.state.url || this.state.src
        return (<PreviewContainer
                >
                <TrashIcon onClick={this.remove.bind(this)}>
                    <FaTrash />
                    {/* <Icon icon={trash2} size='100%' onClick={this.remove.bind(this)} touched={this.remove.bind(this)} /> */}
                </TrashIcon>
                <LinkIcon onClick={e => this.props.onLink && this.props.onLink()}>
                    <FaLink />
                </LinkIcon>
                {url.startsWith('data:image') && <Img src={this.state.url || this.state.src} onClick={this.props.onClick}/>}
                {url.startsWith('https://') && <Img src={toPageSrc(this.state.url || this.state.src)} onClick={this.props.onClick}/>}
                {url.startsWith('data:video') && <video width='100%' height='100%' controls><source src={this.state.url} type={this.state.type} /></video>}
            </PreviewContainer>)
    }
}
const Gallery = styled.div`
    /* display: grid; */
    /* grid-template-columns: repeat(auto-fill, 105px); */
    display: flex;
    overflow: auto;
    flex-wrap: nowrap;
    width: 100%;
    /* border: 1px bold lightgrey; */
    padding-top: 5px;
    margin-top: 5px;
    padding-bottom: 5px;
    /* border-top: 1px solid lightgrey; */
    position: relative;
`
const TextWrapper = styled.div`
    width: 100%;
    min-height: ${props => props.minHeight || '100px'};
    max-height: ${props => props.maxHeight || '40vh'};
    margin-top: 10px;
    position: relative;
    /* border-bottom: 1px solid black */
`
const StyledTextArea = styled.textarea`
    width: 100%;
    border: 0;
    height: 100%;
    padding-top: 5px;
    position:absolute;
    top: 0;
    right: 0;
    resize: none;
    /* font-size: medium; */
    /* touch-action: none; */
`
const Pre = styled.div`
    width: 100%;
    max-width: 650px;
    display:block;
    visibility:hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 16px;
    /* font-size: medium; */
    /* padding-top: 5px; */
`
const Space = styled.div`
    display: block;
    width: 100%;
    height: 3rem;
    visibility: hidden;
`
const Actions = styled.div `
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    margin-top: 5px;
    /* border-top: 0.5px solid gray; */
    /* div {
        width: 20px;
    } */
    /* margin-top: 10px; */
`

const Separator = styled.div`
    width: 100%;
    border-bottom: 1px solid gray;
`
const RightMost = styled.div`
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
`

const Tags = styled(Actions)`
    justify-content: left;
`

const Tag = styled.span `
    margin-left: 10px;
    border-radius: 2px;
    padding: 2px 2px 1px 1px;
    /* background-color: lightblue; */
    color: grey;
    font-family: "Hiragino Sans GB";
    font-weight: bold;
    font-size: 0.9rem;
`

const FixedBody = styled(Body)`
    position: fixed;
`
const PostIcon = styled(props => <Icon {...props} size={'20px'}/>)`
    margin-right: 20px;
`
const EditorWrapper = styled.div`
    width: 90%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    position: relative;
`


const EasyEditor = withRouter(class extends React.Component {
    state = {
        images: [],
        counter: 0,
        links: []
    }
    constructor(props) {
        super(props)
        this.state.entities = props.entities || []
        this.state.topics  = props.topics || []
        this.state.text = props.text || ''
        this.state.tags = props.tags || []
        this.state.topics = this.state.tags || []
        this.state.images = props.images || []
        this.state.videos = props.videos || []
        this.state.links = props.links || []
        this.state.retweet = props.retweet
    }

    componentDidMount() {
        this.props.onRef && this.props.onRef(this)
        console.log('mount', this.props)
        if (!this.props.name) {
            return
        }
        let state = getCache(this.props.name);
        console.log(state);
        let files = state && state.images;
        // files = null;
        if (this.state.text) {
            this.refs.textInput.value = this.state.text
            return
        }
        if (state && !state.showToast) {
            this.refs.textInput.value = state.text || '';
            this.setState(state);
        }

    }
    extractTags() {
        let reg = /(#[^\s#\(\)\/]+)[\s]*/g;
        let m = findAll(reg, this.state.text)
        // console.log('Tags', m, m && m[0], m && m[1]);   
        let tags = m.map((v, i) => v[1])
        console.log(tags)
        this.state.tags = [...(this.state.topics || []), ...tags];
        this.state.tags = [...new Set(this.state.tags)];
    }
    extractLinks() {
        let reg = /(http[s]?:\/\/[^\s\)]+)[\s]+/g
        let m = findAll(reg, this.state.text)
        console.log(m)
        let links = m.map((v, i) => v[1])
        this.state.links = links
    }
    onImageSelected(e) {
        console.log(e, e.currentTarget, e.currentTarget.files)
        let files = [...e.currentTarget.files]
        console.log(files)
        const compress = new Compress();
        compress.compress(files, {
            size: 4,
            quality: 0.9,
            maxWidth: 1920,
            maxHeight: 1920,
            resize: true,
        }).then(data => {
            console.log(data);
            let images = data.map((v, i) => {
                this.state.counter += 1
                let o = {url: `${v.prefix}${v.data}`, index: this.state.counter, alt: v.alt, prefix: v.prefix}
                return o
            })
            this.setState({
                images: [...this.state.images, ...images]
            })
            this.state.images.map((o, i) => {
                if (o.src) {
                    return o
                }
                let key = `${getUserInfo()._id}/${o.alt}`
                post('upload', {key: key}).then(res => {
                    console.log(res)
                    FileAPI.getInfo(files[i], (err, info) => {
                        if (!err) {
                            o.info = info
                        }
                    })
    
                    this.upload(this.dataURLtoFile(o.url), res.data).then(res => {
                        console.log('Aliyun:', res)
                        o.src = `https://paoying.oss-cn-beijing.aliyuncs.com/${key}`
                        o.url = o.src
                    })
                })
            })
            // this.props.name && localStorage.setItem(this.props.name, JSON.stringify(this.state));
            // setTimeout(() => this.refs.textInput.click(), 100);
        })

    }
    onVideoSelected(e) {
        let files = [...e.currentTarget.files]
        console.log(files)
        this.videos = files
        let v = files[0]
        let o = {
            url: window.URL.createObjectURL(v),
            type: v.type,
        }

        this.setState({videos: [o]})
    }
    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }
    upload(file, options) {
        const url = 'https://paoying.oss-cn-beijing.aliyuncs.com/';
        const formData = new FormData();
        console.log('Options', options, file)
        for (var v in options) {
            formData.append(v, options[v])
        }
        formData.append('file', file)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return  axios.post(url, formData,config)
    }

    makeVideoData(f) {
        if (this.state.videos.length === 0) {
            f && f()
            return
        }
        let o = this.state.videos[0]
        if (o.src) {
            f && f()
            return
        }
        let v = this.videos[0]
        let key = `${getUserInfo()._id}/${v.name}`
        post('/upload', {key: key}).then(res => {
            console.log(res)
            this.upload(v, res.data).then(res => {
                console.log('Aliyun:', res)
                o.src = `https://paoying.oss-cn-beijing.aliyuncs.com/${key}`
                f && f();
            })
        })
        // let reader = new FileReader()
        // reader.onload = e => {
        //     o.data = e.currentTarget.result


        //     })
        //     // f && f()
        // }
        // reader.readAsDataURL(v)
        // FileAPI.getInfo(v, (err, info) => {
        //     if (!err) {
        //         o.info = info
        //     }
        // })
    }
    onChange(e) {
        this.state.text = e.currentTarget.value;
        this.extractTags()
        this.extractLinks()
        console.log(this.state);
        this.setState({
        })
        
    }
    componentWillUnmount() {
        this.props.name && setCache(this.props.name, this.state);
    }
    triggerImage(times) {
        document.getElementById('imageInput').click();

        // if (isStandalone) {
        //     // trigger touch in PWA
        //     document.getElementById('imageInput').click();
        // }
    }
    clear() {
        this.setState({images: [], topics: [], text: '', links: [], videos: []})
        this.refs.textInput.value = ''
        this.props.name && setCache(this.props.name, null)
        this.props.onClear && this.props.onClear()
    }
    startEdit(text) {
        this.refs.textInput.value = text;
        this.refs.textInput.focus();
    }
    publish() {
        this.setState({
            showLoading: true
        })
        const onPublishSuccess = res => {
            this.clear();
            this.setState({
                showLoading: false,
                showToast: true
            })
            setTimeout(() => this.setState({showToast: false}), 500)
        }
        this.props.onPublish && this.makeVideoData( () => this.props.onPublish(this.state, onPublishSuccess))
        // this.clear()
    }
    renderLinks() {
        console.log(this.state)
        return <Fragment>
            {
                this.state.retweet &&
                <PostCard data={this.state.retweet} noLink={false} card={true}/>
            }
            {
                this.state.entities.map((v, i) => {
                    return <GeneralCard data={{...v, data: getCache(`${v.type}-${v.id || v._id}`)}} />
                })
            }
            {
                this.state.links.map((link, i) => {
                    return <GeneralCard data={{type: 'link', data: {src: link}}} />
                })
            }
        
        </Fragment>
    }

    renderGallery() {
        let urls = this.state.images.map((v, i) => {
            let info = v.info || {}
            let exif = info.exif || {}

            // for iPhone picture
            let doTrans = exif.Orientation === 6

            let width = info.width || 1920
            let height = info.height || 1920
            return {
                    src: `${v.url}`, 
                    w:  doTrans && height || width,
                    h:  doTrans && width || height,
                }
        })
        let options = {
            index: this.state.clickIndex,
            escKey: true,
            // ui option
            timeToIdle: 4000,
            tapToClose: true,
            clickToCloseNonZoomable: true,
        }
        const Wrap = styled(Portal)`
        `
        return <Wrap>{ this.state.showImages && <PhotoSwipe isOpen={this.state.showImages}
                                            items={urls}
                                            options={options}
                                            onClose={e => this.closeGalley() || <div />} 
                    />}</Wrap>
    }
    
    closeGalley() {
        this.setState({showImages: false})
    }
    recordVoice() {

        let p = navigator.mediaDevices.getUserMedia({
            audio: true
        })
        p.then(res => {
            console.log(res)
            let recorder = new MediaRecorder(res)
            this.state.recorder = recorder
            recorder.start()
            this.setState({showRecorder: true})
            // recorder.stop()
            console.log('Stop')
            recorder.ondataavailable = e => {
                console.log(e)
            }
        })
    }
    Recorder = styled.div`
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);

    `
    renderRecorder() {
        const stop = e => {
            this.state.recorder.stop()
            this.setState({showRecorder: false})
        }
        return this.state.showRecorder && <Portal>
                <this.Recorder>
                    <div onClick={stop}>停止</div>
                </this.Recorder>
            </Portal>
    }
    onLink(v) {
        let f = this.insertAtCaret.bind(this.refs.textInput)
        f(`![](${v.src})`)
        // this.onChange()
    }
    insertAtCaret = function (text) {
        text = text || '';
        if (document.selection) {
          // IE
          this.focus();
          var sel = document.selection.createRange();
          sel.text = text;
        } else if (this.selectionStart || this.selectionStart === 0) {
          // Others
          var startPos = this.selectionStart;
          var endPos = this.selectionEnd;
          this.value = this.value.substring(0, startPos) +
            text +
            this.value.substring(endPos, this.value.length);
          this.selectionStart = startPos + text.length;
          this.selectionEnd = startPos + text.length;
        } else {
          this.value += text;
        }
    }
    onReorder(event, previousIndex, nextIndex, fromId, toId) {
        this.setState({
            images: reorder(this.state.images, previousIndex, nextIndex)
          });
    }
    Reorder = styled(Reorder)`
        display: flex;
        flex-wrap: nowrap;
        .dragged {
            width: 170px;
            height: 170px;
            img {
                width: 170px;
                height: 170px;
            }
        }
    `
    renderImageList() {
        let imgs = (this.state.images || []).map((v, i) => {
            return <div style={{position: 'relative', 'margin-right': '5px'}}>
                <TrashIcon onClick={e => this.state.images.splice(i, 1) && this.setState({})}>
                    <FaTrash />
                    {/* <Icon icon={trash2} size='100%' onClick={this.remove.bind(this)} touched={this.remove.bind(this)} /> */}
                </TrashIcon>
                <LinkIcon onClick={e => this.onLink(v)}>
                    <FaLink />
                </LinkIcon>
                <Img src={v.url || v.src} onClick={e => this.setState({showImages: true, clickIndex: i})} />
                </div>
            // return <ImagePreview 
            //         onLink={e => this.onLink(v)}
            //         onRemove={e => this.state.images.splice(i, 1) && this.setState({})} 
            //         onClick={e => this.setState({showImages: true, clickIndex: i})}
            //         state={this.state.images[i]} key={v.index || i} />
         })
        console.log(imgs)
        return <this.Reorder
            reorderId="image-list" // Unique ID that is used internally to track this list (required)
            // getRef={this.storeRef.bind(this)} // Function that is passed a reference to the root node when mounted (optional)
            lock="vertical" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
            holdTime={300} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
            touchHoldTime={300} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
            mouseHoldTime={200} // Hold time before dragging begins with mouse (optional), defaults to holdTime
            onReorder={this.onReorder.bind(this)} // Callback when an item is dropped (you will need this to update your state)
            autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
            disabled={false} // Disable reordering (optional), defaults to false
            disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
        >
        {
                            
            imgs
        

        }
        </this.Reorder>

    }
    render() {
        return (<EditorWrapper>

                {this.renderGallery()}
                {this.renderRecorder()}
                <Toast icon="success-no-circle" show={this.state.showToast}>发布成功</Toast>
                {/* <Dialog type="ios" title='在输入框里面粘贴链接'
                        buttons={[
                            {
                                label: '好的',
                                onClick: e => this.setState({showLinkPromt: false})
                            }
                        ]} 
                        show={this.state.showLinkPromt}>
                        <textarea />
                </Dialog> */}
                <Toast icon="loading" show={this.state.showLoading}>发布中...</Toast>
                <ImageInput id='imageInput' ref='imageInput' type='file' accept="image/*" multiple onChange={this.onImageSelected.bind(this)} />
                <ImageInput id='videoInput' ref='videoInput' type='file' accept="video/*"  onChange={this.onVideoSelected.bind(this)} />
                {/* <ImageInput id='audioInput' ref='audioInput' type='file' accept="audio/*"  onChange={this.onVideoSelected.bind(this)} /> */}
                <TextWrapper minHeight={this.props.minHeight} maxHeight={this.props.maxHeight}>
                    <StyledTextArea 
                        placeholder={this.props.placeholder || '分享你的见解'}  
                        autoFocus={this.props.autofocus} ref="textInput" 
                        onChange={this.onChange.bind(this)} />
                    {/* {this.state.text && <Space />} */}
                    <Pre> {this.state.text || ''} </Pre>
                </TextWrapper>
                <Separator />
                <Actions>
                    <PostIcon icon={x} onClick={this.clear.bind(this)} /> 
                    <RightMost>
                        {(this.state.videos || []).length === 0 && <PostIcon icon={image} 
                                            onClick={e => this.triggerImage()} 
                                            />}
                        {(this.state.images || []).length === 0 && (this.state.videos || []).length === 0 && <PostIcon icon={video} onClick={e => document.getElementById('videoInput').click()} />}
                        {/* <PostIcon icon={mic} onClick={this.recordVoice.bind(this)}/> */}

                        {/* <PostIcon icon={hash} onClick={e => this.props.history.push('/entities')}/>  */}
                        {/* <PostIcon icon={link} onClick={e => this.setState({showLinkPromt: true})}/> */}
                        <Tag />
                        <Tag />
                        <PostIcon id='sendIcon' icon={send} onClick={this.publish.bind(this)}  />
                    </RightMost>
                </Actions>
                {/* <GridGallery images={this.state.images} /> */}
                <Tags>
                    {this.state.tags.length > 0 && <strong>话题: </strong>}
                    {
                        this.state.tags.map((t, i) => {
                            return <Tag key={t}>{t}</Tag>
                        })
                    }
                </Tags>
                {
                    this.state.videos.map((v, i) => {
                        return <video width='100%' height='40%'  controls>
                            {/* <source src={v.url || v.src} /> 
                            <source src={v.url || v.src} type={v.type} autoplay/> */}
                            <source src={`${v.url || v.src}#t=0.1`} />
                            <source src={`${v.url || v.src}#t=0.1`} type={v.type}/>
                            <source src={`${v.url || v.src}`} type={v.type}/>
                        </video>
                    })
                }
                <Gallery>

                { this.renderImageList() }
                {/* <div css={{"touch-action": "none"}} onClick={e => document.getElementById('imageInput').click()}> ttttttest </div> */}
                </Gallery>
                {this.renderLinks()}
            </EditorWrapper>)
    }
})

class PostEditor extends React.Component {
    state = {
        images: [],
        topics: [],
        tags: [],
        counter: 0
    }
    constructor(props) {
        super(props);
        let params = queryString.parse(props.location.search.substring(1));
        let data = props.location.refer || {}
        let retweet = props.location.retweet || {}
        console.log('params:', params, data)
        this.state.name = `doPost/${retweet._id || ''}`
        if (data.type) {
            this.state.name = `doPost/${data.type}-${data.id}-${data.title}`
        }
        if (data.type && data.type !== 'tag') {
            // this.state.topics = [`#(${params.type}:${params.id})${params.title}`];
            this.state.entities = [{type: data.type, id: data.id || data._id, title: data.title}]
            setCache(`${data.type}-${data.id || data._id}`, data)
        } else if (data.type === 'tag'){
            this.state.tags = [data.id]
        }

        let modify = this.props.location.data
        if (modify) {
            this.state = {...this.state, ...modify}
        }
    }

    async publish(data, onSuccess) {
        console.log('publish', data);
        if (this.props.location.data && this.props.location.data._id) {
            data._id =  this.props.location.data._id
        }
        if (data.retweet) {
            data.retweet = {
                ...data.retweet,
                ph: undefined
            }
        }
        let resp
        try {
            resp = await post('post', data)
        } catch(e) {
            console.log(e)
            return this.props.history.goBack()
        }
        console.log(resp)
        // localStorage.removeItem('doPost')
        setCache('doPost', null)
        setRefresh('post')
        setRefresh('home')
        onSuccess && onSuccess()
        if (data._id) {
            AppState.invalidate('post', data.post_id || data._id)
            try {
                await AppState.get('post', data.post_id || data._id)
            } catch(e) {
                console.log(e)
            }
        }
        this.props.history.goBack()
    }
    componentDidMount() {
        ensureLogin()
    }
    Body = styled(Body)`
        position: relative;
    `
    render() {
        console.log(this.state.images)
        return (
            <Container>
                <StyledToolbar>
                    <div className='left'>
                        <ToolbarButton onClick={e => this.props.history.goBack()}> 返回 </ToolbarButton>
                    </div>
                    <div className='center'>{}</div>
                    <div className='right'>
                        <ToolbarButton onClick={e => document.getElementById('sendIcon').click()} > 发布 </ToolbarButton>
                    </div>

                </StyledToolbar>
                <this.Body>
                <EasyEditor ref='ee' 
                            onPublish={this.publish.bind(this)} name={this.state.name}
                            text={this.props.location.data && this.props.location.data.text}
                            images={this.props.location.data && this.props.location.data.images}
                            videos={this.props.location.data && this.props.location.data.videos}
                            entities={(this.state.entities || []).filter(o => o.type !== 'link')}
                            topics={this.state.topics}
                            links={this.state.links}
                            retweet={this.props.location.retweet}
                            tags={this.state.tags} />
                {/* <input /> */}
                </this.Body>    
            </Container>
        )
    }
}

export default withRouter(PostEditor);
export {EasyEditor};