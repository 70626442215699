import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import styled from 'styled-components';
import {
    Page,
    Toolbar,
    ToolbarButton,
    Br,
    Card,
    Hr,
    Button as Button_
} from 'react-onsenui';
import {Entry, Museum, Exhibit, Unit, WikiCard, LinkCard, TagCard, UserCard, BookCard} from './Card';
import {PostCard} from './Discuss';
import { getCache, setCache } from './State';
import {mapPin} from 'react-icons-kit/feather/mapPin';
import {chevronDown} from 'react-icons-kit/feather/chevronDown';
import {settings} from 'react-icons-kit/feather/settings';
// import {search} from 'react-icons-kit/fa/search'
import {search} from 'react-icons-kit/feather/search';
import {video} from 'react-icons-kit/feather/video';
import {headphones} from 'react-icons-kit/feather/headphones';
import {Icon} from 'react-icons-kit';
import {Fixed, AvatorIcon, SearchBox} from './Common';
import {getUserInfo, get} from './Backend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaSearch as FaSearch, FaPen, FaVideo, FaVideoSlash, FaHeadphones } from 'react-icons/fa';
import axios from 'axios';
import { VSlides } from './Douyin';
import { MdSearch } from 'react-icons/md';


const Image = styled.img `
max-width: 100%;
max-height: 60vh;
margin-left: auto;
margin-right: auto;
margin-bottom: 20px;
`

const Desc = styled.div `
width: 100%;
height: 100%;
margin-left: auto;
margin-right: auto;
margin-bottom: 20px;
padding-bottom: 40px;
margin-top: 10px;
font-size: 0.8rem;
overflow: auto;
`

const Row = styled.div `
display: flex;
flex-direction: row;
font-size: 0.8rem;
`
const Key = styled.div `
font-weight: bold;
width: 5rem;
`

const Value = styled.div `
width: 100%;
`
let maxWidth = '650px';

const Top = styled.div `
top: 0;
position: fixed;
height: 30px;
`

const MetaCard = styled(Card)`
    border-radius: 0;
    width: 100%;
    box-shadow: 0 0;
`
const InputLine = styled.input `
    width: 100%;
    height: 40px;
    border: 0px;
    border-radius: 40px;
    margin-bottom: 100px;    
`

const Actions = styled.div `
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 5px;
    /* margin-left: 50px;
    margin-right: 50px; */
`

const RightMost = styled.div`
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
`

const LeftMost = styled.div`
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
`

const Head = styled.span `
font-size: 1.2rem;
font-weight: bold;
`
const C = styled.div `
width: 100%;
margin-top: 10px;
/* min-height: 100vh; */
/* display: none; */
`
const C1 = styled(C)`
position: fixed;
top: ${props => props.scrollTop};
height: 100vh - ${props => props.scrollTop};
`


const GeneralCard = props => {
    let type = props.data.type
    if (!props.data.data) {
        // Data Err!
        return <div />
    }
    else if (props.data.type === 'antique') {
        return <Entry data={props.data.data} inPost={props.inPost} />
    } else if (type === 'post' || type === 'comment') {
        return <PostCard data={props.data.data} inPost={props.inPost} />
    } else if (type === 'exhibit') {
        return <Exhibit data={props.data.data} inPost={props.inPost} />
    } else if (type === 'museum') {
        return <Museum data={props.data.data} inPost={props.inPost} />
    } else if (type === 'unit') {
        return <Unit data={props.data.data} inPost={props.inPost} />
    } else if (type === 'wiki') {
        return <WikiCard data={props.data.data} inPost={props.inPost} />
    } else if (type === 'link') {
        return <LinkCard data={props.data.data} inPost={props.inPost} />
    } else if (type === 'tag') {
        return <TagCard data={props.data.data} inPost={props.inPost} />
    } else if (type === 'user') {
        console.log(props)
        return <UserCard data={props.data.data} inPost={props.inPost} />
    } else if (type === 'book') {
        console.log(props)
        return <BookCard data={props.data.data} inPost={props.inPost} />
    } 
    else {
        return <div />
    }
}


const Tags = styled.div`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    /* padding-left: 100px; */
    /* padding-right: 100px; */
    /* color: lightgrey; */
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: space-between;
    font-size: small;
`

const Tag = styled.div`
    color: ${props => props.actived && 'green' || 'lightgrey'};
`
const copy = o => JSON.parse(JSON.stringify(o))
const TagSelect = withRouter(class  extends React.Component {
    state = {
    }
    constructor(props) {
        super(props)
        console.log('TagSelect', props)

        // if (props.headerState) {
        //     this.state = props.headerState
        // }
        this.state.actived = this.state.actived || props.defaultActived
        let s = getCache(this.props.uniqueKey)
        if (s) {
            this.state = s
        }
    }

    componentDidMount() {
        let i = this.state.actived
        if (true) {
            this.props.onChange && this.props.onChange(this.props.paramKey, this.props.keys && this.props.keys[i] || this.props.tags[i], this.state)
        }
        setCache(this.props.uniqueKey, this.state)
    }

    set(i) {
        this.state.actived = i
        this.props.onChange && this.props.onChange(this.props.paramKey, this.props.keys && this.props.keys[i] || this.props.tags[i], copy(this.state))
        setCache(this.props.uniqueKey, {actived: i})
        this.setState({})
    }
    render() {
        return <Tags>
            {
                this.props.tags.map((t, i) => {
                    return <Tag onClick={e => this.set(i)} touched={e => this.set(i)} key={i} actived={this.state.actived === i}> {t} </Tag>
                })
            }
            </Tags>
    }
})

// const Button_ = styled(Button)`
//     width: 6rem;
// `

const Pen = styled.div`
    width: 50px;
    height: 50px;
    background-color: green;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: space-around;
`
const FixedPostIcon = withRouter(props => {
    if (getUserInfo() && getUserInfo().nickName) {
       return !props.remindOnly && <Fixed bottom={props.bottom || '160px'}>

           <Link to={props.to || `/post`}>
               {/* <AvatorIcon name='帖'/> */}
               <Pen>
               <FaPen color='white' size='25px' />
               </Pen>
           </Link>
       </Fixed>
   } else
   {
        return  !props.noRemind && <Fixed bottom={props.bottom} left='calc(50% - 3rem)' right=''>
               <Button fontSize='16px' onClick={e => props.history.push('/login')}>{props.buttonText || '登录/注册'}</Button>
        </Fixed>
   }
})


const Cards = styled.div `
    display: flex;
    flex-direction: column;
    width: 100%;
`

const BarContainer = styled.div`
    position: relative;
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    /* strong {
        font-size: 25px;
    } */
    /* border-bottom: 1px solid black; */
`

const Place = styled.div`
    /* position: absolute;
    left: 55px;
    bottom: 5px; */
    flex-shrink: 0;
    align-items: baseline;
    display: flex;
    /* align-items: bottom; */
    font-size: 14px;
    margin-left: 10px;
    /* p {
        margin-left: 5px;
        padding-top: 2px;
    } */
`
const AddressBar = withRouter(class extends React.Component {
    state = {
        city: getCache('cityName') || '...'
    }
    gdLocate() {
        let key = 'ed46be8ff64d4a40fae74e6bcbb3fe5c'
        axios.get('https://restapi.amap.com/v3/ip', {params: {key: key}}).then(res => {
            console.log(res)
            if (this.state.city === '...') {
                setCache('cityName', res.data.city)
                this.setState({
                    city: res.data.city
                })
            }
            let rect = res.data.rectangle
        })
    }
    componentDidMount() {
        // this.gdLocate()
        this.gdLocate()
        console.log(this.state)
    }
    Tip = styled.div`
        font-size: small;
        color: grey;
        font-weight: normal;
        margin-left: 10px;
    `
    Head = styled.div`
        display: flex;
        flex-direction: row;
        align-items: baseline;
        flex-shrink: 0;
        margin-right: 10px;
        font-size: 25px;
        color: rgba(12, 12, 12);
    `
    Icons = styled.div`
        display: flex;
        /* max-width: 60%; */
        /* flex-grow: 1; */
        aling-items: center;
        span {
            margin-left: 25px;
        }
        /* margin-left: 10px; */
        /* margin-right: 10px; */
    `
    renderHead() {
        return <this.Head>
        <strong>
        {this.props.title || '多知'}
        </strong>
        {this.props.tip && <this.Tip> {this.props.tip} </this.Tip>}
        { this.props.showCity &&
        <Place onClick={e => this.props.history.push('/city/select')} touched={e => this.props.history.push('/city/select')}>
            {/* <Icon tag='span' icon={mapPin} size='15px' />
            <span>
            <strong>城市：</strong>
            </span> */}
            <span> {this.state.city} </span>
            <Icon tag='span' icon={chevronDown} size='15px' />
        </Place>
        }
        </this.Head>
    }
    render() {
        this.props.onChange && this.props.onChange(this.state)
        return <BarContainer> 
            {this.renderHead()}
            {this.state.showVV && <VSlides onClose={e => this.setState({showVV: false})}/> }
            
            {/* <span> {this.props.title} </span> */}
            {/* <Icon icon={video} tag='span' size='25px'  
                onClick={e => this.setState({showVV: true})}/> */}
            {/* <FaSearch size='20px' spin={true} onClick={e => this.props.history.push('/search')}/> */}
            {/* <SearchBox 
                onFocus={e => this
                    .props
                    .history
                    .push('/search')}
                placeholder='王羲之'
            /> */}

            <this.Icons>
            <Icon icon={video} tag='span' size='23px'  
                onClick={e => this.setState({showVV: true})}/>
            <Icon icon={search} tag='span' size='25px'  onClick={e => this.props.history.push('/search')}/>
            {/* <Icon icon={headphones} tag='span' size='23px'  onClick={e => this.props.history.push('/search')}/> */}
            </this.Icons>
            {/* <Icon icon={search} tag='span' size='25px'  onClick={e => this.props.history.push('/search')}/> */}
            {/* <Icon icon={headphones} tag='span' size='25px'  onClick={e => this.props.history.push('/search')}/> */}
            {/* <FaHeadphones size='25px' /> */}
            
        </BarContainer>
    }
})

const Button = styled(Button_)`
    width: ${props => props.width};
    height: ${props => props.height};
    min-width: 4rem;
    font-size: ${props => props.fontSize || 'small'};
    border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: ${props => props.background || 'green'};
    /* color: black; */
    align-items: center;
    text-align: center;
`

export {
    Image,
    Head,
    C,
    C1,
    Actions,
    InputLine,
    MetaCard,
    Row,
    Key,
    Value,
    Desc,
    GeneralCard,
    TagSelect,
    RightMost,
    LeftMost,
    AddressBar,
    FixedPostIcon,
    Button
}