import React from 'react';
import {withRouter} from 'react-router';
import {Container, StyledToolbar, Body, Scroller, End} from './Common';
import {back, shareNav, get} from './Backend';
import {
    Page,
    Toolbar,
    ToolbarButton,
    Card,
} from 'react-onsenui';
import renderHTML from 'react-render-html';
import {
    Image,
    MetaCard,
    Row,
    Key,
    Value,
    Desc,
    Actions,
    C,
    FixedPostIcon,
    GeneralCard,
    TagSelect,
    RightMost
} from './Detail';
import {Watch, Bookmark, ShareIcon} from './Actions';
import {Tabbed, Feed} from './Common';
import styled from 'styled-components';
import {PostList} from './Discuss';
import {Entry, Exhibit as ExhibitCard, UserCard} from './Card';
// import {Markdown as ReactMarkdown} from './newsprint';
import ReactMarkdown from 'react-markdown';
import { getCache, setCache } from './State';
import {Wiki} from './Wiki';
import {Gallery} from 'react-weui';
import {Button} from './Detail';
import {Icon} from 'react-icons-kit';
import {share} from 'react-icons-kit/feather/share';
import {arrowLeft as backward} from 'react-icons-kit/feather/arrowLeft';
import {logo} from './Config';
import { FaAngleLeft, FaShare } from 'react-icons/fa';
import { InfinitList } from './Basics';
// import './md/old-themes/newsprint.css';

const EDesc = styled.div `
    /* height: 100vh; */
    padding-bottom: 40vh;
    /* overflow-y: auto; */
    img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }
    a {
        pointer-events: none;
        cursor: default;
        text-decoration: none;
        color: black;
    }
`

const Title = styled.div `
    width: 60%;
`

const EntityBase = class extends React.Component {
    type = 'exhibit'

    state = {
        showImages: false
    }

    share() {
        shareNav(this.state.data.title, this.state.data.title, this.props.location.pathname)
    }

    constructor(props) {
        super(props)
        this.state.data = this.props.location.data

        let cache = getCache(this.key())
        if (cache && cache.data) {
            this.state = cache
            this.collectImages()
        }

        // Timeout
        get(this.props.location.pathname).then(res => {
            console.log('Get', res)
            this.setState({data: res.data})
            this.collectImages()
        })

    }
    collectImages() {
        if (this.state.data && !this.state.data.images) {
            let data = this.state.data
            this.state.data.images = this.state.data.images || [
                data.pic_cover
            ]
        }
    }
    key() {
        return `detail/v2/${this.props.location.pathname}`
    }
    componentDidMount() {
        console.log(this.state)
        if (this.state.scrollTop && this.state.scrollTop !== 0) {
            setTimeout(e => {this.refs.scroller.scrollTop = this.state.scrollTop;}, 3);
        }
    }
    componentWillUnmount() {
        this.state.scrollTop = this.refs.scroller && this.refs.scroller.scrollTop
        setCache(this.key(), this.state)
    }

    wikiTab = ['百科', Wiki]

    onScroll() {
        // let listElem = document.getElementById('infList')
        let elem = document.getElementsByClassName('entryTabbed')[0]
        let listElem = document.getElementById('infList')
        if (!listElem) {
            listElem = elem.getElementsByTagName('div')[1]
        }
        console.log(elem.offsetTop, this.refs.scroller.scrollTop, listElem)
        if ((this.refs.scroller.scrollTop - elem.offsetTop) > 0) {

            if (listElem.style.overflowY === 'hidden') {
                listElem.style.overflowY = this.state.listScroll || 0
                listElem.style.overflowY = 'auto'
                elem.style.overflowY = 'hidden'
                listElem.scrollTop = listElem.scrollTop + 1
            }

        } else {
            console.log('hidden')
            if (listElem.style.overflowY !== 'hidden') {
                this.state.listScroll = listElem.scrollTop
                listElem.style.overflowY = 'hidden'
                // elem.style.overflowY = 'auto'
            }
        }
    }

    renderContent() {
        let data = this.state.data
        return <Container color='white'>
        <Gallery src={this.state.data.images}
                    show={this.state.showImages} defaultIndex={this.state.index} 
                    onClick={e => this.setState({showImages: false})} touched={e => this.setState({showImages: false})} />
        <StyledToolbar>
            <div className='left'>
                <ToolbarButton onClick={e => back(this, '/')}>
                    返回
                </ToolbarButton>
            </div>
            <div className='center'>{this.state.data.title}</div>
            <div className='right'>
                <ToolbarButton
                    onClick={this
                    .share
                    .bind(this)}>
                    分享
                </ToolbarButton>
            </div>
        </StyledToolbar>
        <Body width='90%'>
            <Scroller ref='scroller' onScroll={this.onScroll.bind(this)}>
                <Image src={data.pic_cover} onClick={e => this.setState({showImages: true})} />

                {/* <div>

                </div> */}

                <strong>{data.title}</strong>
                {/* <div> 
                    <Button> 关注 </Button>
                    <span /> 
                    <Button> 收藏 </Button>
                </div> */}
                <MetaCard>
                    {/* <Row>
                        <Key>标题</Key>
                        <Value>
                            <strong>{data.title}</strong>
                        </Value>
                    </Row> */}
                    {data
                        .fields
                        .map((v, i) => <Row>
                            <Key>{v.key}</Key>
                            <Value>{v.value}</Value>
                        </Row>)}

                </MetaCard>
                <Actions>
                    {/* <Button> 关注 </Button>
                    <Button> 收藏 </Button> */}
                    <span />
                    <Watch type={this.type} object={data._id} withLabel={true} />
                    <Bookmark type={this.type} object={data._id} withLabel={true} />
                    <span />
                    {/* <ShareIcon title={data.title} text={data.title}/> */}
                </Actions>
                <C>
                    {data._id && <Tabbed
                        className='entryTabbed'
                        tabs={this.tabs}
                        onSwitch={this.onScroll.bind(this)}
                        params={{
                        id: data.id || data._id,
                        type: this.type,
                        title: data.title,
                        desc: data.description,
                    }}/>}
                </C>
                <FixedPostIcon
                    to={{
                        pathname: `/post`,
                        refer: {...data, type: this.type}
                        }}/> {/* {renderHTML(data.description)} */}
            </Scroller>
        </Body>
    </Container>
    }
    render() {
        let data = this.state.data
        console.log(data)
        return this.state.data && this.renderContent() || <div/>
    }
}

let statusBarHeight = '35px';
const HeadCard = styled(MetaCard)`
    background: linear-gradient(to bottom, rgba(255,255,255, 0.2) 0%, rgba(255,255,255, 0.5)), url(${props => `${props.pic_cover || logo}?x-oss-process=style/page`});
    /* background: linear-gradient(to top, white, transparent); */
    background-repeat:repeat-x;
    /* background-color: transparent; */
    width: 100%;
    height: 20vh;
    margin: 0 0 0 0;
    background-size: cover;
    position: fixed;
    top: 0;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    color: black;
`
const HeaderInCard = styled.div`
    position: absolute;
    width: 100%;
    z-index: 10;
    /* top: ${statusBarHeight}; */
    top: 0;
    left: 0;
    height: 2rem;
    .right {
        position: absolute;
        right: 10px;
        top: 5px;
    }
    .left {
        position: absolute;
        left: 10px;
        top: 5px;
    }
`

const TopicDesc = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-between;
    font-size: small;
    top: calc(60%);
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 0));
`

const Main = styled.div`
    width: 100%;
    position: fixed;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    top: 20vh;
    height: calc(100vh);
    /* top: calc(150px); */
`

const Right = styled.div`
    padding-top: 7px;
    width: 30%;
    height: 100%;
    /* display: flex;
    flex-direction: column; */
`
const Left = styled.div`
    width: 66%;
    padding-left: 5px;
    display: flex;
    flex-direction: column;
    strong {
        font-size: 1.5rem;
    }
    span {
        margin-right: 10px;
    }
`
const Follow = props => <Button  background='rgba(0, 0, 0, 0.4)' {...props}>关注</Button>
const Followed = props => <Button  background='rgba(255, 255, 255, 0.2)' {...props}>已关注</Button>

const TagPage = withRouter(class extends EntityBase {
    type = 'tag'

    UserList = InfinitList('user')

    tabs = [
        ['讨论', PostList],
        ['关注', props => <this.UserList cacheKey={`tag.${props.params.id}.User`}
                            loadMore={this.loadMoreUser.bind(this)}
                            itemComponent={UserCard} />]
    ]
    loadMoreUser(offset) {
        return get('/following', {
            params: {
                offset: offset,
                limit: 10,
                object: this.state.data._id,
                type: 'tag'
            }
        })
    }
    renderContent() {
        let data = this.state.data
        return <Container color='white'>
            <HeaderInCard>
                <span onClick={e => back(this, '/')} className='left'> 
                {/* {`<`} */}
                    {/* <Icon icon={backward} /> */}
                    <FaAngleLeft />
                    </span>
                    
                <span/>
                <span onClick={e => this.share()} className='right'>
                    {/* <Icon icon={share} /> */}
                    <FaShare />
                </span>
            </HeaderInCard>
            <HeadCard pic_cover={data.pic_cover}>
                <TopicDesc>
                    <Left>
                        <span>
                            <strong>{data.title}</strong>                         
                            {/* {`  ${data.follow_count} 关注` } */}

                        </span>
                        <span> {`@${data.creator.nickName} ${data.time_desc}`}</span>
                        
                    </Left>
                    <Right>
                        <Watch type='tag' object={data._id} 
                        Active={Follow} 
                        Actived={Followed} 
                        />

                    </Right>
                </TopicDesc>
            </HeadCard>
            <Main>
            {data._id && <Tabbed
                        tabs={this.tabs}
                        params={{
                        id: data.id || data._id,
                        type: this.type,
                        title: data.title,
                        desc: data.description,
                    }}/>}
            </Main>
                <FixedPostIcon
                    to={{
                        pathname: `/post`,
                        refer: {...data, type: this.type}
                        }}/> {/* {renderHTML(data.description)} */}
            </Container>
    }
})
const Exhibit = withRouter(class extends EntityBase {
    type = 'exhibit'
    tabs = [
        // ['描述', props => <iframe src={`https://m.icity.ly/s/ime/${props.id}`} />]
        // ['描述', props => <Scroller dangerouslySetInnerHTML={{__html:
        // props.params.desc}}></Scroller>]
        [
            '描述', props => <Scroller className='halfScrollable'>
                    {/* <Title> {props.params.title} </Title> */}
                    {/* <br /> */}
                    <EDesc
                        dangerouslySetInnerHTML={{
                        __html: props.params.desc
                    }}/>
                    <End/>
                </Scroller>
        ],
        ['讨论', PostList]
    ]
})

const ExhibitListHeader = props => <TagSelect
    defaultActived={0}
    {...props}
    tags={['最新', '常设', '即将结束', '已结束']}
    paramKey='order'
    keys={['new', 'regular', 'end_soon', 'ended']}/>

const ExhibitList = withRouter(props => <Feed
    dataType='exhibit'
    component={ExhibitCard}
    {...props}
    header={ExhibitListHeader}/>)

const MuseumPage = withRouter(class extends EntityBase {
    type = 'museum'
    baikeEleName = 'baikeIframe'
    tabs = [
        // ['描述', props => <iframe src={`https://m.icity.ly/s/ime/${props.id}`} />]
        // ['描述', props => <Scroller dangerouslySetInnerHTML={{__html:
        // props.params.desc}}></Scroller>]
        [
            '描述', props => <Scroller>
                    {/* <Title> {props.params.title} </Title> */}
                    {/* <br /> */}
                    {/* <iframe id={'baikeIframe'} src={props.params.link} onLoad={this.onIframeLoad.bind(this)}/> */}
                    <EDesc
                        dangerouslySetInnerHTML={{
                        __html: props.params.desc
                    }}/>
                    <End/>
                </Scroller>
        ],
        [
            '讨论', PostList
        ],
        [
            '展览', ExhibitList
        ],
        [
            '展品', props => <Feed
                    dataType='entry'
                    component={Entry}
                    params={{
                    museum: props.params.title
                }}/>
        ],
        this.wikiTab
    ]
})

const AntiquePage = withRouter(class extends EntityBase {
    type='antique'
    tabs = [
        [
            '简介', props => <Scroller>
                    {/* <Title> {props.params.title} </Title> */}
                    {/* <br /> */}
                    {/* <iframe id={'baikeIframe'} src={props.params.link} onLoad={this.onIframeLoad.bind(this)}/> */}
                    <EDesc
                        dangerouslySetInnerHTML={{
                        __html: props.params.desc || '暂时没有数据'
                    }}/>
                    <End/>
                </Scroller>
        ],
        this.wikiTab,
        [
            '讨论', PostList
        ],

    ]
})

const UnitPage = withRouter(class extends EntityBase {
    type = 'unit'
    tabs = [
        // [
        //     '描述', props => <Scroller>
        //             {/* <Title> {props.params.title} </Title> */}
        //             {/* <br /> */}
        //             {/* <iframe id={'baikeIframe'} src={props.params.link} onLoad={this.onIframeLoad.bind(this)}/> */}
        //             <EDesc
        //                 dangerouslySetInnerHTML={{
        //                 __html: props.params.desc
        //             }}/>
        //             <End/>
        //         </Scroller>
        // ],
        this.wikiTab,
        ['讨论', PostList]
    ]
})

export {Exhibit, MuseumPage, ExhibitList, UnitPage, AntiquePage, TagPage}