import React, {Fragment} from 'react';
import {withRouter} from 'react-router';
import { Link } from 'react-router-dom';
import {Container} from './Common';
import {back, shareNav, get} from './Backend';
import styled from 'styled-components';

// import {Markdown as ReactMarkdown} from './newsprint';

import {Icon} from 'react-icons-kit';
import {share} from 'react-icons-kit/feather/share';
import {arrowLeft as backward} from 'react-icons-kit/feather/arrowLeft';
import {logo} from './Config';
import {CoverCard} from './CoverCard';
import { FaAngleLeft, FaPlus, 
    FaShare as FaShare,
    FaBookmark,
    FaHeart,
    FaPen
} from "react-icons/fa";

import {post, getUserInfo} from './Backend';

import { Portal } from 'react-portal';
import {x} from 'react-icons-kit/feather/x';
import {image} from 'react-icons-kit/feather/image';
import { Plugins, CameraResultType, CameraSource } from '@capacitor/core';
import {toPageSrc} from './CoverCard';
const { Camera } = Plugins
const { Modals } = Plugins;


let r = document.getElementById('root')

const FavState = new class {
    state = {}
    sync() {
        get('/fav/item').then(res => {
            console.log(res)
            this.state.items = res.data
        })
    }
    isBookMarked(type, object, by) {
        let items = this.state.items || []
        // console.log(items, type, object)
        for (var i in items) {
            let v = items[i]
            if (v.type === type && v.object === object) {
                if (!by) {
                    return true
                } else {
                    if (by === v.fav_id) {
                        return true
                    }
                    continue
                }
               
            }
        }
        return false
    }
}()

setTimeout(e => FavState.sync(), 3000)

const FavPromt = class extends React.Component {
    Container = styled(Container)`
        background-color: rgba(0, 0, 0, 0.2);
    `
    Promt = styled.div`
        width: 100%;
        height: ${props => props.height || 'auto'};
        min-height: 45vh;
        position: fixed;
        max-width: 650px;
        margin-left: auto;
        margin-right: auto;
        bottom: 0;
        left: ${r.offsetLeft}px;
        background-color: white;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        z-index: 1000;
        box-shadow: 0 0 5px gray;
        /* z-index: 10; */
    `
    Bar = styled.div`
        /* width: 100%; */
        padding-left: 20px;
        padding-right: 20px;
        height: 40px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid lightgrey;

    `
    Add = styled.div`
        font-weight: bold;
        font-size: 16px;
        color: green;        
    `
    Middle = styled.div`
        flex-grow: 1;
    `
    state = {
        favList: []
    }
    Title = styled.div`
        font-weight: bold;
        font-size: 16px;
        /* color: green;         */
    `
    Desc = styled.div`
        font-size: 12px;
        padding-left: 20px;
    `
    FavDesc = styled.div`
        align-items: baseline;
        display: flex;
        padding-left: 20px;
    `
    doFav(o) {
        console.log(o)
        post('/fav/item', {
            fav: o,
            type: this.props.type,
            object: this.props.object
        }).then(res => {
            console.log(res.data)
            this.setState({
                Success: true,
                favItem: res.data
            })
        })
    }
    renderFavItem(o) {
        return <this.Bar key={o._id} onClick={e => this.doFav(o)}>
            {o.cover && <this.Head src={toPageSrc(o.cover)} />}
            {!o.cover && <Icon icon={image} size='20px' /> }
            <this.FavDesc>

            <this.Title>{o.name}</this.Title>
            <this.Desc>{`${o.count || 0}收藏  ${o.follow || 0}关注`}</this.Desc>
            </this.FavDesc>
            <this.Middle />
            <this.FavDesc>  
                {FavState.isBookMarked(this.props.type, this.props.object, o._id) && '✓' 	}
            </this.FavDesc>
            </this.Bar>
    }
    Success = styled.div`
        font-size: 16px;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* font-weight: bold; */
        align-items: center;
        width: auto;
    `
    Input = styled.textarea`
        margin-left: auto;
        margin-right: auto;
        height: 100px;
        width: calc(80% - 40px);
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        /* border: 1px solid lightgrey; */
        border: 0;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 30px;
    `
    Sure = styled.div`
        width: 80%;
        height: 30px;
        font-size: 16px;
        text-align: center;
        background-color: ${props => props.color || 'green'};
        color: white;
        border-radius: 10px;
        /* margin-bottom: 50px; */
    `
    Center = styled.div`
        width: 100%;
        /* position: relative; */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: ${props => props.height || 'auto'};
        /* border: 1px solid green; */
    `
    onSuccess() {
        let desc = this.refs.input.value
        if (!desc) {
            console.log('desc')
            this.close()
            return
        }
        post('/fav/item', {
            ...this.state.favItem,
            description: desc
        }).then(res => {
            console.log(res)
            this.close()
        })
    }
    close() {
        this.setState({showCreate: false})
        this.props.onClose && this.props.onClose()
        FavState.sync()
    }

    FavNameInput = styled.input`
        width: calc(80% - 40px);
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 20px;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        border: 0;
        height: 40px;
        color: black;
        margin-bottom: 20px;
    `
    CoverBar = styled(this.Bar)`
        width: calc(80% - 40px);
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 30px;
        margin-top: 20px;
    `
    async selectCover() {
        let options = {
            quality: 90,
            // allowEditing: true,
            height: 720,
            width: 720,
            resultType: CameraResultType.DataUrl,
            source: CameraSource.Prompt
          }
        let res = await Camera.getPhoto(options)
        console.log(res)
        this.setState({
            cover: res.dataUrl
        })
    }
    createFav() {
        post('/fav', {
            cover: this.state.cover,
            name: this.refs.title.value,
            description: this.refs.input.value
        }).then(res => {
            console.log(res)
            this.close()
            this.init()
        })
    }
    Head = styled.img`
        width: 20px;
        height: 20px;
        object-fit: cover;
        border-radius: 4px;
    `
    init() {
        if (!((this.props.params || {}).user_id) && !getUserInfo()) {
            return Modals.alert({
                title: '请先登录',
                message: '请先登录'
            })
        }
        let params = this.props.params || {}
        get('/fav', {
            params: {
                user_id: params.user_id || getUserInfo()._id
            }
        }).then(res => {
            this.setState({favList: res.data})
        })
    }
    constructor(props) {
        super(props)
        FavState.sync()
        this.init()
    }
    async onDelete(e) {
        let confirmRet = await Modals.confirm({
            title: '确认',
            message: '您确定要删除这个收藏夹吗？'
          });
        confirmRet.value && this.deleteFav() && this.init()
    }
    renderCreate() {
        return this.state.showCreate && <this.Promt height='80vh'>
        <this.Bar>
            <Icon icon={x} size='20px' onClick={e => this.setState({showCreate: false})} />
            <this.Middle />
            <this.Add onClick={e => this.createFav()}> 好了 </this.Add>
        </this.Bar>

        <this.Center height='auto'>
            <this.CoverBar onClick={this.selectCover.bind(this)}>
                {!this.state.cover && <Icon icon={image} size='20px' />}
                {this.state.cover && <this.Head src={this.state.cover} />}
                <this.Middle />
                <this.Add> 选择封面 > </this.Add>
            </this.CoverBar>
            <this.FavNameInput defaultValue={this.state.title} ref='title' placeholder='给你的收藏夹取个名字' />
            <this.Input defaultValue={this.state.description} ref='input' placeholder={'描述一下收藏夹(可选)'} />
            {!this.state.modifyFav && <this.Sure onClick={this.createFav.bind(this)} > 好了 </this.Sure>}
            {this.state.modifyFav && <this.Sure color='red' onClick={this.onDelete.bind(this)} > 删除 </this.Sure>}
        </this.Center>
        </this.Promt>
    }
    render() {
        return <Portal>
            {
                !this.state.Success && <this.Promt>
                <this.Bar>
                    <Icon icon={x} size='20px' onClick={e => this.close()} />
                    <this.Middle />
                    <this.Add onClick={e => this.setState({showCreate: true})}> + 创建收藏夹 </this.Add>
                </this.Bar>
                {/* {this.renderFavItem({
                    name: '我的收藏'
                })} */}
                {
                    this.state.favList.map((v, i) => this.renderFavItem(v))
                }
                </this.Promt>
            }
            {
                this.state.Success && <this.Promt>
                    <this.Success> 收藏成功 </this.Success>
                    <this.Center>
                        <this.Input ref='input' placeholder={this.state.favItem.description || '说说收藏的理由，给自己备忘(可选)'} />
                        <this.Sure onClick={this.onSuccess.bind(this)} > 好了 </this.Sure>
                    </this.Center>
                </this.Promt>
            }
            {
                this.renderCreate()
            }
        </Portal>
    }
}

const FavList = withRouter(class extends FavPromt {

    state = {
        favList: []
    }
    doFav(o) {
        console.log(o)
        this.props.history.push({pathname: `/fav/${o._id}`, data: o})
    }
    render() {
        let isMe = getUserInfo() && (getUserInfo()._id === (this.props.params.user_id))
        return <Container>
                <this.Bar>
                    {/* <Icon icon={x} size='20px' onClick={e => this.close()} /> */}
                    <span> {this.state.favList.length} 个收藏夹 </span>
                    <this.Middle />
                    {isMe && <this.Add onClick={e => this.setState({showCreate: true})}> + 创建收藏夹 </this.Add>}
                </this.Bar>
                {/* {this.renderFavItem({
                    name: '我的收藏'
                })} */}
                {
                    this.state.favList.map((v, i) => this.renderFavItem(v))
                }
                {
                    this.renderCreate()
                }
            </Container>
    }
})



export {FavPromt, FavList, FavState}