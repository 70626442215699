import React, {Fragment} from 'react';
import {withRouter} from 'react-router';
import { Link } from 'react-router-dom';
import {Container, SearchBox} from './Common';
import {back, shareNav, get} from './Backend';
import styled from 'styled-components';

// import {Markdown as ReactMarkdown} from './newsprint';

import {Icon} from 'react-icons-kit';
import {share} from 'react-icons-kit/feather/share';
import {arrowLeft as backward} from 'react-icons-kit/feather/arrowLeft';
import {logo} from './Config';
import {CoverCard} from './CoverCard';
import { FaAngleLeft, FaPlus, 
    FaShare as FaShare,
    FaBookmark,
    FaHeart,
    FaPen
} from "react-icons/fa";

import {post, getUserInfo} from './Backend';

import { Portal } from 'react-portal';
import {x} from 'react-icons-kit/feather/x';
import {image} from 'react-icons-kit/feather/image';
import { Plugins, CameraResultType, CameraSource } from '@capacitor/core';
import {toPageSrc} from './CoverCard';
import { FavPromt } from './Fav';
import { Bar } from './Basics';
import axios from 'axios';
import FileAPI from 'fileapi';
import {} from 'fileapi/plugins/FileAPI.exif';
import { InfinitList } from './Basics';
import { Entry } from './Card';

const { Camera } = Plugins
const { Modals } = Plugins;


let r = document.getElementById('root')




const SearchPromt = withRouter(class extends FavPromt {
    SearchResult = InfinitList('result')
    onChange(e) {
        console.log(e.target.value)
        this.search(e.target.value)
    }
    search(w) {
        if (w !== this.state.lastSearched) {
            get(`/search/${encodeURI(w)}`, {
                params: {
                    type: 'antique',
                    limit: 10
                }
            }).then(res => {
                console.log(res)
                this.setState({result: res.data, lastSearched: w})
            })
            this.setState({lastSearched: w})
        }
    }

    results = {}

    renderResult() {

    }
    Results = styled.div`
        margin-top: 20px;
        height: 80vh;
        overflow: auto;
    `
    Div = styled.div`
        height: 30vh;
    `
    select(data) {
        post('/exhibit_entry', {
            exhibit_id: this.props.exhibitId,
            antique_id: data._id
        }).then(res => {
            this.props.onClose()
        })
    }
    render() {
        let to = {
            pathname: '/itemModify',
            exhibit_id: this.props.exhibitId,
            extra: {
                type: 'antique'
            }
        }
        return <Portal>

            <this.Promt height='90vh'>
                <this.Bar>
                    <Icon icon={x} size='20px' onClick={e => this.props.onClose()} />
                    <this.Middle />
                    <this.Add onClick={e => this.props.history.push(to)}> + 创建文物条目 </this.Add>
                </this.Bar>
                <SearchBox placeholder='搜索文物' onChange={e => this.onChange(e)}/>
                <this.Results>
                {
                    (this.state.result || []).map((v, i) => <Entry onClick={e => this.select(v.data)} key={v.data._id} data={v.data} />)
                }
                <this.Div />
                </this.Results>
            </this.Promt>
        </Portal>
    }
})

export { SearchPromt }