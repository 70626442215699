import React, {Fragment} from 'react';

import {Watch} from './Actions';
import styled from 'styled-components';
import {withRouter, Link} from 'react-router-dom';
import { Wiki, Markdown } from './Wiki';
import { get } from './Backend';
import { Plugins } from '@capacitor/core';
import { logo } from './Config';
import Avator from 'react-avatar';
import { Colors, Card } from "@blueprintjs/core";
import {getCache, setCache} from './State';
import { getCity, getLoc } from './City';
import { FaHeadphones, FaPauseCircle, 
    FaForward,
    FaBackward,
    FaPlay,
    FaPause

} from 'react-icons/fa';

import { toPageSrc } from './CoverCard';
// import 'semantic-ui-css/semantic.min.css';
// import {Button} from './Detail';



function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

window.addEventListener('playEnd', () => {
    console.log('PlayEndd')
})

class AudioPlayer {
    current = null
    currentIndex = 0
    playList(call) {
        this.current && this.current.pause()
        this.audios = call.items.map((v, i) => {
            let audio = new Audio()
            console.log(audio)
            audio.src = v.src
            audio.load()
            return audio
        })
        this.current = this.audios[0]
        this.currentIndex = 0
        this.play()
    }
    triggerEvent(name) {
        var event; // The custom event that will be created
        if(document.createEvent){
            event = document.createEvent("HTMLEvents");
            event.initEvent(name, true, true);
            event.eventName = name;
            window.dispatchEvent(event);
        } else {
            event = document.createEventObject();
            event.eventName = name;
            event.eventType = name;
            window.fireEvent("on" + event.eventType, event);
        }
    }
    play() {
        if (!this.current) {
            return
        }
        this.current.onended = e => {
            this.triggerEvent('playEnd')
            if (this.current === this.audios[this.audios.length - 1]) {
                this.triggerEvent('playAllEnd')
            } else {
                this.currentIndex += 1
                this.current = this.audios[this.currentIndex]
                this.play()
            }
        }
        // this.current.onpause = e => {
        //     this.triggerEvent('playPaused')
        // }
        this.current.onplaying = e => {
            this.triggerEvent('playResumed')
        }
        console.log(this.current)
        this.current && this.current.play().catch(e => {
            console.log(e)
            this.triggerEvent('playNext')
        })
    }
    pausePlay() {
        this.current && this.current.pause()
    }
    resumePlay() {
        this.play()
    }
    setPlaying(info) {
        this.info = info
        return new Promise(r => r() )
    }
}


var Player = Plugins.Audio

if (!Player) {
    Player = Plugins.IosSwipeBack
    if (!Player) {
        Player = new AudioPlayer()
    }
}
Player.setPlaying({}).then(res => {
    console.log(res)
}).catch(e => {
    // this.setState({disabled: true})    
    Player = new AudioPlayer()
})
const syn = (input) => {
    // 进行语音合成
    let text = input.replace('#', '').replace(/\(https?:\/\/.*\)/, '')
    let sections = text.split('。').map((v, i) => encodeURI(v)).filter(t => t.length > 2)
    let audios = sections.map((s, i) => {
        let ss = s
        let audio = {}
        audio.src = `https://fanyi.baidu.com/gettts?lan=zh&text=${ss}`
        return audio
    })
    return audios
}
class Radio extends React.Component {
    state = {
        data: [],
        index: 0,
        hearing: false
    }

    loadMore() {
        let params = {
            offset: this.state.data.length,
            limit: 10,
            location: getLoc(),
            city: getCity(),
            type: 'unit'
        }
        console.log(params)
        get('radio', {
            params: params
        }).then(res => {
            console.log('RadioData:', res)
            this.state.data = [...this.state.data, ...res.data]
        }).catch(e => {
            console.log('Error:', e)
        })
    }
    playNext() {
        console.log('Next')
        if (!this.state.hearing) {
            return
        }
        let item = this.state.data[this.state.index] || {data: {}}
        console.log(item, this.state)
        Player.setPlaying({
            title: item['data']['title'],
            artist: '多知电台'
        })
        this.state.index += 1
        if (this.state.index > (this.state.data.length - 3)) {
            this.loadMore()
        }
        Player.playList({
            items: syn(item.data.wiki || '')
        })
    }
    componentWillUnmount() {
        // setCache('radio', this.state)
    }
    componentDidMount() {
        if (Player) {
            try {
                Player.setPlaying({}).then(res => {
                    console.log(res)
                }).catch(e => {
                    // this.setState({disabled: true})    
                    Player = new AudioPlayer()
                    this.setState({})
                })
            } catch (e){
                console.log('Error:', e)
                this.setState({disabled: true})
            }
        }
        window.addEventListener('playAllEnd', e => {
            console.log('AlllllEND')
            this.playNext()
        })
        window.addEventListener('playNext', e => {
            this.playNext()
        })
        window.addEventListener('playPrevious', e => {
            this.state.index = Math.max(0, this.state.index - 2)
            this.playNext()
        })
        window.addEventListener('playPaused', e => {
            console.log(e)
            this.setState({hearing: false, started: true})
        })
        window.addEventListener('playResumed', e => {
            this.setState({hearing: true, started: true})
        })
    }
    stop() {
        console.log('停止收听')
        Player.pausePlay()
        this.setState({hearing: !this.state.hearing, started: true})
    }
    start() {
        // new VConsole()
        console.log('开始收听')
        this.setState({hearing: !this.state.hearing})
        if (this.state.started) {
            Player.resumePlay()
            return
        }
        let welcome = '您好，欢迎收听多知电台。'
        Player.playList({
            items: syn(welcome)    
        })
        this.loadMore()
        
    }

    Container = styled.div`
        position: fixed;
        left: 80%;
        bottom: 20%;
        z-index: 100;
        /* background-color: rgba(0, 0, 0, 0.1); */
    `
    renderPlay() {
        return <this.Container> <FaHeadphones size='30px' color='white'  onClick={this.start.bind(this)} /></this.Container> 
    }
    renderPlaying() {
        return <this.Container><FaPauseCircle size='30px' color='white'  onClick={this.stop.bind(this)} /> </this.Container> 
    }
    render() {
        if (this.props.card) {
            return this.renderCard()
        }
        console.log(Player, this.state)
        return Player && !this.state.disabled && <Fragment>
            {!this.state.hearing && this.renderPlay()}
            {this.state.hearing && this.renderPlaying()}
            </Fragment>
        || <Fragment />
    }

    Card = styled.div`
        width: 100%;
        height: 70px;
        border-radius: 5px;
        position: relative;
        /* box-shadow: 0px 0px 5px grey; */
        background-image: url(${props => props.image});
        background-size: cover;
        display: flex;
        justify-content: space-between;
    `
    Actions = styled.div`
        width: calc(100% - 60px);
        height: 30px;
        margin-left: 30px;
        margin-right: 30px;
        position: absolute;
        bottom: 10px;
        display: flex;
        justify-content: space-between;
        
    `
    Cover = styled.div`
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.3);
    `
    renderCard() {
        return <this.Card>
                {/* <this.Cover> */}
                    <this.Actions>
                        <FaBackward />
                        <FaPlay />
                        <FaForward />
                    </this.Actions>
                {/* </this.Cover> */}
            </this.Card>
    }
}

export {Radio, Player, syn}