import ReactMarkdown from 'react-markdown';
import { getCache, setCache } from './State';
import React from 'react';
import {withRouter} from 'react-router';
import {Container, StyledToolbar, Body, Scroller, End} from './Common';
import {back, shareNav, get} from './Backend';
import {
    Page,
    Toolbar,
    ToolbarButton,
    Br,
    Card,
    Hr
} from 'react-onsenui';
import styled from 'styled-components';

const Markdown = styled(ReactMarkdown)`
padding-bottom: 30vh;
width: 100%;
padding-left: 0;
padding-right: 0;
overflow: auto;
/* font-size: 0.9rem; */
h1, h2, h3, h4, h5, h6 {
    font-size: bold;
    font-size: 1.1rem;
}
h1 {
    font-size: 1.3rem;
    margin-bottom: 2rem;
}
h2 {
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 0.7rem;
}
p {
    margin-bottom: 1rem;
}
li {
    margin-bottom: 0.7rem;
}

blockquote {
    border-color: #bababa;
    color: #656565;
    font-style: italic;
    border-left: 5px solid;
    /* margin-left: 2em; */
    padding-left: 1em;
}

pre, code {
    font-family: 'Monaco';
    font-size: 1rem;
}

code {
    background-color: lightgrey;
    border-radius: 5px;
    padding-left: 2px;
    padding-right: 2px;
}

a {
    font-style: italic;
    font-size: 0.9rem;
}
`
const MarkdownPreview = styled(Markdown)`
    font-size: 1rem;
    padding-bottom: 0;
    /* line-height: 1.2rem; */
    p {
        /* font-size: 0.8rem; */
        margin-bottom: 0.5rem;
    }
    h1, h2, h3, h4 {
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }
    ul {
        margin-bottom: 0.5rem;
    }
`

const Wiki = withRouter(class extends React.Component {
    state = {}
    constructor(props) {
        super(props)
        let title = props.title || props.params.title
        this.title = title
        this.state[title] = getCache(title)

    }
    checkData() {
        let title = this.props.title || this.props.params.title
        let data = this.props.data || {}
        if (!data.content && !this.state[title]) {
            get(`wiki/${title}`).then(res => {
                setCache(title, res.data.content)
                this.state[title] = res.data.content || ' '
                this.setState({})
            })
        }
    }
    render() {
        let title = this.props.title || this.props.params.title
        console.log(this.props)
        let data = this.props.data || {}
        this.checkData()
        return <MarkdownPreview source={data.content || this.state[title]} />
    }
})

const WikiPage = withRouter(class extends React.Component {
state = {}
share() {
    shareNav(this.title, this.title, this.props.location.pathname)
}
constructor(props) {
    super(props)
}

render() {
    console.log(this.props, this.state);
    let items = this.props.location.pathname.split('/')
    let title = items[items.length - 1]
    this.state.title = title;
    this.title = title;
    return <Container color='white'>
        <StyledToolbar>
            <div className='left'>
                <ToolbarButton onClick={e => back(this, '/')}>
                    返回
                </ToolbarButton>
            </div>
            <div className='center'>维基百科: {this.state.title}</div>
            <div className='right'>
                <ToolbarButton
                    onClick={this
                    .share
                    .bind(this)}>
                    分享
                </ToolbarButton>
            </div>
        </StyledToolbar>
        <Body width='90%'>
            <Wiki title={this.state.title}/>
        </Body>
        </Container>
}
})

export {Markdown, WikiPage, Wiki, MarkdownPreview}
