import React from 'react';
import styled from 'styled-components';
import {Card} from 'react-onsenui';
import {withRouter, Link} from 'react-router-dom';

const Group = styled(Card)`
    display: flex;
    position: relative;
    flex-direction: column;
    /* align-items: center; */
    width: 100%;
    height: 200px;
    min-height: 200px;
    border-radius: 0;
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: white;
    box-shadow: 0 0 0 0 ;
    border: 1px solid whitesmoke;
`

const GroupHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    /* width: 100%; */
    /* border-bottom: 1px solid lightgrey; */
    strong {
        font-size: 1.2rem;
        color: black;
        vertical-align: bottom;
    }
    span {
        color: lightgrey;
        font-size: 0.8rem;
        vertical-align: bottom;
    }
`

const GroupCard = withRouter(class extends React.Component {
    render() {
        let v = this.props.data
        let to = `${this.props.base}`
        console.log(to)
        return <Group>
        <GroupHeader>
            <span><strong> {this.props.head}</strong> {`  ${v.title}`} </span>
            <Link to={to}>
                <span>查看全部 > </span>
            </Link>
        </GroupHeader>
        {this.props.children}
    </Group>
    }
})

export {GroupCard}