import React, {Fragment} from 'react';
import {SearchBox, Tabbed, Feed, Content, Container, Body, Scroller} from './Common.js';
import {Entry, Museum} from './Card.js';
import { Page, Card } from 'react-onsenui';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import {Icon} from 'react-icons-kit';
import {home} from 'react-icons-kit/feather/home';
import {mapPin} from 'react-icons-kit/feather/mapPin';
import {setRefresh, setCache, getCache} from './State';
import { get } from './Backend';
import axios from 'axios';

const CityContainer = styled.p`
    font-size: medium;
    /* margin-left: 10px; */
    margin-right: 10px;
    cursor: pointer;
    /* margin-bottom: 10px; */
`
const City = withRouter(props => {
    let setCity = name => {
        setCache('cityName', name)
        setRefresh('home')
        props.history.goBack()
    }

    return <CityContainer onClick={e => {setCity(props.name); props.history.replace(`/city/${props.name}`)}}> {props.name} </CityContainer>
})

const _Card = styled(Card)`
    display: flex;
    width: calc(100% - 10px);
    flex-direction: column;
    margin-left: 5px;
    margin-right: 5px;
    /* align-items: center; */
    justify-content: space-between;
    border-radius: 0;
`
const CityLine = styled(_Card)`
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 5px;
    padding-top: 5px;
    /* margin-left: 30px; */
    /* margin-right: 30px; */
    height: 2rem;
`
const Flex = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const Cities = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 4rem);
    grid-template-rows: repeat(5, 2rem);
`

let key = 'ed46be8ff64d4a40fae74e6bcbb3fe5c'


const locateCity = (callback) => {
    if (!getCache('location')) {
        axios.get('https://restapi.amap.com/v3/ip', {params: {key: key}}).then(res => {
            console.log(res)
            setCache('cityName', res.data.city)

            let rect = res.data.rectangle
            callback && callback(res.data.city)
        })
    } else {
        getPlace(p => {
            callback && callback(p.city)
        })
    }
}

const CitySelect = withRouter(class extends React.Component {
    state = {
        city: getCache('cityName') || '...'
    }
    componentDidMount() {
        if (this.state.city === '...') {
            locateCity(city => {
                setCache('cityName', city)
                this.setState({city: city})
            })
        }
    }
    cities = [
        '北京', '西安', '上海', '广州', '杭州', '天津', '南京', '石家庄', '太原', '郑州', 
        '洛阳', '武汉', '长沙', '南昌', '合肥', '苏州', '银川', '兰州', '拉萨',
        '西宁', '成都', '重庆', '昆明', '贵阳', '南宁', '福州', '厦门', '沈阳', '哈尔滨', '长春',
        // '呼和浩特', '乌鲁木齐'
    ]
    locate() {
        if (navigator.geolocation) {
            console.log('location')
            navigator.geolocation.getCurrentPosition(pos => {
                // locate to a city
                console.log(pos)
            }, err => {
                console.log(err)
            })
        }
    }

    doSearch() {
        let q = this.state.q
        if (q !== this.state.completed) {
            get('city', {params: {'q': q}}).then(res => {
                this.setState({
                    completed: q,
                    cities: res.data
                })
            })
        }

    }
    onChange(e) {
        console.log('Onchange', e.target.value)
        this.setState({q: e.target.value})
        setTimeout(e => this.doSearch(), 300)
    }
    renderDefault() {
        return <Fragment>
            <br />
            <_Card>
                <Flex>
                    <div>
                        <strong> 当前定位 </strong> 
                    </div>
                    <City name={this.state.city} />
                    
                    <span onClick={e => locateCity(city => {
                                          setCache('cityName', city)
                                          this.setState({city: city})
                                        })}>重新定位</span>
                </Flex>
            </_Card>
            <_Card>
                <strong> 热门 </strong>
                <br />
                <Cities>
                    {
                        this.cities.map((v, i) => {
                            return <City name={v} key={i} />
                        })
                    }
                </Cities>
            </_Card>
            </Fragment>
    }
    renderList() {
        console.log(this.state)
        return <Fragment>
            {
                this.state.cities.map((v, i) => {
                    return <CityLine><City name={v.sFullName} /></CityLine>
                })
            }
            </Fragment>
    }
    render() {
        return <Container>
            <SearchBox placeholder='输入城市名称或拼音' 
                       enableCancel={true}
                       onChange={this.onChange.bind(this)} />

            {!this.state.cities && this.renderDefault()}

            {this.state.cities && <Scroller style={{width: '100%', top: '30px'}}>{this.renderList()}</Scroller>}
            
        </Container>
    }
})

const setPlace = (place) => {
    setCache('place', place)
}

const SimplePlace = withRouter(class extends React.Component {
    onClick() {
        setPlace(this.props.place); 
        this.props.history.goBack();
    }
    render() {
        let district = this.props.place.district
        let place = this.props.place
        return <CityContainer onClick={this.onClick.bind(this)}>
        {this.props.place.name !== '这里' 
            && `${this.props.place.name}${district && `(${district})` || ''}`
            || place.location}
        </CityContainer>
    }
})

const PlaceItem = withRouter(class extends React.Component {

})


const getLoc = () => {
    let loc = getCache('location')
    return loc && loc.longitude && `${loc.longitude.toFixed(5)},${loc.latitude.toFixed(5)}` || '116.50417,39.86139'
}

const getPlaceOfLoc = (callback) => {
    console.log('EEEE')
    let loc = getLoc()
    let place = {location: loc, name: '...'}
    let key = 'ed46be8ff64d4a40fae74e6bcbb3fe5c'
    let url = `https://restapi.amap.com/v3/geocode/regeo?key=${key}&location=${loc}&poitype=&radius=1000&extensions=base&batch=false&roadlevel=0`
    axios.get(url).then(res => {
        console.log(res)
        let addr = res.data.regeocode.addressComponent
        place.name = res.data.regeocode.addressComponent.neighborhood.name;
        place.district = `${addr.province}${addr.district}`
        if (!place.name || (place.name && place.name.length === 0)) {
            place.name = place.district || '这里'
        }
        // alert(JSON.stringify(res))
        place.city = addr.city
        if (!addr.city || (addr.city.length === 0)) {
            // 主要是直辖市
            place.city = addr.province
        }
        callback && callback(place)
        // setCache('place', place)
        // this.setState({place: place, refresh: true})
    }).catch(res => {
        console.log('Adress:', res)
    })
    return place
}


const getPlace = (callback) => {
     return getPlaceOfLoc(callback)
}

const getCity = () => {
    return getCache('cityName') || '北京'
}

const PlaceSearch = withRouter(class extends React.Component {
    state = {
        places: [],
        place: getCache('place') && getPlace(),
        q: ''
    }

    renderDefault() {
        return <_Card onClick={e => console.log(e)}>
                <Flex>
                    <div>
                        <strong> 当前位置 </strong> 
                    </div>
                    <SimplePlace place={this.state.place} />
                    <span onClick={this.relocate.bind(this)}>重新定位</span>
                </Flex>
            </_Card>

    }
    relocate() {
        getPlace(place => {
            setCache('place', place)
            this.setState({place: place})
        })
    }
    doSearch() {
        let q = this.state.q
        if (q !== this.state.completed) {
            // get('city', {params: {'q': q}}).then(res => {
            //     this.setState({
            //         completed: q,
            //         cities: res.data
            //     })
            // })
            let loc = getLoc()
            let url = `https://restapi.amap.com/v3/assistant/inputtips?key=${key}&keywords=${q}&location=${loc}`
            axios.get(url).then(res => {
                console.log(res)
                this.setState({
                    completed: q,
                    places: res.data.tips
                })
            })
        }

    }
    onChange(e) {
        console.log('Onchange', e.target.value)
        this.setState({q: e.target.value})
        setTimeout(e => this.doSearch(), 300)
    }
    renderList() {
        return <Fragment>
        {
            this.state.places.map((v, i) => {
                return <CityLine key={i}><SimplePlace place={v} /></CityLine>
            })
        }
        </Fragment>

    }
    componentDidMount() {
        // let place = getCache('place')
        // setCache('place', null)
        // place && setCache('places', [place, ...(getCache('places') || [])])
    }
    render() {
        return <Container>
        <SearchBox placeholder='输入地点名称或拼音' 
                   enableCancel={true}
                   onChange={this.onChange.bind(this)} />
        {this.state.place && this.renderDefault()}
        {this.state.places[0] && <Scroller style={{width: '100%', top: '30px'}}>{this.renderList()}</Scroller>}
        {/* {this.state.cities && <Scroller style={{width: '100%', top: '30px'}}>{this.renderList()}</Scroller>} */}
    </Container>
    }
})
// const CityPage = withRouter(class extends React.Component {
//     render() {
        
//     }
// })

export {CitySelect, PlaceSearch, getPlace, getCity, locateCity, getLoc}