import React, {Fragment} from 'react';
import {
    Toolbar, Page, ToolbarButton,
    // SearchInput,
    Card
} from 'react-onsenui';

// import styled, {css} from 'react-emotion'; import styled, { css } from
// 'react-emotion'
import styled from 'styled-components';
// import {SearchInput} from 'react-weui';

import "react-tabs/style/react-tabs.css";
import {SearchBox, StyledToolbar, End} from './Common.js';
import SearchPage from './Search.js';
import {withRouter} from 'react-router-dom';
import {
    Feed,
    StyledPage,
    Tabbed,
    Container,
    Body,
    Scroller
} from './Common.js';
import {Entry, Museum, Exhibit, Unit, LinkCard, QuotePost} from './Card';
import {TagSelect, GeneralCard, LeftMost, Desc, AddressBar, FixedPostIcon} from './Detail';
import {getRefresh, setRefresh, getCache, getPosition, getPlaceOfLoc, setFlyCache as setCache, IndexDB} from './State';

import { back, shareNav, get } from './Backend';
import axios from 'axios';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Slider from "react-slick";
import { getPlace, getCity, locateCity, getLoc } from './City';
import queryString from 'query-string';
import LazyLoad from 'react-lazyload';
import {Icon} from 'react-icons-kit';
import {settings} from 'react-icons-kit/feather/settings';
import {CoverCard, toPageSrc} from './CoverCard';
import {
    FaAngleLeft, FaPlus, 
    FaShare as FaShare,
    FaBookmark,
    FaHeartbeat
} from 'react-icons/fa';
import { Portal } from '@blueprintjs/core';
import { PhotoSwipe } from 'react-photoswipe';
import {
    Plugins,
    StatusBarStyle,
  } from '@capacitor/core';
import PullRefresh from 'react-pullrefresh';
const { StatusBar } = Plugins;
// import {bindLifecycle} from 'react-keep-alive';
// import "styles/react-router-tabs.css"; import
// "react-router-tabs/styles/react-router-tabs.css";

const List = styled.div`
    width: 96%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow-Y: scroll;
    overflow-X: hidden;
    /* padding-bottom: 40vh; */
`

const PlaceHolder = styled.div`
    width: 100%;
    height: ${props => props.height || 'auto'};
`

const Waterfall = class extends React.Component {
    Container = styled.div`
        width: 100%;
        height: 100%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        overflow-Y: scroll;
        overflow-X: hidden;
    `
    Padding = styled.div`
        height: 300px;
    `
    LeftColumn = styled.div`
        width: calc(50% - 20px);
        padding-left: 10px;
        padding-bottom: 200px;
        height: auto;
        position: absolute;
        left: 0;
        top: 0;
        /* height: 100%; */
    `
    RightColumn = styled.div`
        width: calc(50% - 20px);
        padding-right: 10px;
        padding-bottom: 200px;
        height: auto;
        position: absolute;
        right: 0;
        top: 0;
        /* height: 100%; */
    `
    Img = styled.img`
        width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        border-radius: 5px;

    `
    Photo = props => <this.Img  {...props}
                                onLoad={e => props.onLoad &&props.onLoad()} 
                                src={toPageSrc(props.src)} />
    PostCard = styled.div`
        width: 100%;
        border-radius: 5px;
        font-size: medium;
        margin-bottom: 20px;
        margin-top: 5px;
        color: green;
        background-color: whitesmoke;
    `
    Post = props => <this.PostCard onClick={e => props.onClick(e)}>
            <QuotePost data={props} onLoad={props.onLoad}/>
         </this.PostCard>

    state = {
        leftData: [],
        rightData: [],
        data: [],
        dataCursor: 0,
        posts: []
    }
    constructor(props) {
        super(props)
        this.state = IndexDB.get(this.props.cacheKey) || this.state
        if (this.state.data.length === 0) {
            this.extractImages(this.props.posts)
        }

    }
    showSlides() {
        this.setState({openPS: true})
    }
    load() {
        this.layout()
        this.onScroll()
    }
    componentDidMount() {
        this.load()
    }
    componentWillUnmount() {
        IndexDB.set(this.props.cacheKey, this.state)
    }
    extractImages(ps) {
        for (var i in ps) {
            if (ps[i].images && ps[i].images.length > 0) {
                let data = ps[i].images.map((v, _) => {
                    return {
                        src: v.src,
                        post: ps[i],
                        info: v.info,
                        type: 'photo',
                    }
                })
                this.state.data = [...this.state.data, ...data]

                // ps[i].type = 'post'
                // this.state.data = [...this.state.data, ps[i], ...data]

            }
        }
    }
    onScroll() {
        let top = this.refs.container.scrollTop
        if (Math.abs((this.state.lastTop || 0) - top) > 100) {
            console.log('Rerender')
            // setTimeout(() => this.setState({}), 1)
        }
        this.state.lastTop = top
        let height = this.refs.container.scrollHeight
        let threhold = 1000
        if (height - top < threhold && !this.state.onLoading) {
            this.state.onLoading = true
            this.props.loadMore(this.state.posts.length).then(res => {
                // console.log(res.data)
                this.state.posts = [...this.state.posts, ...res.data]
                this.extractImages(res.data)
                this.layout()
                this.state.onLoading = false
            }).catch(res => {
                this.setState({onLoading: false})
            })
        }
    }

    layout() {
        if (!this.refs.left || !this.refs.right) {
            return
        }
        let leftHeight = this.refs.left.offsetHeight
        let rightHeight = this.refs.right.offsetHeight
        let {data, dataCursor} = this.state
        if (this.state.dataCursor === this.state.data.length) {
            this.setState({onLoading: false})
            return
        }
        console.log(this.state, leftHeight, rightHeight)
        let item = {
            ...data[dataCursor],
            index: dataCursor
        }
        if (leftHeight > rightHeight) {
            this.setState({dataCursor: dataCursor + 1, rightData: [...this.state.rightData, item]})
        } else {
            this.setState({dataCursor: dataCursor + 1, leftData: [...this.state.leftData, item]})
        }

    }

    initPS() {
        StatusBar.hide()
        // document.getElementsByClassName('pswp__caption')[0].onClick = (e) => {
        //     console.log(e)
        // }
    }
    calcIndexOf(i) {
        // console.log(i, this.state.data.slice(0, i), this.state.data.slice(0, i).filter(v => v.type === 'image').length)
        return this.state.data.slice(0, i).filter(v => v.type === 'photo').length
    }
    renderItem(v, i) {
        if (v.type === 'post') {
            return <this.Post post={v} onLoad={this.layout.bind(this)} onClick={e => this.props.history.push(`/posts/${v._id}`)}/>
        } else {

            return <this.Photo onClick={e => this.setState({openPS: true, index: this.calcIndexOf(v.index)}) && this.initPS()} onLoad={this.layout.bind(this)} {...v} key={v.src} />
        }
    }

    render() {
        let options = {
            index: this.state.index,
            escKey: true,
            // ui option
            // timeToIdle: 400000,
            tapToClose: true,
            // modal: false,
            history: false,
            // clickToCloseNonZoomable: true,
            closeOnVerticalDrag: false,
            addCaptionHTMLFn: (item, captionEl, isFake) => {
                console.log(item)
                if (item.title) {
                    captionEl.children[0].innerHTML = item.title
                    captionEl.children[0].style.color = 'white'
                    captionEl.children[0].style.height = '100px'
                    return true
                }
            }
            // isClickableElement: e => false,
        }
        let items = this.state.data.map((v, i) => {
            let info = v.info || {}
            let exif = info.exif || {}

            // for iPhone picture
            let doTrans = exif.Orientation === 6

            let width = info.width || 1920
            let height = info.height || 1920
            // return v.src
            console.log(v)
            return {
                    src: `${v.src}`, 
                    w:  doTrans && height || width,
                    h:  doTrans && width || height,
                    msrc: toPageSrc(v.src || ''),
                    title: v.post && v.post.text && `@${v.post.user.nickName}: ${v.post.text.substring(0, 50)} ${v.post.text.length > 50 && '...' || ''} <a href='/#/posts/${v.post._id}'>查看帖子</a>`,
                    post_id: v.post && v.post._id,
                    isImage: !!v.src
                }
        }).filter(v => v.isImage)
        // console.log(items)
        return <this.Container ref='container' onScroll={this.onScroll.bind(this)}>

            <this.LeftColumn ref='left'>
                {
                    this.state.leftData.map((v, i) => this.renderItem(v, i))
                }
            </this.LeftColumn>
            <this.RightColumn ref='right'>
                {
                    this.state.rightData.map((v, i) => this.renderItem(v, i))
                }
            </this.RightColumn>
            {this.state.openPS && <Portal>
                 <PhotoSwipe options={options}
                         items={items} 
                         isOpen={this.state.openPS} 
                         onClose={e => this.setState({openPS: false}) && StatusBar.show()}/>
            </Portal>}
        </this.Container>
    }
}

const InfinitList = key => class extends React.Component {
    state = {}
    
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            top: 0,
            onLoading: false
        }
        if (!this.props.refresh) {
            this.state = IndexDB.get(this.props.cacheKey) || this.state
        }
        
    }
    componentDidMount() {
        this.load()
    }

    load() {
        this.state.onLoading = false
        if (this.props.refresh) {
            return this.onRefresh()
        }
        if (!this.props.refresh) {
            this.refs.container.scrollTop = this.state.top || 0
        }
        this.onScroll()
        console.log(this.state)
        this.props.onData && this.props.onData(this.state.data)
    }

    componentWillUnmount() {
        this.state.top = this.refs.container.scrollTop
        // setCache(this.props.cacheKey, this.state)
        IndexDB.set(this.props.cacheKey, this.state)
    }

    onScroll() {
        let top = this.refs.container.scrollTop
        let height = this.refs.container.scrollHeight
        let threhold = this.window
        // console.log('OnScroll', top, this.state)
        if (((this.state.lastTop || 0) - top) > 0) {
            this.props.upScroll && this.props.upScroll(top, height)
        } else {
            this.props.downScroll && this.props.downScroll(top, height)
        }
        if (Math.abs((this.state.lastTop || 0) - top) > 200) {
            console.log('Rerender')
            this.setState({})
            this.state.lastTop = top
            // setTimeout(() => this.setState({}), 1)
        }


        console.log(height, top, this.state)
        if (top < 50 && !this.state.refreshEnabled) {
            this.setState({refreshEnabled: true})
        }
        if (top > 50 && this.state.refreshEnabled) {
            this.setState({refreshEnabled: false})
        }
        if (((height - top < threhold) || this.state.data.length === 0 ) && !this.state.onLoading) {
            this.state.onLoading = true
            this.props.loadMore(this.state.data.length).then(res => {
                console.log(res)
                this.setState({data: [...this.state.data, ...res.data], onLoading: false})
                this.props.onData && this.props.onData(this.state.data)
            }).catch(res => {
                this.setState({onLoading: false})
            })
        }

    }
    window = 2000

    isVisible(data, index) {
        // console.log(data, index, this.state)
        return true
        // if (!data._height ) {
        //     console.log('visible')
        //     return true
        // } else {
        //     this.state.height += data._height
        //     // data._height
        // }
        // let scrollTop = this.refs.container && this.refs.container.scrollTop || this.state.top || 0
        // // console.log(scrollTop, this.state.height)
        // if (Math.abs(scrollTop - this.state.height) > this.window) {
        //     return false
        // }
        // return true

    }
    Item = class extends React.Component {
        componentDidMount() {
            this.props.data._height = this.refs.ph.offsetHeight
            // this.props.data.ph = this.refs.ph
        }
        render() {
            let h = this.props.data._height || 0
            // console.log(this.props)
            return <PlaceHolder ref='ph' height={this.props.visible && 'auto' || `${h}px` }>
                {this.props.visible && !this.props.data.dummy && React.createElement(this.props.itemComponent, {
                        data: this.props.data,
                        index: this.props.index,
                        key: this.props.index,
                        onDelete: e => this.props.onRefresh()
                    }) 
                }
                </PlaceHolder>
        }
    }
    renderItem(data, index) {
        // console.log(data)
        return  <this.Item 
                        visible={this.isVisible(data, index)}
                        data={data} 
                        index={index} 
                        key={data._id || index} 
                        onRefresh={this.onRefresh.bind(this)}
                        itemComponent={this.props.itemComponent}>
                    </this.Item>
    }

    onRefresh() {
        console.log('Refresh')
        this.state.data = []
        this.state.height = 0
        this.onScroll()
    }
    Padding = styled.div`
        height: 300px;
    `
    render() {
        console.log(this.state)
        this.state.height = 0
        return <List ref='container' onScroll={this.onScroll.bind(this)}>
        {
         !this.props.refreshDisabled && <PullRefresh onRefresh={this.onRefresh.bind(this)} disabled={!this.state.refreshEnabled || this.props.refreshDisabled}>
            {
                this.state.data.map((v, i) => this.renderItem(v, i))
            }
        </PullRefresh>
        }
        {
            this.props.refreshDisabled && this.state.data.map((v, i) => this.renderItem(v, i))
        }
        <this.Padding />

        </List>
        
    }
}

const PlayText = text => {
    return axios.get('https://fanyi.baidu.com/gettts', {
        params: {
            lan: 'zh',
            text: text
        }
    })
}


const size = '25px'
const Back = withRouter(props => <FaAngleLeft size='25px' color={props.color} onClick={props.onClick || (e => props.history.goBack())} />)
const Header = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 100%;
    background-color: white;
    z-index: 1000;
    border-bottom: ${props => props.noBaseLine && '0' || '1px solid lightgrey'};
    margin-bottom: 10px;
`
const Return = styled.div`
    position: absolute;
    left: 10px;
    top: 10px;
`
const Done = styled.div`
    position: absolute;
    right: 20px;
    top: 10px;
    color: green;
`

const Bar = props => <Header {...props}>
    <Return> <Back onClick={e => props.back(e)}/> </Return>
    {props.share && <Done onClick={e => props.share(e)}> {props.shareTitle || '分享'} </Done>}
    </Header>

export {InfinitList, List, PlayText, Waterfall, Back, Bar}