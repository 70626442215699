import {
    Plugins,
    Capacitor,
} from '@capacitor/core';
import axios from 'axios';
import { get } from './Backend';
// import localForage from 'localforage';
const { Storage } = Plugins;


const getPosition = async () => {
    // const {coords} = await Geolocation.getCurrentPosition()
    // setCache('location', {
    //     longitude: coords.longitude,
    //     latitude: coords.latitude,
    //     accuray: coords.accuracy
    // })
    return getCache('location')
}

const getPlaceOfLoc = async () => {
    console.log('EEEE')
    let loc = await getPosition()
    let place = {location: `${loc.longitude.toFixed(5)},${loc.latitude.toFixed(5)}`}
    let key = 'ed46be8ff64d4a40fae74e6bcbb3fe5c'
    let url = `https://restapi.amap.com/v3/geocode/regeo?key=${key}&location=${loc.longitude.toFixed(5)},${loc.latitude.toFixed(5)}&poitype=&radius=1000&extensions=base&batch=false&roadlevel=0`
    axios.get(url).then(res => {
        console.log(res)
        let place = this.state.place;
        let addr = res.data.regeocode.addressComponent
        place.name = res.data.regeocode.addressComponent.neighborhood.name;
        place.district = `${addr.province}${addr.district}`
        setCache('place', place)
        // this.setState({place: place, refresh: true})
    }).catch(res => {
        console.log('Adress:', res)
    })
    return place
}

const getRefresh = key => {
    let name = `refresh.${key}`
    let ret = localStorage.getItem(name)
    localStorage.removeItem(name)
    return ret
}

const setRefresh = key => {
    let name = `refresh.${key}`
    localStorage.setItem(name, 'true')
}

let _Cache = {}
const setCache = (k, v) => {
    _Cache[k] = v
    try {
        // Storage.set({key: k, value: JSON.stringify(v)})
        localStorage.setItem(k, JSON.stringify(v))
    } catch(e) {
        if (e.code === 22) {
            // Storage.clear()
            localStorage.clear()
            localStorage.setItem(k, JSON.stringify(v))
        }
    }
}

const invalidate = k => {
    _Cache[k] = undefined
    localStorage.removeItem(k)
}
const setFlyCache = (k, v) => {
    _Cache[k] = v
}

const getCache = k => {
    // let 
    let v = _Cache[k]
    if (!v) {
        try {
            v = JSON.parse(localStorage.getItem(k))
        } catch(e) {
            return undefined
        }
    }
    return v
}

const IndexDB = {
    set: (k, v) => {
        return _Cache[k] = v
    },
    get: k => {
        return _Cache[k]
    }
}

const setHash = (name, k, value) => {
    let v = getCache(name)
    if (!v) {
        setCache(name, {})
        v = getCache(name)
    }
    v[k] = value
    setCache(name, v)
    return v
}

const getHash = (name, k) => {
    let v = getCache(name)
    if (!v) {
        return null
    }
    return v[k]
}

const clearCache = () => {
    _Cache = {}
    localStorage.clear()
    Storage.clear()
}

const AppState = new class {
    state = {}
    set(type, id, value) {
        this.state[type] = this.state[type] || {}
        this.state[type][id] = value
    }
    async get(type, id) {
        let v = this.syncGet(type, id)
        if (v) {
            return v
        }
        let res = await get(`/${type}/${id}`)
        this.set(type, id, res.data)
        return res.data
    }
    syncGet(type, id) {
        let value = this.state[type] && this.state[type][id]
        return value
    }
    update(type, data) {
        let v = this.syncGet(type, data._id)

        if (!v || ((v.updated || v.created || 0) < (data.updated || v.created || 0 ))) {
            this.set(type, data._id, data)
            return data
        }
        return v
    }
    invalidate(type, id) {
        if (!id) {
            this.state[type] = {}
            return
        }
        this.state[type] && (this.state[type][id] = undefined)
    }
}()


export {getRefresh, setRefresh, setCache, setFlyCache, getCache, getHash, setHash, clearCache, getPosition, getPlaceOfLoc, IndexDB, invalidate, AppState}