import React from 'react';
import {Feed, StyledPage, StyledToolbar, Content, Container, Body} from './Common.js';
import {Entry, Museum as MuseumCard} from  './Card.js';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {ToolbarButton} from 'react-onsenui';
import {shareNav, back} from './Backend';



class Museum extends React.Component {
    constructor(props) {
        super(props);
        this.name = props.location.pathname.split('/')[2];
        console.log(this.name);
    }
    render() {
        return (<Container>
            <StyledToolbar>
                <div className='left'> 
                    <ToolbarButton onClick={e => back(this, '/')}> 返回 </ToolbarButton>
                </div>
                <div className='center'> {this.name} </div>
                <div className='right'>
                    <ToolbarButton onClick={e => shareNav(this.name, this.name, this.props.location.pathname)}> 分享 </ToolbarButton>
                </div>
            </StyledToolbar>
            <Body>
                <Feed dataType='entry' component={Entry} params={{museum: this.name}}/>
            </Body>
            </Container>)
    }
}

export default withRouter(Museum);